import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import CustomButton from "../buttons/CustomButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";

function FilterByOptionsTickets({
  UserDetails,
  selectOEM,
  selectTECH,
  ticketsType,
  appMetaDetails,
  selectedVariantDetails,
  EEDetails,
  LSDetails,
  selectedTicketsType,
  setSelectedTicketsType,
  selectTicketStatus,
  handleCheckboxChange,
  selectedZones,
  selectedEE,
  selectedVC,
  selectedStandard,
  selectedChapter,
  selectedLR,
  selectSWMEE,
  selectedSWM,
  setSearchTicketId,
  searchTicketId,
  setSearchIntrId,
  searchIntrId,
  handleClearAllFilter,
  fetchTickets,
  reqObj,
  tabValue,
}) {
  return (
    <div>
      <Box
        sx={{
          boxShadow: 4,
          padding: "10px",
          ml: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 20px",
            backgroundColor: "#fff",
            boxShadow: 3,
            position: "sticky",
            top: 0,
            width: "100%",
            zIndex: 1,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Filters</Typography>
          <CustomButton
            handleOnClick={handleClearAllFilter}
            size={"small"}
            variant={"contained"}
            btnName={"Clear All "}
            btnStyle={{
              ml: 2,
            }}
          />
        </Box>

        <Divider sx={{ mt: "10px" }} />
        <Box sx={{ minHeight: "400px", overflowY: "auto" }}>
          {[
            tabValue === 2
              ? {
                  title: "Ticket ID",
                  value: searchTicketId,
                }
              : null,
            tabValue === 1
              ? {
                  title: "Intersection ID",
                  value: searchIntrId,
                }
              : null,
            {
              title: "Ticket Type",
              ticket_type: {
                options: [
                  { value: ticketsType?.EE, label: "EE Component" },
                  {
                    value: ticketsType?.SWM,
                    label: "Software Modules",
                  },
                  {
                    value: ticketsType?.VEHICLE,
                    label: "Vehicle Component",
                  },
                ],
              },
            },
            {
              title: "Ticket Status",
              status_list:
                appMetaDetails?.snow_ticket_status?.length > 0
                  ? appMetaDetails.snow_ticket_status
                  : [],
            },
            {
              title: "OEM Service Users",
              oem_details:
                UserDetails?.usersList?.length > 0
                  ? UserDetails?.usersList?.filter(
                      (user) => user?.role_details?.role_type === 0
                    )
                  : [],
            },
            {
              title: "Technical Service Users",
              tech_details:
                UserDetails?.usersList?.length > 0
                  ? UserDetails?.usersList?.filter(
                      (user) => user?.role_details?.role_type === 1
                    )
                  : [],
            },
            {
              title: "Zones",
              zone_details:
                selectedVariantDetails?.zone_details?.length > 0
                  ? selectedVariantDetails.zone_details
                  : [],
            },
            {
              title: "EE Components",
              ee_components_details:
                EEDetails?.eeComponentsList?.length > 0
                  ? EEDetails.eeComponentsList
                  : [],
            },

            {
              title: "Software Modules",
              ee_details: {
                title: "E/E Components ",
                ee_components_details:
                  EEDetails?.eeComponentsList?.length > 0
                    ? EEDetails.eeComponentsList
                    : [],
              },
              swm_details: {
                title: "SWM Components",
                software_modules_details:
                  EEDetails?.swModulesList?.length > 0
                    ? EEDetails?.swModulesList
                    : [],
              },
            },
            {
              title: "Vehicle Components",
              vehicle_components_details:
                selectedVariantDetails?.vehicle_components?.length > 0
                  ? selectedVariantDetails.vehicle_components
                  : [],
            },

            {
              title: "Legal Details",
              legal_standard: {
                title: "Standards",
                legal_standard_details:
                  LSDetails?.legalStandardDetails?.length > 0
                    ? LSDetails.legalStandardDetails
                    : [],
              },
              legal_chapters: {
                title: "Chapters",
                legal_chapters_details:
                  LSDetails?.legalStandardDetails?.length > 0
                    ? LSDetails?.legalStandardDetails?.flatMap(
                        (data) => data?.chapters || []
                      )
                    : [],
              },
              legal_requirements: {
                title: "Legal Requirements",
                legal_requirements_details:
                  LSDetails?.legalRequirementsDetails?.length > 0
                    ? LSDetails?.legalRequirementsDetails
                    : [],
              },
            },
          ]?.map((data, index) => {
            return (
              <div
                key={index}
                style={{
                  marginTop: "5px",
                  padding: "2px",
                }}
              >
                {/* Ticket ID */}
                {data?.title === "Ticket ID" ? (
                  <Accordion expanded={true}>
                    <AccordionDetails>
                      <Box sx={{ padding: "7px" }}>
                        <MemoizedTextField
                          reqObj={reqObj}
                          fetchTickets={fetchTickets}
                          setSearchTicketId={setSearchTicketId}
                          data={data}
                        />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {/* Intersection ID */}
                {data?.title === "Intersection ID" ? (
                  <Accordion expanded={true}>
                    <AccordionDetails>
                      <Box sx={{ padding: "7px" }}>
                        <MemoizedTextField
                          type="number"
                          reqObj={reqObj}
                          fetchTickets={fetchTickets}
                          setSearchIntrId={setSearchIntrId}
                          data={data}
                        />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {/* Ticket Type */}
                {data?.title === "Ticket Type" ? (
                  <Accordion expanded={true}>
                    <AccordionSummary
                      //expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl
                        sx={{
                          size: "small",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <RadioGroup
                          value={selectedTicketsType}
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          onChange={(e) =>
                            setSelectedTicketsType(e.target.value)
                          }
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {data?.ticket_type?.options?.map((option, idx) => (
                            <FormControlLabel
                              key={idx}
                              value={option?.value}
                              control={<Radio sx={{ fontSize: 16 }} />}
                              label={
                                <Typography sx={{ fontSize: "0.875rem" }}>
                                  {option?.label}
                                </Typography>
                              }
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {/* Ticket Status */}
                {data?.title === "Ticket Status" ? (
                  <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        minHeight: "200px",
                        maxHeight: "300px",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 #f1f1f1",
                      }}
                    >
                      <FormGroup
                        sx={{
                          margin: 1,
                          size: "small",
                          display: "flex",

                          justifyContent: "start",
                        }}
                      >
                        {Object?.values(data?.status_list)?.map(
                          (item, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      item?.value,
                                      data?.title
                                    )
                                  }
                                  checked={selectTicketStatus.includes(
                                    item?.value
                                  )}
                                />
                              }
                              label={
                                <Typography sx={{ fontSize: "0.875rem" }}>
                                  {item?.key}
                                </Typography>
                              }
                            />
                          )
                        )}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
                {/* OEM Users */}
                {data?.title === "OEM Service Users" ? (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 #f1f1f1",
                      }}
                    >
                      <FormGroup
                        sx={{
                          size: "small",
                          display: "flex",

                          justifyContent: "flex-start",
                        }}
                      >
                        {data?.oem_details?.map((item, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    item?.user_id,
                                    data?.title
                                  )
                                }
                                checked={selectOEM.includes(item?.user_id)}
                              />
                            }
                            label={
                              <Typography sx={{ fontSize: "0.875rem" }}>
                                {item?.fullname}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {data?.title === "Technical Service Users" ? (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 #f1f1f1",
                      }}
                    >
                      <FormGroup
                        sx={{
                          size: "small",
                          display: "flex",

                          justifyContent: "flex-start",
                        }}
                      >
                        {data?.tech_details?.map((item, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    item?.user_id,
                                    data?.title
                                  )
                                }
                                checked={selectTECH.includes(item?.user_id)}
                              />
                            }
                            label={
                              <Typography sx={{ fontSize: "0.875rem" }}>
                                {item?.fullname}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
                {/* Zones */}
                {data?.title === "Zones" ? (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 #f1f1f1",
                      }}
                    >
                      <FormGroup
                        sx={{
                          size: "small",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {data?.zone_details?.map((option, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    e,
                                    option?.zone?.position,
                                    data?.title
                                  )
                                }
                                checked={selectedZones.includes(
                                  option?.zone?.position
                                )}
                              />
                            }
                            label={
                              <Typography sx={{ fontSize: "0.875rem" }}>
                                {option?.zone?.zone_name}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {/* EE Components */}
                {data?.title === "EE Components" && (
                  <Accordion
                    disabled={
                      parseInt(selectedTicketsType) === ticketsType?.VEHICLE ||
                      parseInt(selectedTicketsType) === ticketsType?.SWM
                        ? true
                        : false
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 #f1f1f1",
                      }}
                    >
                      <FormGroup
                        sx={{
                          size: "small",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {Object?.values(data?.ee_components_details)?.map(
                          (option) => (
                            <FormControlLabel
                              key={option?.ee_id}
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      option?.ee_id,
                                      data?.title
                                    )
                                  }
                                  checked={selectedEE.includes(option?.ee_id)}
                                />
                              }
                              label={
                                <Typography sx={{ fontSize: "0.875rem" }}>
                                  {option?.supplier_name}
                                </Typography>
                              }
                            />
                          )
                        )}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* Vehicle Components */}
                {data?.title === "Vehicle Components" ? (
                  <Accordion
                    disabled={
                      parseInt(selectedTicketsType) === ticketsType?.EE ||
                      parseInt(selectedTicketsType) === ticketsType?.SWM
                        ? true
                        : false
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        maxHeight: "300px",
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 #f1f1f1",
                      }}
                    >
                      <FormGroup
                        sx={{
                          size: "small",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {Object?.values(data?.vehicle_components_details)?.map(
                          (componentData, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      e,
                                      componentData?.id,
                                      data?.title
                                    )
                                  }
                                  checked={selectedVC.includes(
                                    componentData?.id
                                  )}
                                />
                              }
                              label={
                                <Typography sx={{ fontSize: "0.875rem" }}>
                                  {componentData?.component}
                                </Typography>
                              }
                            />
                          )
                        )}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {/* Legal Standards */}
                {data?.title === "Legal Details" ? (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <FormLabel component="legend">
                            {data?.legal_standard?.title}
                          </FormLabel>
                          <FormGroup
                            sx={{
                              size: "small",
                              display: "flex",

                              justifyContent: "flex-start",
                              maxHeight: "300px",
                              overflowY: "auto",
                              scrollbarWidth: "thin",
                              scrollbarColor: "#888 #f1f1f1",
                            }}
                          >
                            {Object?.values(
                              data?.legal_standard?.legal_standard_details
                            )?.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        e,
                                        item?.ls_id,
                                        data?.legal_standard?.title
                                      )
                                    }
                                    checked={selectedStandard.includes(
                                      item?.ls_id
                                    )}
                                  />
                                }
                                label={
                                  <Typography sx={{ fontSize: "0.875rem" }}>
                                    {item?.standard_number}
                                  </Typography>
                                }
                              />
                            ))}
                          </FormGroup>
                        </Grid>
                        <Grid item md={12}>
                          <FormLabel component="legend">
                            {data?.legal_chapters?.title}
                          </FormLabel>
                          <FormGroup
                            sx={{
                              size: "small",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",

                              maxHeight: "300px",
                              overflowY: "auto",
                              scrollbarWidth: "thin",
                              scrollbarColor: "#888 #f1f1f1",
                            }}
                          >
                            {data?.legal_chapters?.legal_chapters_details?.map(
                              (item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          item,
                                          data?.legal_chapters?.title
                                        )
                                      }
                                      checked={selectedChapter.includes(item)}
                                    />
                                  }
                                  label={
                                    <Typography sx={{ fontSize: "0.875rem" }}>
                                      {item}
                                    </Typography>
                                  }
                                />
                              )
                            )}
                          </FormGroup>
                        </Grid>
                        <Grid item md={12}>
                          <FormLabel component="legend">
                            {data?.legal_requirements?.title}
                          </FormLabel>
                          <FormGroup
                            sx={{
                              size: "small",
                              display: "flex",

                              justifyContent: "center",
                              maxWidth: "250px",
                              maxHeight: "300px",
                              overflowY: "auto",
                              scrollbarWidth: "thin",
                              scrollbarColor: "#888 #f1f1f1",
                            }}
                          >
                            {data?.legal_requirements?.legal_requirements_details?.map(
                              (item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          item?.lr_id,
                                          data?.legal_requirements?.title
                                        )
                                      }
                                      checked={selectedLR.includes(item?.lr_id)}
                                    />
                                  }
                                  label={
                                    <Typography sx={{ fontSize: "0.875rem" }}>
                                      {item?.req_number}
                                    </Typography>
                                  }
                                />
                              )
                            )}
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ) : null}

                {/* Software Modules */}
                {data?.title === "Software Modules" ? (
                  <Accordion
                    disabled={
                      parseInt(selectedTicketsType) === ticketsType?.EE ||
                      parseInt(selectedTicketsType) === ticketsType?.VEHICLE
                        ? true
                        : false
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {data?.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item md={6}>
                          <FormGroup
                            sx={{
                              size: "small",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              maxHeight: "300px",
                              overflowY: "auto",
                              scrollbarWidth: "thin",
                              scrollbarColor: "#888 #f1f1f1",
                            }}
                          >
                            <FormLabel component="legend">
                              {data?.ee_details?.title}
                            </FormLabel>
                            {data?.ee_details?.ee_components_details?.map(
                              (item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={selectSWMEE === item?.ee_id}
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          item?.ee_id,
                                          data?.ee_details?.title
                                        )
                                      }
                                    />
                                  }
                                  label={
                                    <Typography sx={{ fontSize: "0.875rem" }}>
                                      {item?.supplier_name}
                                    </Typography>
                                  }
                                />
                              )
                            )}
                          </FormGroup>
                        </Grid>

                        <Grid item md={6}>
                          <FormGroup
                            sx={{
                              size: "small",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              maxHeight: "300px",
                              overflowY: "auto",
                              scrollbarWidth: "thin",
                              scrollbarColor: "#888 #f1f1f1",
                            }}
                          >
                            <FormLabel component="legend">
                              {data?.swm_details?.title}
                            </FormLabel>
                            {data?.swm_details?.software_modules_details?.map(
                              (item, index) => (
                                <FormControlLabel
                                  key={index}
                                  control={
                                    <Checkbox
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          item?.swm_id,
                                          data?.swm_details?.title
                                        )
                                      }
                                      checked={selectedSWM.includes(
                                        item?.swm_id
                                      )}
                                    />
                                  }
                                  label={
                                    <Typography sx={{ fontSize: "0.875rem" }}>
                                      {item?.description}
                                    </Typography>
                                  }
                                />
                              )
                            )}
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
              </div>
            );
          })}
        </Box>
      </Box>
    </div>
  );
}

export default FilterByOptionsTickets;

const MemoizedTextField = React.memo(
  ({
    setSearchTicketId,
    setSearchIntrId,
    data,
    fetchTickets,
    reqObj,
    type,
  }) => {
    const [inputValue, setInputValue] = useState(data?.value || "");
    const memoizedSearchChange = useCallback((e, title) => {
      console.log("Hellloo");
      if (title === "Ticket ID") {
        setSearchTicketId(e.target.value);
      } else if (title === "Intersection ID") {
        setSearchIntrId(e.target.value);
      }
    }, []);

    const onClickFunction = (title, value) => {
      let updatedReqObj = { ...reqObj };
      if (title === "Ticket ID") {
        updatedReqObj.ticket_id = value;
      } else if (title === "Intersection ID") {
        updatedReqObj.intr_id = value;
      }
      return updatedReqObj;
    };

    const handleIconButtonClick = () => {
      const updatedReqObj = onClickFunction(data?.title, data?.value);
      fetchTickets(updatedReqObj);
    };

    const handleClearClick = () => {
      setInputValue("");
      memoizedSearchChange({ target: { value: "" } }, data?.title);
      fetchTickets(reqObj);
    };

    return (
      <TextField
        type={type}
        autoComplete="off"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          memoizedSearchChange(e, data?.title);
        }}
        sx={{ width: "100%" }}
        variant="standard"
        label={data?.title}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {inputValue?.length > 0 && (
                <IconButton edge="end" onClick={handleClearClick}>
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton
                disabled={
                  (data?.title === "Ticket ID" && inputValue?.length < 6) ||
                  (data?.title === "Intersection ID" && inputValue?.length < 1)
                    ? true
                    : false
                }
                edge="end"
                onClick={handleIconButtonClick}
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);
