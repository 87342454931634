/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-07-2024
 * @description : Custom template dialog component.
 */
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import appTheme from "../../assets/AppTheme/appTheme";
import CustomTextFieldN from "../text-fields/CustomTextFieldN";
import CustomButton from "../buttons/CustomButton";
import customConsole from "../../config/customConsole";
import DynamicFormTemplate from "./DynamicFormTemplate";
import { useRef } from "react";
import generatePDF from "react-to-pdf";
import typeApprovalAPI from "../../redux/actions/typeApprovalAPI";
import { HandleApiActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { FORM_TYPES } from "../../config/appConstants";
import CustomLogsDialog from "../dialog-boxes/CustomLogsDialog";

const TemplateDialog = ({
  open,
  onClose,
  formType,
  projectId,
  variantId,
  intrId,
  dialogTitle,
  dialogContent,
  onConfirm,
  onDisabled,
  // intersectionDetails,
  dynamicFormData,
  setDynamicFormData,
}) => {
  /* Dispatch */
  const dispatch = useDispatch();

  /* Refs */
  const templateRef = useRef();

  /* Constant States */
  const ALIGN_TYPES = { LEFT: "left", CENTER: "center", RIGHT: "right" };
  const FIELD_TYPES = { NUMBER: "number", TEXT: "string" };
  const EDIT_TYPES = { DDH: 100, SNOW: 200, BOTH: 300 };

  /* States */
  const [formName, setFormName] = useState("");
  // const [formType, setFormType] = useState(FORM_TYPES?.EE);
  const [selectedHeaders, setSelectedHeaders] = useState([ALIGN_TYPES?.CENTER]);
  const [selectedFooters, setSelectedFooters] = useState([ALIGN_TYPES?.CENTER]);
  const [selectedSignatures, setSelectedSignatures] = useState([
    ALIGN_TYPES?.RIGHT,
  ]);

  /* Template states */
  const [openTemplateChangeLogsDialog, setOpenTemplateChangeLogsDialog] =
    useState(false);
  const [templateChangeLogsList, setTemplateChangeLogsList] = useState([]);
  const [templateLogsDialogData, setTemplateLogsDialogData] = useState({});

  // Dynamic form data
  // const [dynamicFormData, setDynamicFormData] = useState(null);

  // Add/Update states
  const [fields, setFields] = useState([
    {
      id: 1,
      fieldName: "Field Name 1",
      fieldValue: "",
      fieldType: FIELD_TYPES?.NUMBER,
      editType: EDIT_TYPES?.DDH,
    },
    {
      id: 2,
      fieldName: "Field Name 2",
      fieldValue: "",
      fieldType: FIELD_TYPES?.NUMBER,
      editType: EDIT_TYPES?.SNOW,
    },
    {
      id: 3,
      fieldName: "Field Name 3",
      fieldValue: "",
      fieldType: FIELD_TYPES?.TEXT,
      editType: EDIT_TYPES?.BOTH,
    },
  ]);
  const [showAddUpdateField, setShowAddUpdateField] = useState(false);
  const [currentField, setCurrentField] = useState(null);

  const headerOptionsList = [
    {
      label: "Left",
      position: ALIGN_TYPES?.LEFT,
      value: "",
    },
    {
      label: "Center",
      position: ALIGN_TYPES?.CENTER,
      value: "",
    },
    {
      label: "Right",
      position: ALIGN_TYPES?.RIGHT,
      value: "",
    },
  ];

  const footerOptionsList = [
    {
      label: "Left",
      position: ALIGN_TYPES?.LEFT,
      value: "",
    },
    {
      label: "Center",
      position: ALIGN_TYPES?.CENTER,
      value: "",
    },
    {
      label: "Right",
      position: ALIGN_TYPES?.RIGHT,
      value: "",
    },
  ];

  const signatureOptionsList = [
    {
      label: "Left",
      position: ALIGN_TYPES?.LEFT,
      value: "",
    },
    {
      label: "Right",
      position: ALIGN_TYPES?.RIGHT,
      value: "",
    },
  ];

  /* Functions */
  /* Get Templates change logs */
  const handleGetTemplateChangeLogs = async ({ pageNo, pageSize }) => {
    let apiDetails = typeApprovalAPI.get_dynamic_form_logs;

    if (dynamicFormData && dynamicFormData?.form_id) {
      customConsole({
        ...apiDetails,
        params: {
          form_id: Number(dynamicFormData?.form_id),
          page_number: pageNo,
          page_size: pageSize,
        },
        show_toast: false,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            form_id: Number(dynamicFormData?.form_id),
            page_number: pageNo,
            page_size: pageSize,
          },
          show_toast: false,
        })
      );
      if (resp.error) {
        setTemplateChangeLogsList([]);
      } else {
        setTemplateChangeLogsList(resp?.data);
      }
    }
  };

  const handleEditField = (id) => {
    const field = fields.find((field) => field.id === id);
    if (field) {
      setCurrentField(field); // Load the selected field into the form
      setShowAddUpdateField(true);
    }
  };

  const handleTemplateLogsDialogOpen = (data) => {
    setOpenTemplateChangeLogsDialog(true);

    setTemplateLogsDialogData(data);
  };
  const handleTemplateLogsDialogClose = () => {
    setTemplateLogsDialogData(null);
    setTemplateChangeLogsList([]);

    setOpenTemplateChangeLogsDialog(false);
  };

  /* UseEffects */
  useEffect(() => {
    // Define valid positions
    const validPositions = ["left", "center", "right"];
    const validPositions1 = ["left", "right"];

    // Update the selected headers list with valid positions
    let selectedHeadersList =
      dynamicFormData?.headers
        ?.map((el) =>
          validPositions?.includes(el?.position) ? String(el?.position) : null
        )
        ?.filter(Boolean) || [];
    setSelectedHeaders(selectedHeadersList);

    // Update the selected signatures list with valid positions
    let selectedSignaturesList =
      dynamicFormData?.signatures
        ?.map((el) =>
          validPositions1?.includes(el?.position) ? String(el?.position) : null
        )
        ?.filter(Boolean) || [];
    setSelectedSignatures(selectedSignaturesList);

    // Update the selected footers list with valid positions
    let selectedFootersList =
      dynamicFormData?.footers
        ?.map((el) =>
          validPositions?.includes(el?.position) ? String(el?.position) : null
        )
        ?.filter(Boolean) || [];
    setSelectedFooters(selectedFootersList);
  }, [open, dynamicFormData]);

  useEffect(() => {
    customConsole({ dynamicFormData17: dynamicFormData });
  }, [dynamicFormData]);

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
          boxShadow: 2,
          py: 1,
        }}
      >
        {/* Title text */}
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {dialogTitle}
        </Typography>

        {/* Close Icon Button */}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {dynamicFormData?.intr_id ? (
          <>
            {/* Change logs icon & Download template icon */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: -2 }}>
              {/* Change logs icon */}
              <Tooltip title="Change logs">
                <IconButton
                  size="small"
                  sx={{ color: "green" }}
                  onClick={(e) =>
                    handleTemplateLogsDialogOpen({ pageNo: 1, pageSize: 25 })
                  }
                >
                  <HistoryEduIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
              {/* Download template icon */}
              <Tooltip title="Download template">
                <IconButton
                  size="small"
                  sx={{ color: "green" }}
                  onClick={() =>
                    generatePDF(templateRef, { filename: "page.pdf" })
                  }
                >
                  <FileDownloadIcon fontSize="medium" />
                </IconButton>
              </Tooltip>
            </Box>
            {/* Intersection details */}
            <Paper elevation={2} sx={styles.intDetailsContainer}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "center",
                  pt: 0.4,
                }}
              >
                Intersection Details
              </Typography>
              <Divider sx={{ mt: 0.4, mb: 1 }} />
              <Grid container>
                {/* Intersection Id */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle2" sx={styles.intDetailsTxt}>
                      <span style={styles.txtBold}>Intersection Id: </span>
                      {dynamicFormData?.intr_id || "-"}
                    </Typography>
                  </Box>
                </Grid>
                {/* Ticket Id */}
                {/* <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle2" sx={styles.intDetailsTxt}>
                  <span style={styles.txtBold}>Ticket Id: </span>
                  {dynamicFormData?.ticket_id || "-"}
                </Typography>
              </Box>
            </Grid> */}
                {/* Form type */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle2" sx={styles.intDetailsTxt}>
                      <span style={styles.txtBold}>Form type: </span>
                      {dynamicFormData?.type === FORM_TYPES?.EE
                        ? "EE Component"
                        : dynamicFormData?.type === FORM_TYPES?.SWM
                        ? "Software Module"
                        : dynamicFormData?.type === FORM_TYPES?.VEHICLE
                        ? "Vehicle Component"
                        : "-"}
                    </Typography>
                  </Box>
                </Grid>
                {/* Form name */}
                <Grid item xs={12} sm={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography variant="subtitle2" sx={styles.intDetailsTxt}>
                      <span style={styles.txtBold}>Form name: </span>
                      {dynamicFormData?.name || "-"}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
            {/* Template */}
            <Box
              ref={templateRef}
              sx={{
                minHeight: "315mm",
                padding: "10px",
                display: "flex",
                textDecoration: "none",
                justifyContent: "space-between",
              }}
            >
              <DynamicFormTemplate
                fields={fields}
                EDIT_TYPES={EDIT_TYPES}
                showOnlyTemplate={true}
                handleEditField={handleEditField}
                headerOptionsList={headerOptionsList}
                signatureOptionsList={signatureOptionsList}
                footerOptionsList={footerOptionsList}
                selectedHeaders={selectedHeaders}
                selectedSignatures={selectedSignatures}
                selectedFooters={selectedFooters}
                dynamicFormData={dynamicFormData}
                setDynamicFormData={setDynamicFormData}
              />
            </Box>
            {/* Change logs */}
            <CustomLogsDialog
              open={openTemplateChangeLogsDialog}
              handleClose={handleTemplateLogsDialogClose}
              dialogTitle={"Change Logs"}
              dialogContent={templateChangeLogsList}
              data={templateLogsDialogData}
              getLogsData={handleGetTemplateChangeLogs}
            />
          </>
        ) : (
          <Box
            style={{
              height: "370px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">No attachments found</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ boxShadow: 4, gap: 2, px: 2, py: 1 }}>
        <CustomButton
          size={"small"}
          variant={"contained"}
          btnName={"Close"}
          startIcon={<CloseIcon />}
          handleOnClick={onClose}
          btnStyle={{}}
        />
        {dynamicFormData?.intr_id ? (
          <CustomButton
            size={"small"}
            variant={"contained"}
            btnName={"Update"}
            startIcon={<UpdateIcon />}
            handleOnClick={() => {}}
            btnStyle={{}}
          />
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default TemplateDialog;

const styles = {
  intDetailsContainer: {
    px: 2,
    // py: 1,
    // mx: 1,
    height: "100%",
    border: "1px solid grey",
    borderRadius: 1,
  },
  intDetailsTxt: {
    textAlign: "start",
    alignContent: "center",
  },
  txtBold: {
    fontWeight: "bold",
  },
};
