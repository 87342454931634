/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 1-07-2024
 * @description Component for handling the PowerTrain Class.
 */
import {
  Box,
  Collapse,
  DialogContentText,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CustomButton from "../../components/buttons/CustomButton";
import CustomTextField1 from "../../components/text-fields/CustomTextField1";
import customConsole from "../../config/customConsole";

import CustomTextFieldSP from "../../components/text-fields/CustomTextFieldSP";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import profileActions from "../../redux/actions/profile";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";

import AddIcon from "@mui/icons-material/Add";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import CustomAddDialog from "../../components/dialog-boxes/CustomAddDialog";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import CustomConfirmationDialog from "../../components/dialog-boxes/CustomConfirmationDialog";
import { ChipsTextField } from "../../components";

function PowerTrainClass({ open, handleClick }) {
  const COLUMNS = [
    { field: "sno", headerName: "S.No", width: 100 },
    {
      field: "abbreviation",
      headerName: "Abbreviation",
      width: 150,
    },
    { field: "name", headerName: "Name", width: 250 },
    {
      field: "description",
      headerName: "Description",
      width: 200,
    },
    {
      field: "configuration",
      headerName: "Configuration",
      width: 200,
      // renderCell: (params) => {
      //   return console.log("params????>>>>", params);
      // },
      renderCell: (params) => (
        <>
          {params?.row?.configuration?.length > 0 ? (
            <FormControl
              fullWidth
              sx={{ mt: "5px", pb: "10px", maxWidth: 150 }}
            >
              <Select
                variant="filled"
                id={`select-${params?.row?.id}`}
                value={params?.row?.configuration?.[0]}
                onChange={(e) => handleTableClick(params?.row?.id, e)}
              >
                {params?.row?.configuration?.map((data, index) => (
                  <MenuItem key={index} value={data}>
                    {data}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </>
      ),
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 200,
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            handleTableRowClick(params, true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  //1.Redux

  const dispatch = useDispatch();
  const powerTrainClassDetails = useSelector(
    (state) => state.powerTrainClassReducer
  );
  const timeZoneDetails = useSelector((state) => state.profileReducer);

  //2.React useState
  const [page, setPage] = React.useState(0);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getPowerTrainClass, setGetPowerTrainClass] = React.useState([]);
  const [openAddPowerDialog, setOpenAddPowerDialog] = React.useState(false);
  const [openEditPowerDialog, setOpenEditPowerDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openDeletePowerDialog, setOpenDeletePowerDialog] =
    React.useState(false);
  const [timeZone, setTimeZone] = React.useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableOpen, setTableOpen] = useState(false);

  //3.React useEffect
  useEffect(() => {
    console.log("getPowerTrainClass?????", getPowerTrainClass);
    let filteredUsersList = getPowerTrainClass?.map((data, index) => {
      return {
        ...data,
        sno: index + 1,
        id: data?.id,
        abbreviation: data?.abbreviation,
        name: data?.name,
        configuration: data?.other_fields?.configurations,
        description: data?.other_fields?.description,
        created_at: convertUtcToTimeZone(
          data?.created_at,
          timeZoneDetails?.profile?.profile?.region
        ),
        updated_at: convertUtcToTimeZone(
          data?.updated_at,
          timeZoneDetails?.profile?.profile?.region
        ),
      };
    });

    console.log("filteredUsersList", filteredUsersList);

    setTableRowsData(filteredUsersList);
  }, [getPowerTrainClass]);

  const filteredRows = useMemo(() => {
    if (!searchQuery) return tableRowsData;
    return tableRowsData.filter((row) =>
      COLUMNS.some((column) =>
        row[column.field]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, tableRowsData, COLUMNS]);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.get_powertrain_class,
        show_toast: false,
      })
    );
    dispatch(HandleApiActions({ ...profileActions.get_coutry_regions }));
  }, []);

  useEffect(() => {
    if (
      timeZoneDetails &&
      timeZoneDetails?.profile &&
      timeZoneDetails?.profile?.profile &&
      timeZoneDetails?.profile?.profile?.region &&
      timeZoneDetails?.profile?.profile?.region.length > 0
    ) {
      customConsole("timeZoneDetails?.data?.profile?.region");
      customConsole(timeZoneDetails?.profile?.profile?.region);
      setTimeZone(timeZoneDetails.profile.profile.region);
    }
  }, [timeZoneDetails]);

  useEffect(() => {
    if (
      powerTrainClassDetails &&
      powerTrainClassDetails?.powerTrainClassDetails &&
      powerTrainClassDetails?.powerTrainClassDetails?.data &&
      powerTrainClassDetails?.powerTrainClassDetails?.data?.length > 0
    ) {
      customConsole("Power Train Details");
      customConsole(powerTrainClassDetails);

      setGetPowerTrainClass(powerTrainClassDetails.powerTrainClassDetails.data);
    }
  }, [powerTrainClassDetails]);

  //4.Functions
  // Toggles the state of the dialog.
  const toggleDialog = (newOpen) => () => {
    setOpenAddPowerDialog(newOpen);
  };
  //Function To Add Button
  const handleAddPowerTrainClick = () => {
    toggleDialog(true)();
  };

  const handleTableClick = (id, e) => {
    e.stopPropagation();
    setTableOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  //Function to Click Row
  const handleTableRowClick = (params, isButtonClicked) => {
    setSelectedRow(params);
    customConsole("Row Selected");
    customConsole(params);
    if (isButtonClicked) {
      setOpenDeletePowerDialog(true);
    } else {
      setOpenEditPowerDialog(true);
    }
  };

  return (
    <div>
      <List sx={styles.list} component="nav">
        <ListItemButton onClick={() => handleClick("3")}>
          <ListItemText
            sx={styles.listText}
            primary="Powertrain Classes"
            primaryTypographyProps={styles.primaryTypography}
          />
          {open === "3" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "3"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "5px" }} />
          <List component="div" disablePadding sx={styles.list1}>
            <Box>
              <Paper sx={styles.container}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "60px",
                    mr: "10px",
                    ml: "10px",
                  }}
                >
                  <Box>
                    <TextField
                      variant="standard"
                      label="Search"
                      size="small"
                      margin="normal"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      sx={{ margin: 0 }}
                    />
                  </Box>
                  <Box sx={styles.tblFun}>
                    <CustomButton
                      size={"medium "}
                      variant={"contained"}
                      btnName={"Add "}
                      startIcon={<AddIcon />}
                      handleOnClick={handleAddPowerTrainClick}
                      btnStyle={{
                        ml: 2,
                      }}
                    />
                  </Box>
                </Box>

                <div
                  style={{
                    height: 400,
                    width: "100%",
                    scrollbarColor: "#888 #f1f1f1",
                  }}
                >
                  <DataGrid
                    initialState={{
                      density: "comfortable",
                    }}
                    sx={{
                      "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1":
                        {
                          display: "block",
                        },
                      "& .MuiDataGrid-columnHeaders": {
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "white",
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    localeText={{ toolbarDensity: "View" }}
                    // autoHeight={true}
                    rows={filteredRows}
                    columns={COLUMNS}
                    disableColumnMenu
                    disableColumnSelector
                    disableColumnFilter
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbar }}
                    pageSize={rowsPerPage}
                    rowCount={
                      tableRowsData?.length >= rowsPerPage
                        ? -1
                        : page * rowsPerPage + tableRowsData?.length
                    }
                    onPaginationModelChange={(model) => {
                      setPage(model.page);
                      setRowsPerPage(model.pageSize);
                    }}
                    paginationModel={{ page: page, pageSize: rowsPerPage }}
                    pageSizeOptions={[25, 50, 100]}
                    slotProps={{
                      toolbar: { printOptions: { disableToolbarButton: true } },
                    }}
                    onRowClick={(params) => {
                      handleTableRowClick(params, false);
                    }}
                  />
                </div>

                <AddPowerTrainClass
                  open={openAddPowerDialog}
                  toggleDialog={toggleDialog}
                />
                <EditPowerTrainClass
                  open={openEditPowerDialog}
                  setOpenEditPowerDialog={setOpenEditPowerDialog}
                  selectedRow={selectedRow}
                />

                <DeletePowerTrainClass
                  openDeletePowerDialog={openDeletePowerDialog}
                  setOpenDeletePowerDialog={setOpenDeletePowerDialog}
                  selectedRow={selectedRow}
                />
              </Paper>
            </Box>
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default PowerTrainClass;

// Styling for the Users component
const styles = {
  container: {
    width: "100%",
    overflow: "auto",
  },
  tblFun: {
    mt: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  list: {
    width: "100%",
    maxWidth: "100%",
    marginTop: "15px",
    backgroundColor: "whitesmoke",
    boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
    borderRadius: " 0px",
  },
  listText: {
    textAlign: "start",
    color: "black",
  },
  primaryTypography: {
    fontWeight: 600,
    fontSize: "18px",
  },
  list1: {
    background: "whitesmoke",
    margin: "5px",
    padding: "10px",
    borderRadius: "10px",

    maxHeight: "500px",
  },
  dataGridStyle: { height: 300, width: "100%" },
  dataGrid: {
    "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1": {
      display: "block",
    },
    "& .MuiDataGrid-columnHeaders": {
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
      },
    },
    position: "sticky",
  },
};

const AddPowerTrainClass = ({ open, toggleDialog }) => {
  //1.Redux
  const dispatch = useDispatch();

  //2.React useState
  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [description, setDescription] = useState("");
  const [configuration, setConfiguration] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [abbreviationError, setAbbreviationError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  //3.Functions
  const handleClose = () => {
    toggleDialog(false)();
    setName("");
    setAbbreviation("");
    setDescription("");
  };

  const handleClickAdd = () => {
    const reqObj = {
      current_time: new Date(),
      abbreviation: abbreviation,
      name: name,
      ...(configuration ? { configurations: configuration } : {}),
    };

    if (description?.trim()) {
      reqObj.description = description;
    }

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_powertrain_class,
        show_toast: true,
        params: reqObj,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_powertrain_class,
          show_toast: false,
        })
      );
    });
    toggleDialog(false)();
    setName("");
    setDescription("");
    setAbbreviation("");
    setConfiguration([]);
  };

  return (
    <>
      <CustomAddDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Add Powertrain Class"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextFieldSP
              required
              size="small"
              label="Name"
              placeholder="Name"
              value={name}
              setValue={setName}
              txtFldStyle={{ width: "270px" }}
              validationRules={{ minLength: 3 }}
              setError={setNameError}
              error={nameError !== ""}
            />
            <CustomTextFieldSP
              required
              size="small"
              label="Abbreviation"
              placeholder="Abbreviation"
              value={abbreviation}
              setValue={setAbbreviation}
              txtFldStyle={{ width: "270px" }}
              validationRules={{ minLength: 2 }}
              setError={setAbbreviationError}
              error={abbreviationError !== ""}
            />
            <CustomTextFieldSP
              size="small"
              label="Description"
              placeholder="Description"
              value={description}
              setValue={setDescription}
              txtFldStyle={{ width: "270px" }}
              validationRules={{ minLength: 5 }}
              setError={setDescriptionError}
              error={descriptionError !== ""}
            />
            <ChipsTextField
              size={"small"}
              label={"Configurations"}
              placeholder={"Configurations"}
              variant={"standard"}
              chips={configuration}
              setChips={setConfiguration}
              txtFldStyle={styles.chipsTextField}
            />
          </Stack>
        }
        onConfirm={handleClickAdd}
        onDisabled={
          name?.length > 2 &&
          name?.trim() !== "" &&
          abbreviation?.length > 1 &&
          abbreviation?.trim() !== ""
            ? false
            : true
        }
      />
    </>
  );
};

const EditPowerTrainClass = ({ open, setOpenEditPowerDialog, selectedRow }) => {
  //1.Redux
  const dispatch = useDispatch();

  //2.React useState
  const [editName, setEditName] = useState("");
  const [editAbbreviation, setEditAbbreviation] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editConfiguration, setEditConfiguration] = useState([]);
  const [descriptionError, setDescriptionError] = useState(false);

  ///3.React useEffect
  React.useEffect(() => {
    console.log("selectedRow?.row", selectedRow?.row);
    if (selectedRow) {
      setEditName(selectedRow?.row?.name || "");
      setEditAbbreviation(selectedRow?.row?.abbreviation || "");
      setEditDescription(selectedRow?.row?.other_fields?.description || "");
      setEditConfiguration(selectedRow?.row?.configuration || []);
    }
  }, [selectedRow]);

  //4.Functions
  const handleClose = () => {
    setOpenEditPowerDialog(false);
  };

  const handleClickEdit = () => {
    const reqObj = {
      current_time: new Date().toISOString(),
      id: selectedRow?.row?.id,
      description: editDescription,
      configurations: editConfiguration,
    };

    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.update_powertrain_class,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...adminSettingsAPI.get_powertrain_class }));
    });
    setOpenEditPowerDialog(false);
  };

  return (
    <>
      <CustomUpdateDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Edit Powertrain Class"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextField1
              size={"small"}
              label={"Name"}
              placeholder={"Name"}
              value={editName}
              setValue={setEditName}
              txtFldStyle={{
                width: "270px",
              }}
              disabled={true}
            />
            <CustomTextField1
              size={"small"}
              label={"Abbreviation"}
              placeholder={"Abbreviation"}
              value={editAbbreviation}
              setValue={setEditAbbreviation}
              txtFldStyle={{
                width: "270px",
              }}
              disabled={true}
            />
            <CustomTextFieldSP
              size={"small"}
              label={"Description"}
              placeholder={"Description"}
              value={editDescription}
              setValue={setEditDescription}
              txtFldStyle={{
                width: "270px",
              }}
              validationRules={{ minLength: 5 }}
              setError={setDescriptionError}
              error={descriptionError !== ""}
            />
            <ChipsTextField
              size={"small"}
              label={"Configurations"}
              placeholder={"Configurations"}
              variant={"standard"}
              chips={editConfiguration}
              setChips={setEditConfiguration}
              txtFldStyle={styles.chipsTextField}
            />
          </Stack>
        }
        onConfirm={handleClickEdit}
        onDisabled={
          editDescription.length > 5 && editDescription?.trim() !== ""
            ? false
            : true
        }
      />
    </>
  );
};

const DeletePowerTrainClass = ({
  openDeletePowerDialog,
  setOpenDeletePowerDialog,
  selectedRow,
}) => {
  //Redux
  const dispatch = useDispatch();

  const handleDelete = () => {
    const reqObj = {
      current_time: new Date(),
      id: selectedRow?.row?.id,
    };
    customConsole("reqObj");
    customConsole(reqObj);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_powertrain_class,
        show_toast: true,
        params: reqObj,
      })
    ).then(() => {
      setTimeout(() => {
        dispatch(
          HandleApiActions({
            ...adminSettingsAPI.get_powertrain_class,
            show_toast: false,
          })
        );
      }, 500);
    });
    setOpenDeletePowerDialog(false);
  };
  return (
    <CustomConfirmationDialog
      open={openDeletePowerDialog}
      onClose={() => setOpenDeletePowerDialog(false)}
      dialogTitle={"Confirm Delete"}
      dialogContent={
        <DialogContentText>Are you sure want to delete?</DialogContentText>
      }
      onConfirm={handleDelete}
    />
  );
};
