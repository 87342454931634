/**
 * @author :Abdul Rahim M -abdul@au79consulting.com
 * @Date : 11-12-2024
 * @description : Component for simulation files
 * @param : none
 * @return : The rendered VLC page.
 */

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CustomFiltersN from "../../components/tables/CustomFiltersN";
import React, { useEffect, useState } from "react";
import {
  CustomButton,
  CustomDataGridTableB,
  CustomDataGridTableN,
  CustomPagination,
  CustomSorting,
  SelectTextFieldNC,
} from "../../components";
import CloseIcon from "@mui/icons-material/Close";

import {
  HandleApiActions,
  labCar,
  legalRequirements,
  profileActions,
  userManagementApis,
} from "../../redux/actions";
import typeApprovalAPI from "../../redux/actions/typeApprovalAPI";
import { useDispatch, useSelector } from "react-redux";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import appTheme from "../../assets/AppTheme/appTheme";
import VisibilityIcon from "@mui/icons-material/Visibility";
import customConsole from "../../config/customConsole";
import { toast } from "react-toastify";
import TicketsType from "../../components/labcar/TicketsType";
import TemplateDialog from "../../components/dynamic-form/TemplateDialog";
import { TICKETS_TYPES } from "../../config/appConstants";
import CustomFiltersMulti from "../../components/tables/CustomFiltersMulti";
import ViewDynammicFormDetails from "../admin-settings/DynamicForms/ViewDynammicFormDetails";
import { Grid } from "@mui/material";
import SortByOptionsTickets from "../../components/tickets/SortByOptionsTickets";
import FilterByOptionsTickets from "../../components/tickets/FilterByOptionsTickets";

function SimulationFiles({ selectedProjectDetails, selectedVariantDetails }) {
  //Diapatch and Selector function to handle redux store
  const dispatch = useDispatch();

  const profileDetails = useSelector((store) => store.profileReducer);
  const imagePathDetails = useSelector((state) => state?.profileReducer);
  const ticketStatusMetaApp = useSelector(
    (state) => state.settingsReducer?.metaData
  );

  const EEDetails = useSelector((state) => state?.labCarReducer);
  const UserDetails = useSelector((state) => state.userManagementReducer);

  const LSDetails = useSelector((state) => state.legalRequirementsReducer);

  const [tableRowsData, setTableRowsData] = useState([]);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [legalDialogData, setLegalDialogData] = useState(null);
  const [isLegalDialogOpen, setIsLegalDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [timer, setTimer] = useState(5);
  const [currentTicketId, setCurrentTicketId] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  // EE Dialog
  const [isEEDialogOpen, setIsEEDialogOpen] = useState(false);
  const [eeDialogData, setEEDialogData] = useState(null);
  const [dialogData, setDialogData] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Pagination
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [hasMore, setHasMore] = useState(false);

  // Sorting
  const [anchorElSort, setAnchorElSort] = useState(null);
  const [openSort, setOpenSort] = useState(false);
  const [selectedSortColumn, setSelectedSortColumn] = useState("updated_at");
  const [selectedSortOrder, setSelectedSortOrder] = useState("DESC");

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("role_name");
  const [searchFieldValue, setSearchFieldValue] = useState("");

  const [openModal, setOpenModal] = React.useState(false);
  const [imagePath, setImagePath] = React.useState("");

  // Template dialog states
  const [isTemplateDialogOpen, setIsTemplateDialogOpen] = useState(false);
  // Dynamic form data
  const [dynamicFormData, setDynamicFormData] = useState(null);

  // Status
  const [ticketStatusData, setTicketStatusData] = useState([]);
  const [filteredEEComponents, setFilteredEEComponents] = useState([]);
  const [filteredVehicleComponents, setFilteredVehicleComponents] = useState(
    []
  );

  //useState for Sort
  const [selectColumn, setSelectColumn] = useState("updated_at");
  const [selectOrder, setSelectOrder] = useState("DESC");

  //useState for Filter
  const [searchIntrId, setSearchIntrId] = useState("");
  const [searchTicketId, setSearchTicketId] = useState("");
  const ticketsType = { SWM: 1, EE: 2, VEHICLE: 3 };
  const [selectedTicketsType, setSelectedTicketsType] = useState(
    ticketsType?.EE
  );

  const [selectTicketStatus, setSelectTicketStatus] = useState([]);

  const [selectedZones, setSelectedZones] = useState([]);
  const [selectOEM, setSelectOEM] = useState([]);
  const [selectTECH, setSelectTECH] = useState([]);
  const [selectedEE, setSelectedEE] = useState([]);
  const [selectSWMEE, setSelectSWMEE] = useState("");
  const [selectedSWM, setSelectedSWM] = useState([]);
  const [selectedVC, setSelectedVC] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [selectedLR, setSelectedLR] = useState([]);

  /* Handle Template Dialog Function */
  const handleTemplateDialog = (confirm) => {
    if (confirm) {
    }

    setIsTemplateDialogOpen(false);
  };

  //useEffect for Filter
  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis?.get_users_list,
        params: {
          page_size: 2000,
        },
      })
    );
  }, []);
  useEffect(() => {
    if (selectedProjectDetails && selectedVariantDetails) {
      dispatch(
        HandleApiActions({
          ...labCar?.get_ee_components,
          params: {
            project_id: selectedProjectDetails?.project_id,
            variant_id: selectedVariantDetails?.variant_id,
          },
          show_toast: false,
        })
      );
    }
  }, [selectedProjectDetails, selectedVariantDetails]);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_size: 1000,
          role_type: 2,
        },
        show_toast: false,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...labCar?.get_sw_modules,
        params: {
          project_id: selectedProjectDetails?.project_id,
          variant_id: selectedVariantDetails?.variant_id,
          ee_id: selectSWMEE,
        },
        show_toast: false,
      })
    );
  }, [selectSWMEE]);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_standard_details,
        params: {
          page_number: 1,
          page_size: 200,
        },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_requirements_details,
        params: {
          page_number: 1,
          page_size: 200,
        },
      })
    );
  }, []);

  const reqObj = {
    project_id: selectedProjectDetails?.project_id,
    variant_id: selectedVariantDetails?.variant_id,
    ...(selectOEM?.length > 0
      ? { oem_users: Array.isArray(selectOEM) ? selectOEM.join(",") : null }
      : null),
    ...(selectTECH?.length > 0
      ? {
          ts_users: Array.isArray(selectTECH) ? selectTECH.join(",") : null,
        }
      : null),

    ...(selectTicketStatus?.length > 0
      ? {
          status: Array.isArray(selectTicketStatus)
            ? selectTicketStatus.join(",")
            : null,
        }
      : null),
    ...(selectedZones?.length > 0
      ? {
          zones: Array.isArray(selectedZones) ? selectedZones.join(",") : null,
        }
      : null),
    ...(selectedEE?.length > 0
      ? {
          ee_id: Array.isArray(selectedEE) ? selectedEE.join(",") : null,
        }
      : null),

    ...(selectedVC?.length > 0
      ? {
          vc_id: Array.isArray(selectedVC) ? selectedVC.join(",") : null,
        }
      : null),
    ...(selectedSWM?.length > 0
      ? {
          swm_id: Array.isArray(selectedSWM) ? selectedSWM.join(",") : null,
        }
      : null),
    ...(selectedStandard?.length > 0
      ? {
          ls_id: Array.isArray(selectedStandard)
            ? selectedStandard.join(",")
            : null,
        }
      : null),
    ...(selectedChapter?.length > 0
      ? {
          chapters: Array.isArray(selectedChapter)
            ? selectedChapter.join(",")
            : null,
        }
      : null),
    ...(selectedLR?.length > 0
      ? {
          chapters: Array.isArray(selectedLR) ? selectedLR.join(",") : null,
        }
      : null),

    page_number: page,
    page_size: rowsPerPage,
    ...(selectColumn ? { sort_column: selectColumn } : null),
    ...(selectOrder ? { sort_order: selectOrder } : null),

    tickets_type: selectedTicketsType,
  };

  const fetchTickets = async (requestObj) => {
    const resp = await dispatch(
      HandleApiActions({
        ...typeApprovalAPI?.get_tickets,
        params: requestObj,
        show_toast: false,
      })
    );
    console.log("respresp", resp);

    if (resp.error) {
      setTicketDetails([]);
    } else {
      setTicketDetails(resp?.data);
    }
  };

  useEffect(() => {
    // Fetch ticket list
    fetchTickets(reqObj);

    // Reset table rows data when dependencies change
    setTableRowsData([]);
  }, [
    selectedChapter,
    selectedStandard,
    selectedLR,
    page,
    rowsPerPage,
    selectOrder,
    selectOEM,
    selectedZones,
    selectTECH,
    selectedVC,
    selectColumn,
    selectedEE,
    selectTicketStatus,
    selectedTicketsType, // Ticket type filter
    selectedSearchField, // Selected search field
    searchFieldValue, // Search query value
    selectedProjectDetails?.project_id, // Project ID
    selectedVariantDetails?.variant_id, // Variant ID
  ]);
  // Map ticket details in table row data
  useEffect(() => {
    const filteredTicketList = ticketDetails
      ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
      // ?.filter((item) => item?.status !== 101)
      ?.map((item, index) => ({
        ...item,
        id: (page - 1) * rowsPerPage + index + 1,
        intr_id: item?.intr_id,
        tech_service_users: item?.tech_service_users,
        oem_users: item?.oem_users,
        users: item?.tech_service_users || item?.oem_users,
        ticket_id: item?.ticket_id,
        tickets_type: item?.tickets_type,
        status: item?.status,
        ticket_status: item?.ticket_id || item?.status,
        ls_details: item?.ls_details,
        lr_details: item?.lr_details,
        swm_details: item?.swm_details,
        vehicle_component_details: item?.vehicle_component_details,
        ee_details: item?.ee_details,
        description: item?.description,
        created_at: convertUtcToTimeZone(
          item?.created_at,
          profileDetails?.profile?.profile?.region
        ),
        created_by: item?.created_by,
        updated_at: convertUtcToTimeZone(
          item?.updated_at,
          profileDetails?.profile?.profile?.region
        ),
        updated_by: item?.updated_by,
        allData: item,
      }));

    // Handle pagination hasMore
    if (filteredTicketList?.length < rowsPerPage) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
    setTableRowsData(filteredTicketList);
  }, [ticketDetails, selectedSortColumn, selectedSortOrder]);

  useEffect(() => {
    setImagePath(imagePathDetails?.profile_pic_path);
  }, [imagePathDetails]);

  //Effect to handle status dropdown to filter tickets
  useEffect(() => {
    // Assuming ticketStatusMetaApp.snow_ticket_status is available
    if (
      !ticketStatusMetaApp ||
      !Array.isArray(ticketStatusMetaApp.snow_ticket_status)
    )
      return;

    const filteredStatus = ticketStatusMetaApp.snow_ticket_status
      .filter((status) => status.value !== undefined && status.value !== 101)
      .map((status) => ({
        value: status.value,
        label: status.key,
      }))
      .sort((a, b) => a.value - b.value);
    setTicketStatusData(filteredStatus);
  }, [ticketStatusMetaApp]);

  const moduleDetailsOptionsList = [
    {
      value: 1,
      label: "Speed Conversion System",
      label1: "NDS68956687",
    },
    {
      value: 2,
      label: "Engine Control System",
      label1: "NDS68956657",
    },
    {
      value: 3,
      label: "Rear Auto Breaking System",
      label1: "NDS689566986",
    },
  ];

  const handleClearAllFilter = () => {
    setSearchIntrId("");
    setSearchTicketId("");
    setSelectOEM([]);
    setSelectTECH([]);
    setSelectedStandard([]);
    setSelectedChapter([]);
    setSelectedLR([]);
    setSelectedZones([]);
    setSelectedVC([]);
    setSelectedSWM([]);
    setSelectedEE([]);
    setSelectTicketStatus([]);
  };

  // RenderValue must be a function
  const renderValue = (selected) => {
    if (!selected) return "Select an option"; // Placeholder text
    const selectedOption = moduleDetailsOptionsList?.find(
      (option) => option.value === selected
    );
    if (!selectedOption) return "Invalid selection"; // Fallback for invalid selections
    return (
      <>
        {selectedOption.label}
        <br />
        {selectedOption.assembly_number}
      </>
    );
  };

  const handleImageChange = async (event, params) => {
    console.log("parrrwfwsfs", params);
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (allowedExtensions.includes(fileExtension)) {
        // Upload the Profile Picture => Get the file name
        const resp = await dispatch(
          HandleApiActions({
            ...profileActions.upload_profile_pic,
            show_toast: true,
            file: file,
            is_file: true,
          })
        );

        await dispatch(
          HandleApiActions({
            api_name: "/ACM/intersections/upload/simulation-file",
            https_method: "PUT",
            params: {
              intr_id: params?.row?.intr_id,
              file_name: resp?.file_name,
            },
            show_toast: true,
          })
        ).then(() => {
          dispatch(
            HandleApiActions({
              ...typeApprovalAPI?.get_tickets,
              params: {
                project_id: selectedProjectDetails?.project_id,
                variant_id: selectedVariantDetails?.variant_id,
                page_number: page,
                page_size: rowsPerPage,
                sort_column: selectedSortColumn,
                sort_order: selectedSortOrder,
                ...(searchFieldValue && {
                  [selectedSearchField]: searchFieldValue,
                }),
                tickets_type: selectedTicketsType,
              },
              show_toast: false,
            })
          );
        });
      } else {
        toast.dark("Please select a valid image file", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
  };

  const handleImageRowClick = (row) => {
    setOpenModal(true);

    console.log("roeewewew", row?.row?.simulation_file);
    dispatch(
      HandleApiActions({
        ...profileActions.get_profile_pic,
        show_toast: true,
        file_name: row?.row?.simulation_file,
        is_file: true,
      })
    );
  };

  const getTicketsTemplates = async ({ intrId }) => {
    const apiDetails = typeApprovalAPI.get_user_dynamic_forms;
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        show_toast: false,
        params: {
          project_id: selectedProjectDetails?.project_id,
          variant_id: selectedVariantDetails?.variant_id,
          intr_id: intrId,
          page_number: 1,
          page_size: 100,
          is_ticket_required: true,
        },
      })
    );
    if (resp.error) {
      setDynamicFormData([]);
    } else {
      console.log("resp?.data?.[0]", resp?.data?.[0]);
      setDynamicFormData(resp?.data?.[0]);
    }
  };

  useEffect(() => {
    if (open) {
      const id = setInterval(() => {
        setTimer((prev) => {
          if (prev <= 1) {
            clearInterval(id);
            handleRedirect();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      setIntervalId(id);

      return () => {
        clearInterval(id);
      };
    }
  }, [open]);

  const COLUMNS = [
    {
      field: "intr_id",
      headerName: "Intersection Id",
      width: 120,
    },
    {
      field: "ticket_id",
      headerName: "TICKET ID",
      headerAlign: "center",
      width: 140,
      renderCell: (params) => (
        <Box sx={{ textAlign: "center" }}>
          <LinkButton onClick={() => handleClickOpen(params.value)}>
            {params?.value}
          </LinkButton>

          <Dialog
            open={open}
            onClose={handleClose}
            BackdropProps={{
              style: { backgroundColor: "transparent" },
            }}
            PaperProps={{
              style: {
                width: "400px",
                height: "180px",
                borderRadius: "15px",
              },
            }}
          >
            <DialogTitle>
              <IconButton
                // edge="end"
                // color="inherit"
                onClick={handleClose}
                sx={styles.closeButton}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                Redirecting to ServiceNow <br />
                Ticketing tool!
              </Typography>

              <CountdownTimer
                onClick={handleRedirect}
                variant="h6"
                sx={{ mt: "20px", ml: "120px" }}
              >
                In ({timer})s
              </CountdownTimer>
            </DialogContent>
          </Dialog>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography sx={{ textTransform: "uppercase" }}>
            {getTicketStatusName(params?.row?.status || "-")}
          </Typography>
          {/* <IconButton
            sx={{ color: "blue" }}
            size="small"
            onClick={() => handleEditClick(params)}
          >
            <EditIcon sx={styles.editIcon} />
          </IconButton> */}
        </Box>
      ),
    },
    {
      field: "changed_by",
      headerName: "Changed By",
      width: 270,
      renderCell: (params) => {
        console.log("kdgjgaparams", params);

        return (
          <Box>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Name: </span>
              {params?.row?.updated_user_details[0]?.name || "-"}
            </Typography>
            <Typography variant="subtitle2">
              <span style={styles.txtBold}>Email: </span>
              {params?.row?.updated_user_details[0]?.email || "-"}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "simulation_file",
      headerName: "Simulation File",
      width: 170,
      renderCell: (params) => {
        console.log("params>>???>", params);

        return params?.row?.simulation_file === "" ? (
          <Box sx={{ mt: 1.4, ml: 2 }}>
            <Button
              size="small"
              sx={{ ...appTheme.commonBtnStyle }}
              variant="contained"
              component="label"
              disabled={params?.row?.status !== 101 ? true : false}
            >
              Generate Test
              <input
                type="file"
                hidden
                onChange={(event) => handleImageChange(event, params)}
              />
            </Button>
          </Box>
        ) : (
          <IconButton
            onClick={() => {
              handleImageRowClick(params);
            }}
          >
            <VisibilityIcon />
          </IconButton>
        );
      },
    },
    // {
    //   field: "module_details",
    //   headerName: "Module Details",
    //   width: 270,
    //   renderCell: (params) => (
    //     <Box>
    //       <Box>
    //         <Typography variant="subtitle1">
    //           <span style={styles.txtBold}>Vehicle Components</span>
    //         </Typography>
    //       </Box>
    //       <Box>
    //         <SelectTextFieldNC
    //           size={"small"}
    //           label={"Module Details"}
    //           variant={"standard"}
    //           optionsList={moduleDetailsOptionsList}
    //           selectedValue={params?.row?.module_details?.id}
    //           handleOnChange={(e) => {}}
    //           sltFldStyle={styles.selectTxtStyle}
    //           renderValue={renderValue}
    //         />
    //       </Box>
    //     </Box>
    //   ),
    // },
    {
      field: "ee_id",
      headerName: "MODULE TYPE",
      headerAlign: "left",
      width: 250,
      renderCell: (params) => {
        const { swm_details, ee_details, vehicle_component_details } =
          params?.row;

        const renderDetails = (label, details) => (
          <>
            <Box sx={{ fontWeight: "bold", marginBottom: "8px" }}>{label}</Box>
            {Object.entries(details).map(([key, value]) => (
              <Box key={key}>
                <strong>{key.replace(/_/g, " ").toUpperCase()}:</strong>{" "}
                {value || "-"}
              </Box>
            ))}
          </>
        );

        return (
          <Box sx={{ textAlign: "left", fontSize: "14px" }}>
            {swm_details && Object.keys(swm_details).length > 0 ? (
              <>
                {renderDetails("SOFTWARE MODULE", {
                  Name: swm_details?.name,
                  Version: swm_details?.version,
                  Description: swm_details?.description,
                })}
                {swm_details?.name && (
                  <Box sx={{ textAlign: "left", marginTop: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleSWMViewButtonClick(params?.row?.allData)
                      }
                      sx={{ ...appTheme.commonBtnStyle, fontSize: "12px" }}
                      size="small"
                    >
                      View more {" >>"}
                    </Button>
                  </Box>
                )}
              </>
            ) : ee_details && Object.keys(ee_details).length > 0 ? (
              <>
                {renderDetails("EE COMPONENT", {
                  "Supplier Name": ee_details?.supplier_name,
                  "Assembly Number": ee_details?.assembly_number,
                  Version: ee_details?.version,
                  Description: ee_details?.description,
                })}
                {params?.row?.allData?.dependencies?.ee_components && (
                  <Box sx={{ textAlign: "left", marginTop: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleEEViewButtonClick(params?.row?.allData)
                      }
                      sx={{ ...appTheme.commonBtnStyle, fontSize: "12px" }}
                      size="small"
                    >
                      View more {" >>"}
                    </Button>
                  </Box>
                )}
              </>
            ) : vehicle_component_details &&
              vehicle_component_details.length > 0 ? (
              <>
                {/* <label style={{ fontWeight: "bold", fontSize: "14px" }}>
                VEHICLE COMPONENTS
              </label> */}
                <Select
                  value={vehicle_component_details}
                  displayEmpty
                  sx={{ height: "40px", fontSize: "15px" }}
                  renderValue={(selected) => {
                    const selectedComponent = vehicle_component_details.find(
                      (component) => component.id === selected
                    );
                    return selectedComponent
                      ? `${selectedComponent.component} - ${selectedComponent.assembly_number}`
                      : "Vehicle Component";
                  }}
                >
                  {vehicle_component_details.map((component) => (
                    <MenuItem
                      sx={{
                        fontSize: "15px",
                        display: "block",
                        padding: "10px 15px",
                      }}
                      key={`vehicle-${component.id}`}
                      value={component.id}
                    >
                      <Box sx={{ marginBottom: "4px" }}>
                        <strong>Component:</strong> {component.component}
                      </Box>
                      <Box sx={{ marginBottom: "4px" }}>
                        <strong>Assembly No:</strong>{" "}
                        {component.assembly_number}
                      </Box>
                      <Box>
                        <strong>Summary:</strong> {component.summary}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <Box sx={{ color: "gray" }}>No Data Available</Box>
            )}
          </Box>
        );
      },
    },
    // {
    //   field: "legal_details",
    //   headerName: "Legal Details",
    //   width: 270,
    //   renderCell: (params) => (
    //     <Box>
    //       <Box>
    //         <Typography variant="subtitle2">
    //           <span style={styles.txtBold}>Standard: </span>
    //           {params?.row?.legal_details?.standard || "-"}
    //         </Typography>
    //         <Typography variant="subtitle2">
    //           <span style={styles.txtBold}>Chapter: </span>
    //           {params?.row?.legal_details?.chapter || "-"}
    //         </Typography>
    //         <Typography variant="subtitle2">
    //           <span style={styles.txtBold}>Revision: </span>
    //           {params?.row?.legal_details?.revision || "-"}
    //         </Typography>
    //         <Typography variant="subtitle2">
    //           <span style={styles.txtBold}>Req. Number: </span>
    //           {params?.row?.legal_details?.req_number || "-"}
    //         </Typography>
    //       </Box>
    //       <Box sx={{ my: 1, ml: 2.5 }}>
    //         <CustomButton
    //           size="small"
    //           variant="contained"
    //           btnName="More"
    //           handleOnClick={() => {}}
    //           // disabled={}
    //         />
    //       </Box>
    //     </Box>
    //   ),
    // },
    {
      field: "ls_lr_details",
      headerName: "LEGAL DETAILS",
      headerAlign: "left",
      width: 250,

      renderCell: (params) => {
        const { lr_details, ls_details } = params?.row;
        return (
          <Box sx={{ textAlign: "left", fontSize: "14px" }}>
            <Box>
              <strong>Chapter:</strong> {lr_details?.chapter || "-"}
            </Box>
            <Box>
              <strong>Requirement Number:</strong>{" "}
              {lr_details?.req_number || "-"}
            </Box>
            <Box>
              <strong>Standard Number:</strong>{" "}
              {ls_details?.standard_number || "-"}
            </Box>
            <Box>
              <strong>Revision:</strong> {ls_details?.revision || "-"}
            </Box>
            {/* <Box>
               <strong>Standard Name:</strong> {ls_details?.name || "-"}
             </Box> */}
            {/* <Box>
              <strong>Description:</strong>
              <ExpandableDescription
                description={lr_details?.description || "-"}
              />
            </Box> */}
            <Box sx={{ textAlign: "left", marginTop: 2 }}>
              <Button
                variant="contained"
                onClick={() => handleLEGALViewButtonClick(params?.row?.allData)}
                sx={{ ...appTheme.commonBtnStyle, fontSize: "12px" }}
                size="small"
              >
                View more{" >>"}
              </Button>
            </Box>
          </Box>
        );
      },
    },
    // {
    //   field: "vehicle_functions",
    //   headerName: "Vehicle Functions",
    //   width: 220,
    //   renderCell: (params) => (
    //     <Box>
    //       <SelectTextFieldNC
    //         size={"small"}
    //         label={"Vehicle Components"}
    //         variant={"standard"}
    //         optionsList={[
    //           { value: 1, label: "AEB - Automatic emergency breaking" },
    //           { value: 2, label: "CSF - Corrective Steering Function" },
    //           ,
    //           { value: 3, label: "ELKS - Emergency Lane-keeping System" },
    //         ]}
    //         selectedValue={params?.row?.vehicle_functions?.id}
    //         handleOnChange={(e) => {}}
    //         sltFldStyle={styles.selectTxtStyle}
    //       />
    //     </Box>
    //   ),
    // },
    {
      field: "attachments",
      headerName: "ATTACHMENTS",
      headerAlign: "center",
      width: 240,
      renderCell: (params) => (
        <Box sx={{ mt: 1.4, ml: 2, textAlign: "center" }}>
          <CustomButton
            size="small"
            variant="contained"
            btnName="View"
            // startIcon={<VisibilityIcon />}
            handleOnClick={() => {
              getTicketsTemplates({ intrId: params?.row?.intr_id });
              setIsTemplateDialogOpen(true);
            }}
            // disabled={}
          />
        </Box>
      ),
    },
  ];

  // Open sort menu
  const handleSortClick = (event) => {
    setAnchorElSort(event.currentTarget);
    setOpenSort(true);
  };

  // Handle sort close
  const handleSortClose = () => {
    setAnchorElSort(null);
    setOpenSort(false);
  };

  // Handle sort change
  const handleSortChange = (column, order) => {
    setSelectedSortColumn(column);
    setSelectedSortOrder(order);

    setPage(1); // Reset to the first page when page size changes

    handleSortClose();
  };

  // Reset the sort values
  const handleSortResetAll = () => {
    handleSortChange("created_at", "ASC");
  };

  // Apply sorting
  const handleSortApplyNow = (selectedSortColumn, selectedSortOrder) => {
    handleSortChange(selectedSortColumn, selectedSortOrder);
  };

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);

    setPage(1); // Reset to the first page when page size changes

    handleFilterClose();
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("role_name", "");
  };

  // Apply filtering
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  // Handles the click event for table rows for editing
  const handleTableRowClick = ({ params }) => {};

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when page size changes
  };

  //Function to handle ticket status
  const getTicketStatusName = (statusCode) => {
    return ticketStatus[statusCode] || "-";
  };

  //Function to handle EE Toggle drawer open/closed
  const toggleEEDialog = (newOpen) => () => {
    setIsEEDialogOpen(newOpen);
  };

  //Function to handle Toggle drawer open/closed
  const toggleDialog = (newOpen) => () => {
    setIsDialogOpen(newOpen);
  };

  //Function to handle Toggle Legal Dialog open/closed
  const toggleLegalDialog = (newOpen) => () => {
    setIsLegalDialogOpen(newOpen);
  };

  // Handle button click to open drawer with ticket details
  const handleSWMViewButtonClick = (item) => {
    const modules = item?.dependencies?.software_modules || [];
    const vehicleFunctions = item?.swm_details?.vehicle_functions;

    // Map the modules to include both module details and their corresponding ee_details
    const items2 = modules.map((module) => ({
      swm_id: module?.swm_id,
      name: module?.name,
      version: module?.version,
      description: module?.description,
      ee_details: module?.ee_details || [], // Include ee_details in each module
    }));

    const eeDetails = modules.flatMap((module) => module?.ee_details) || [];

    setDialogData({
      heading1: `Vehicle Functions`,
      items1:
        vehicleFunctions?.map((vehicleFunction) => ({
          name: vehicleFunction?.name,
          abbreviation: vehicleFunction?.abbreviation,
        })) || [],
      heading2: `Dependent Software Modules`,
      items2: items2, // Use the combined items
      heading3: `EE Details`,
      items3: eeDetails.map((ee) => ({
        ee_id: ee?.ee_id,
        assembly_number: ee?.assembly_number,
        description: ee?.description,
        supplier_name: ee?.supplier_name,
        version: ee?.version,
      })),
    });

    toggleDialog(true)();
  };

  // Handle button click to open drawer with ticket details
  const handleEEViewButtonClick = (item) => {
    const modules = item?.dependencies?.ee_components || [];
    const vehicleSystems = item?.ee_details?.vehicle_systems;

    setEEDialogData({
      heading1: `Vehicle Systems`,
      items1:
        vehicleSystems?.map((vs) => ({
          name: vs?.name,
          abbreviation: vs?.abbreviation,
        })) || [],
      heading2: `Dependent EE Components`,
      items2: modules.map((ee) => ({
        ee_id: ee?.ee_id,
        assembly_number: ee?.assembly_number,
        description: ee?.description,
        supplier_name: ee?.supplier_name,
        version: ee?.version,
      })),
    });

    toggleEEDialog(true)();
  };

  const handleLEGALViewButtonClick = (item) => {
    const lr_details = item?.lr_details || {};
    const ls_details = item?.ls_details || {};

    console.log("lr_details", lr_details);
    console.log("ls_details", ls_details);

    // Directly map lr_details to items2
    const items2 = [
      {
        lr_id: lr_details.lr_id,
        chapter: lr_details.chapter,
        description: lr_details.description,
        req_number: lr_details.req_number,
      },
    ];

    // Create items3 from ls_details
    const items3 = [
      {
        ls_id: ls_details.ls_id,
        standard_number: ls_details.standard_number,
        name: ls_details.name,
        revision: ls_details.revision,
        description: ls_details.description,
        chapters: ls_details.chapters,
      },
    ];

    setLegalDialogData({
      heading2: `Legal Requirement`,
      items2: items2,
      items3: items3, // Optionally include ls_details if needed
    });

    toggleLegalDialog(true)();
  };
  // const Transition = React.forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  //Function for Checkbox
  const handleCheckboxChange = (e, key, title) => {
    const verifyChecked = e.target.checked;
    const updateState = (setterFunction, key) => {
      setterFunction((prev) => {
        console.log("Updating state:", key, verifyChecked, prev, title);
        return verifyChecked
          ? [...prev, key]
          : prev.filter((item) => item !== key);
      });
    };

    if (title === "Ticket Status") {
      updateState(setSelectTicketStatus, key);
    } else if (title === "Zones") {
      updateState(setSelectedZones, key);
    } else if (title === "EE Components") {
      updateState(setSelectedEE, key);
    } else if (title === "Vehicle Components") {
      updateState(setSelectedVC, key);
    } else if (title === "Standards") {
      updateState(setSelectedStandard, key);
    } else if (title === "Chapters") {
      updateState(setSelectedChapter, key);
    } else if (title === "E/E Components ") {
      if (selectSWMEE === key) {
        setSelectSWMEE("");
      } else {
        setSelectSWMEE(key);
      }
    } else if (title === "SWM Components") {
      updateState(setSelectedSWM, key);
    } else if (title === "Legal Requirements") {
      updateState(setSelectedLR, key);
    } else if (title === "OEM Service Users") {
      updateState(setSelectOEM, key);
    } else if (title === "Technical Service Users") {
      updateState(setSelectTECH, key);
    }
  };

  const handleClickOpen = (ticket_id) => {
    setCurrentTicketId(ticket_id);
    setOpen(true);
    setTimer(5);
  };

  const handleClose = () => {
    setOpen(false);
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  const handleRedirect = () => {
    setOpen(false);
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
    if (currentTicketId) {
      // console.log("Redirecting to the URL"); // Debugging line
      window.open(
        `https://ven07074.service-now.com/incident.do?sysparm_query=number=${currentTicketId}`,
        "_blank"
      );
    }
  };
  return (
    <Box sx={styles.mainContainer}>
      <SortByOptionsTickets
        selectColumn={selectColumn}
        selectOrder={selectOrder}
        setSelectColumn={setSelectColumn}
        setSelectOrder={setSelectOrder}
      />
      {/* Simulation files table */}
      <Grid container spacing={2}>
        <Grid item md={3}>
          <Box
            sx={{
              minHeight: "500px",
              height: "500px",
              overflowY: "auto",
              padding: "5px",

              scrollbarWidth: "thin",
              scrollbarColor: "#888 #f1f1f1",
            }}
          >
            <FilterByOptionsTickets
              setSearchTicketId={setSearchTicketId}
              setSearchIntrId={setSearchIntrId}
              searchTicketId={searchTicketId}
              searchIntrId={searchIntrId}
              selectOEM={selectOEM}
              selectTECH={selectTECH}
              UserDetails={UserDetails}
              ticketsType={ticketsType}
              appMetaDetails={ticketStatusMetaApp}
              selectedVariantDetails={selectedVariantDetails}
              EEDetails={EEDetails}
              LSDetails={LSDetails}
              selectedTicketsType={selectedTicketsType}
              setSelectedTicketsType={setSelectedTicketsType}
              selectTicketStatus={selectTicketStatus}
              handleCheckboxChange={handleCheckboxChange}
              selectedZones={selectedZones}
              selectedEE={selectedEE}
              selectedVC={selectedVC}
              selectedStandard={selectedStandard}
              selectedChapter={selectedChapter}
              selectedLR={selectedLR}
              selectSWMEE={selectSWMEE}
              selectedSWM={selectedSWM}
              handleClearAllFilter={handleClearAllFilter}
              fetchTickets={fetchTickets}
              reqObj={reqObj}
            />
          </Box>
        </Grid>
        <Grid item md={9}>
          <Box>
            <Paper elevation={6} sx={styles.paper}>
              <CustomDataGridTableB
                tableRowsData={tableRowsData}
                columns={COLUMNS}
                rowCount={tableRowsData.length}
                isMultiLine={true}
                handleTableRowClick={() => null}
              />
            </Paper>
            <Paper elevation={4} sx={styles.paginationPaper}>
              {/* Pagination with select page size */}
              <CustomPagination
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                hasMore={hasMore}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>

      {/* Dialog for ee dependencies */}
      <Dialog
        open={isEEDialogOpen}
        //TransitionComponent={Transition}
        keepMounted
        onClose={toggleEEDialog(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "350px",
            boxSizing: "border-box",
            padding: 0,
          },
        }}
      >
        <Box sx={styles.drawerContainer}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            EE Components
          </Typography>
          <Divider sx={styles.divider} />

          {/* Close Button */}
          <IconButton onClick={toggleEEDialog(false)} sx={styles.closeButton}>
            <CloseIcon />
          </IconButton>

          {/* System Functions */}
          <Box sx={styles.drawerWrap}>
            <Paper sx={styles.paperDrawer}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                System Functions
              </Typography>
              <Divider sx={styles.divider} />

              {eeDialogData?.items1?.map((vehicleSystem, index) => (
                <Paper key={index} sx={styles.cardDrawer}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      display: "flex",
                    }}
                  >
                    <strong>{vehicleSystem?.abbreviation}</strong>:{" "}
                    {vehicleSystem?.name}
                  </Typography>
                </Paper>
              ))}

              {eeDialogData?.items1?.length <= 0 && (
                <Typography sx={{ textAlign: "center" }}>
                  No data found.
                </Typography>
              )}
            </Paper>
          </Box>

          {/* Software Modules */}
          <Box sx={styles.drawerWrap}>
            {eeDialogData?.heading2 && (
              <Paper sx={styles.paperDrawer}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Dependent EE Components
                </Typography>
                <Divider sx={styles.divider} />
                <Box>
                  {eeDialogData?.items2?.map((module, index) => (
                    <Paper key={index} sx={styles.cardDrawer}>
                      <Box
                        sx={{
                          border: "1px solid black",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {/* EE Component */}
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "15px",
                              textDecoration: "underline",
                            }}
                          >
                            EE Component
                          </Typography>
                          <Box sx={{ gap: "10px" }}>
                            {[
                              { header: "Name", value: module?.description },
                              { header: "Version", value: module?.version },
                              {
                                header: "Assembly Number",
                                value: module?.assembly_number,
                              },
                              {
                                header: "Supplier Name",
                                value: module?.supplier_name,
                              },
                            ].map((det, key) => (
                              <Typography key={key} sx={{ fontSize: "13px" }}>
                                <strong>{det?.header}:</strong> {det?.value}
                              </Typography>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Paper>
                  ))}

                  {eeDialogData?.items2?.length <= 0 && (
                    <Typography sx={{ textAlign: "center" }}>
                      No data found.
                    </Typography>
                  )}
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      </Dialog>
      {/* Container for Dialog to view more about ticket */}
      <Dialog
        open={isDialogOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={toggleDialog(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            maxWidth: "350px",
            boxSizing: "border-box",
            padding: 0,
          },
        }}
      >
        <Box sx={styles.drawerContainer}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Software Module
          </Typography>
          <Divider sx={styles.divider} />
          {/* Close Button */}
          <IconButton onClick={toggleDialog(false)} sx={styles.closeButton}>
            <CloseIcon />
          </IconButton>
          {/* Vehicle Functions */}
          <Box sx={styles.drawerWrap}>
            <Paper sx={styles.paperDrawer}>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: 1,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Vehicle Function
              </Typography>
              <Divider sx={styles.divider} />

              {dialogData?.items1?.map((vehicleFunction, index) => (
                <Paper key={index} sx={styles.cardDrawer}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      display: "flex",
                    }}
                  >
                    <strong>{vehicleFunction.abbreviation}</strong>:{" "}
                    {vehicleFunction.name}
                  </Typography>
                </Paper>
              ))}

              {dialogData?.items1?.length <= 0 && (
                <Typography sx={{ textAlign: "center" }}>
                  No data found.
                </Typography>
              )}
            </Paper>
          </Box>
          {/* Software Modules */}
          <Box sx={styles.drawerWrap}>
            {dialogData?.heading2 && (
              <Paper sx={styles.paperDrawer}>
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: 1,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Dependent Software Modules
                </Typography>
                <Divider sx={styles.divider} />
                <Box>
                  {dialogData?.items2?.map((module, index) => (
                    <Paper key={index} sx={styles.cardDrawer}>
                      <Box
                        sx={{
                          border: "1px solid black",
                          padding: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {/* Software Modules */}
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              color: "black",
                              fontSize: "15px",
                              textDecoration: "underline",
                            }}
                          >
                            Software Module
                          </Typography>
                          <Box sx={{ gap: "10px" }}>
                            {[
                              { header: "ID", value: module?.swm_id },
                              { header: "Name", value: module?.name },
                              { header: "Version", value: module?.version },
                              {
                                header: "Description",
                                value: module?.description,
                              },
                            ].map((det, key) => (
                              <Typography key={key} sx={{ fontSize: "13px" }}>
                                <strong>{det?.header}:</strong> {det?.value}
                              </Typography>
                            ))}
                          </Box>
                        </Box>

                        {/* EE Component */}
                        {module?.ee_details && module.ee_details.length > 0 && (
                          <Box>
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "black",
                                fontSize: "15px",
                                paddingTop: "10px",
                                textDecoration: "underline",
                              }}
                            >
                              EE Component
                            </Typography>
                            {module.ee_details.map((ee, eeIndex) => (
                              <Box key={eeIndex} sx={{ gap: "10px" }}>
                                {[
                                  { header: "Name", value: ee?.description },
                                  { header: "Version", value: ee?.version },
                                  {
                                    header: "Assembly Number",
                                    value: ee?.assembly_number,
                                  },
                                  {
                                    header: "Supplier Name",
                                    value: ee?.supplier_name,
                                  },
                                ].map((det, key) => (
                                  <Typography
                                    key={key}
                                    sx={{ fontSize: "13px" }}
                                  >
                                    <strong>{det?.header}:</strong> {det?.value}
                                  </Typography>
                                ))}
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    </Paper>
                  ))}

                  {dialogData?.items2?.length <= 0 && (
                    <Typography sx={{ textAlign: "center" }}>
                      No data found.
                    </Typography>
                  )}
                </Box>
              </Paper>
            )}
          </Box>
          {/* Legal Req */}
          <Dialog
            open={isLegalDialogOpen}
            //TransitionComponent={Transition}
            keepMounted
            onClose={toggleLegalDialog(false)}
            sx={{
              "& .MuiDrawer-paper": {
                width: "100%",
                maxWidth: "350px",
                boxSizing: "border-box",
                padding: 0,
              },
            }}
          >
            <Box sx={styles.drawerContainer}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                LEGAL DETAILS
              </Typography>
              <Divider sx={styles.divider} />

              {/* Close Button */}
              <IconButton
                onClick={toggleLegalDialog(false)}
                sx={styles.closeButton}
              >
                <CloseIcon />
              </IconButton>

              {/* Legal Requirements */}
              <Box sx={styles.drawerWrap}>
                {legalDialogData?.heading2 && (
                  <Paper sx={styles.paperDrawer}>
                    <Box>
                      {legalDialogData?.items2.map((lr, index) => (
                        <Paper key={index} sx={styles.cardDrawer}>
                          <Box
                            sx={{
                              border: "1px solid black",
                              padding: "10px",
                              borderRadius: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                                color: "black",
                                fontSize: "15px",
                                textDecoration: "underline",
                              }}
                            >
                              Legal Requirements
                            </Typography>
                            <Box sx={{ gap: "10px" }}>
                              {[
                                { header: "ID", value: lr?.lr_id },
                                { header: "Chapter", value: lr?.chapter },
                                {
                                  header: "Requirement Number",
                                  value: lr?.req_number,
                                },
                                {
                                  header: "Description",
                                  value: lr?.description,
                                },
                              ].map((det, key) => (
                                <Typography key={key} sx={{ fontSize: "13px" }}>
                                  <strong>{det?.header}:</strong> {det?.value}
                                </Typography>
                              ))}
                            </Box>
                          </Box>
                        </Paper>
                      ))}
                    </Box>
                  </Paper>
                )}
              </Box>

              {/* Legal Standards */}
              <Dialog
                open={isLegalDialogOpen}
                //TransitionComponent={Transition}
                keepMounted
                onClose={toggleLegalDialog(false)}
                sx={{
                  "& .MuiDrawer-paper": {
                    width: "100%",
                    maxWidth: "350px",
                    boxSizing: "border-box",
                    padding: 0,
                  },
                }}
              >
                <Box sx={styles.drawerContainer}>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    LEGAL DETAILS
                  </Typography>
                  <Divider sx={styles.divider} />

                  {/* Close Button */}
                  <IconButton
                    onClick={toggleLegalDialog(false)}
                    sx={styles.closeButton}
                  >
                    <CloseIcon />
                  </IconButton>

                  {/* Legal Requirements */}
                  <Box sx={styles.drawerWrap}>
                    {legalDialogData?.heading2 && (
                      <Paper sx={styles.paperDrawer}>
                        <Box>
                          {legalDialogData?.items2.map((lr, index) => (
                            <Paper key={index} sx={styles.cardDrawer}>
                              <Box
                                sx={{
                                  border: "1px solid black",
                                  padding: "10px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    color: "black",
                                    fontSize: "15px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Legal Requirements
                                </Typography>
                                <Box sx={{ gap: "10px" }}>
                                  {[
                                    { header: "ID", value: lr?.lr_id },
                                    { header: "Chapter", value: lr?.chapter },
                                    {
                                      header: "Requirement Number",
                                      value: lr?.req_number,
                                    },
                                    {
                                      header: "Description",
                                      value: lr?.description,
                                    },
                                  ].map((det, key) => (
                                    <Typography
                                      key={key}
                                      sx={{ fontSize: "13px" }}
                                    >
                                      <strong>{det?.header}:</strong>{" "}
                                      {det?.value}
                                    </Typography>
                                  ))}
                                </Box>
                              </Box>
                            </Paper>
                          ))}
                        </Box>
                      </Paper>
                    )}
                  </Box>

                  {/* Legal Standards */}
                  <Box sx={styles.drawerWrap}>
                    {legalDialogData?.items3 && (
                      <Paper sx={styles.paperDrawer}>
                        <Box>
                          {legalDialogData?.items3.map((ls, index) => (
                            <Paper key={index} sx={styles.cardDrawer}>
                              <Box
                                sx={{
                                  border: "1px solid black",
                                  padding: "10px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    color: "black",
                                    fontSize: "15px",
                                    textDecoration: "underline",
                                  }}
                                >
                                  Legal Standard
                                </Typography>
                                <Box sx={{ gap: "10px" }}>
                                  {[
                                    { header: "ID", value: ls?.ls_id },
                                    {
                                      header: "Standard Number",
                                      value: ls?.standard_number,
                                    },
                                    { header: "Name", value: ls?.name },
                                    { header: "Revision", value: ls?.revision },
                                    {
                                      header: "Description",
                                      value: ls?.description,
                                    },
                                    {
                                      header: "Chapters",
                                      value: ls?.chapters.join(", "),
                                    }, // Display chapters
                                  ].map((det, key) => (
                                    <Typography
                                      key={key}
                                      sx={{ fontSize: "13px" }}
                                    >
                                      <strong>{det?.header}:</strong>{" "}
                                      {det?.value}
                                    </Typography>
                                  ))}
                                </Box>
                              </Box>
                            </Paper>
                          ))}
                        </Box>
                      </Paper>
                    )}
                  </Box>
                </Box>
              </Dialog>
            </Box>
          </Dialog>
        </Box>

        {/* <DialogActions>
    <Button onClick={toggleDialog(false)} sx={{ ...appTheme.commonBtnStyle }}>
      Close
    </Button>
  </DialogActions> */}
      </Dialog>
      {openModal && (
        <Dialog open={openModal} onClose={() => setOpenModal(false)}>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h6">View Image</Typography>
              <IconButton
                onClick={() => {
                  setOpenModal(false);
                  setImagePath(null);
                }}
                color="inherit"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <img
              alt="No Image Found"
              src={imagePath}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain", // Adjust object-fit as needed
              }}
            />
          </DialogContent>
        </Dialog>
      )}
      {/* Template dialog */}
      {/* <TemplateDialog
        open={isTemplateDialogOpen}
        onClose={() => handleTemplateDialog(false)}
        projectId={selectedProjectDetails?.project_id}
        variantId={selectedVariantDetails?.variant_id}
        formType={TICKETS_TYPES?.SWM}
        dynamicFormData={dynamicFormData}
        setDynamicFormData={setDynamicFormData}
        dialogTitle={"Template"}
        dialogContent={null}
        onConfirm={() => {}}
        onDisabled={() => {}}
      /> */}
      {dynamicFormData?.form_id ? (
        <ViewDynammicFormDetails
          data={dynamicFormData}
          open={isTemplateDialogOpen}
          handleClose={() => handleTemplateDialog(false)}
        />
      ) : (
        <Dialog
          fullWidth
          maxWidth={"sm"}
          open={isTemplateDialogOpen}
          onClose={() => handleTemplateDialog(false)}
        >
          <DialogTitle sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => handleTemplateDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              style={{
                height: "370px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6">No attachments found</Typography>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}

export default SimulationFiles;

// Styles for the page
const styles = {
  filter: {
    position: "relative",
    top: "4px",
    right: "8px",
  },
  mainBox: {
    pt: 0,
    boxShadow: 3,
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    height: "100vh",
    pr: 2,
    pl: 2,
  },
  box: {
    height: "100vh",
    width: "100%",
  },
  paper: {
    mt: "10px",
    boxShadow: 3,
    height: "500px",
    minHeight: "500px",
    width: "auto",
    scrollbarColor: "#888 #f1f1f1",
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "400px",
    padding: 2,
    position: "relative",
  },
  closeButton: {
    color: "red",
    position: "absolute",
    top: "4px",
    right: "8px",
  },
  divider: {
    border: "1px solid lightgrey",
    margin: "8px 0",
    marginY: 1,
  },
  paperDrawer: {
    marginBottom: 2,
    padding: 2,
    boxShadow: 10,
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
  },
  drawerWrap: {
    marginTop: 2,
  },

  dataTypographyKey: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "110px",
  },
  dataTypographyValue: {
    marginLeft: 1,
    fontSize: "14px",
    textAlign: "left",
  },
  dataBox: {
    display: "flex",
    flexDirection: "row",

    alignItems: "center",
    flexWrap: "wrap",
    gap: 1,
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "100%",
  },
  card: {
    boxShadow: 3,
    width: "100%",
    height: "auto",
    marginBottom: 1,
    padding: 2,
  },
  cardContent: {
    display: "flex",
    height: "40px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dataTypography: {
    fontWeight: "bold",
    fontSize: "13px",
    textAlign: "left",
    minWidth: "120px",
  },
  dataTypographyCardKey: {
    fontWeight: "bold",
    fontSize: "15px",
    textAlign: "left",
    minWidth: "50px",
  },
  dataTypographyCardValue: {
    fontSize: "15px",
    textAlign: "left",
    minWidth: "50px",
  },
  btnsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mb: 1,
    gap: 1,
  },
  cardDrawer: { margin: 1, padding: 2, boxShadow: 3 },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px",
  },
};

const ticketStatus = {
  100: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#000000",
        background: "none",
        color: "#000000",
        fontSize: "12px",
      }}
      label={"NEW"}
      size="medium"
    />
  ),
  101: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"DRAFT"}
      size="medium"
    />
  ),
  150: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"CONFIRM LEGAL REQUIREMENTS"}
      size="medium"
    />
  ),
  200: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"ARTIFACT CONFIRMATION"}
      size="medium"
    />
  ),
  300: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"EVIDENCE SUBMISSION"}
      size="medium"
    />
  ),
  400: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#0076a8",
        background: "none",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"EVIDENCE REVIEW"}
      size="medium"
    />
  ),
  500: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        background: "none",
        // backgroundColor: "#0076a8",
        color: "#0076a8",
        fontSize: "12px",
      }}
      label={"TESTING AT TECHNICAL SERVICE"}
      size="medium"
    />
  ),
  600: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#26890D",
        background: "none",
        color: "#26890D",
        fontSize: "12px",
      }}
      label={"RESOLVED"}
      size="medium"
    />
  ),
  999: (
    <Chip
      sx={{
        fontWeight: "bold",
        // boxShadow: "3",
        // borderRadius: "5px",
        // backgroundColor: "#26890D",
        background: "none",
        color: "#26890D",
        fontSize: "12px",
      }}
      label={"CANCELLED"}
      size="medium"
    />
  ),
};

const LinkButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  color: theme.palette.primary.main,
  textDecoration: "underline",
  padding: 0,
  minWidth: "auto",
  "&:hover": {
    textDecoration: "underline",
    backgroundColor: "transparent",
  },
}));

const CountdownTimer = styled(Typography)(({ theme }) => ({
  backgroundColor: "green",
  color: "white",
  borderRadius: "30px",
  textAlign: "center",
  width: "100px",
  cursor: "pointer",
}));
