/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 14-10-2024
 * @description : Link EE Component to Legal Requirement.
 */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LabCar from "../../labcar/LabCar";
import customConsole from "../../../config/customConsole";
import {
  HandleApiActions,
  labCar,
  legalRequirements,
} from "../../../redux/actions";
import {
  CustomAddDialog,
  CustomButton,
  CustomTextFieldN,
  DynamicFormDialog,
  SelectDynamicForm,
  SelectTextFieldN,
} from "../../../components";
import softwareIntersectionAPI from "../../../redux/actions/softwareIntersectionActions";
import typeApprovalAPI from "../../../redux/actions/typeApprovalAPI";
import LinkedEEToLegalTable from "./LinkedEEToLegalTable";
import appTheme from "../../../assets/AppTheme/appTheme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link, useLocation } from "react-router-dom";
import CustomFiltersN from "../../../components/tables/CustomFiltersN";
import { FORM_TYPES } from "../../../config/appConstants";

function LinkEEToLegal({
  setIntersectionDetailsNew,
  setIsSWMToLegalIntersection,
  selectedProjectId,
  selectedProjectIsZonal,
  selectedProjectZonesList,
  setTabValue,
  setShowIntersDelTicket,
  selectedSingleVariantDetails,
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const zoneFromurl = queryParams.get("selectedZone");
  const eeFromurl = queryParams.get("ee_id");

  /* Dispatch */
  const dispatch = useDispatch();

  /* Get data from the redux store  */

  const { selectedVariant } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* Zone Main */
  const [zoneMainOptionsList, setZoneMainOptionsList] = useState([]);
  const [selectedZoneMain, setSelectedZoneMain] = useState(null);

  /* Legal Standards */
  const [legalStandardsList, setLegalStandardsList] = useState([]);
  const [legalStandardsOptionsList, setLegalStandardsOptionsList] = useState(
    []
  );
  const [selectedLegalStandardId, setSelectedLegalStandardId] = useState(null);

  /* EE Components Main List */
  const [eeComponentsMainList, setEEComponentsMainList] = useState([]);

  /* Legal Standards Chapters */
  const [lsChaptersOptionsList, setLSChaptersOptionsList] = useState([]);
  const [selectedLSChapter, setLSelectedLSChapter] = useState(null);

  /* Legal Requirements */
  const [legalRequirementsList, setLegalRequirementsList] = useState([]);

  /* Selected EE Component Main */
  const [selectedMainEEDetails, setSelectedMainEEDetails] = useState(null);

  /* EE to legal Intersections */
  const [selectedEELinkedLRList, setSelectedEELinkedLRList] = useState([]);

  /* Selected Legal Requirements States */
  const [selectedLegalRequirementsIds, setSelectedLegalRequirementsIds] =
    useState([]);
  const [eeLRIntersectionsDetails, setEELRIntersectionsDetails] = useState([]);
  const [isAddDescDialogOpen, setIsAddDescDialogOpen] = useState(false);
  const [currentLegalRequirement, setCurrentLegalRequirement] = useState(null);
  const [currentDescription, setCurrentDescription] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedECU, setSelectedECU] = useState(null);
  const [actionType1DialogOpen, setActionType1DialogOpen] = useState(false);

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState("module_type");
  const [searchFieldValue, setSearchFieldValue] = useState(2);

  // DynamicForm
  const [isDynamicFormDialogOpen, setIsDynamicFormDialogOpen] = useState(false);
  const [isFormRequired, setIsFormRequired] = useState(false);
  const [dynamicFormFilteredData, setDynamicFormFilteredData] = useState(null);

  /* Dispatch Functions */
  /* Get EE Main Components List Function */
  const getEEMainComponentsList = async ({
    projectId,
    zoneSequence,
    zonePosition,
  }) => {
    let apiDetails = labCar.get_ee_components_new;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        variant_id: Number(selectedVariant?.value),
        // zone_sequence: zoneSequence,
        zone_position: zonePosition,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          variant_id: Number(selectedVariant?.value),
          // zone_sequence: zoneSequence,
          zone_position: zonePosition,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setEEComponentsMainList([]);
    } else {
      setEEComponentsMainList(resp.data);
    }
  };

  /* Get EE Main Linked Components List Function */
  const getEEMainLinkedComponentsList = async ({ projectId }) => {
    let apiDetails = labCar.get_ee_components_new;

    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
        variant_id: Number(selectedVariant?.value),
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
          variant_id: Number(selectedVariant?.value),
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setEEComponentsMainList([]);
    } else {
      setEEComponentsMainList(resp.data);
    }
  };

  /* Get Legal Standards List Function */
  const getLegalStandardsList = async () => {
    let apiDetails = legalRequirements.get_legal_standard_details_new;

    customConsole({
      ...apiDetails,
      params: {
        page_number: 1,
        page_size: 250,
        ...(Number(searchFieldValue) && {
          [selectedSearchField]: searchFieldValue,
        }),
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          page_number: 1,
          page_size: 250,
          ...(Number(searchFieldValue) && {
            [selectedSearchField]: searchFieldValue,
          }),
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setLegalStandardsList([]);
    } else {
      setLegalStandardsList(resp.data);
    }
  };

  /* Get Legal Requirements List Function */
  const getLegalRequirementsList = async ({
    legalStandardId,
    legalStandardChapter,
  }) => {
    let apiDetails = legalRequirements.get_legal_requirements_details_new;

    customConsole({
      ...apiDetails,
      params: {
        ls_id: legalStandardId,
        chapter: legalStandardChapter,
        page_number: 1,
        page_size: 250,
      },
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          ls_id: legalStandardId,
          chapter: legalStandardChapter,
          page_number: 1,
          page_size: 250,
        },
        show_toast: false,
      })
    );

    if (resp.error) {
      setLegalRequirementsList([]);
    } else {
      setLegalRequirementsList(resp.data);
    }
  };

  /* Get EE Linked LR List Function */
  const getEELinkedLRList = async ({ projectId, eeId }) => {
    if (projectId && eeId) {
      let apiDetails = softwareIntersectionAPI.get_ee_legal_requirements;

      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(projectId),
          variant_id: Number(selectedVariant?.value),
          ee_id: Number(eeId),
        },
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(projectId),
            variant_id: Number(selectedVariant?.value),
            ee_id: Number(eeId),
          },
          show_toast: false,
        })
      );

      if (resp.error) {
        setSelectedEELinkedLRList([]);
      } else {
        setSelectedEELinkedLRList(resp.data);
      }
    }
  };

  /* Handle Add Description Dialog Close Function */
  const handleAddDescDialogClose = (confirm) => {
    if (confirm && currentDescription) {
      // Proceed with adding legal requirement
      handleAddLegalRequirement(
        currentLegalRequirement,
        currentDescription,
        dynamicFormFilteredData
      );
    } else {
      // Uncheck the checkbox if no description is provided
      setSelectedLegalRequirementsIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => id !== currentLegalRequirement.lr_id)
      );
    }
    setCurrentDescription("");
    setCurrentLegalRequirement(null);
    setIsAddDescDialogOpen(false);

    // Dynamic form
    setIsFormRequired(false);
  };

  /* Handle Add Legal Requirement Function */
  const handleAddLegalRequirement = (
    legalRequirement,
    description,
    dynamicFormFilteredData
  ) => {
    const { eeId, lr_id } = legalRequirement;
    const newLegalRequirement = {
      lr_id: Number(lr_id),
      description: description,
      ...(isFormRequired ? { dynamic_form: dynamicFormFilteredData } : {}),
    };

    setEELRIntersectionsDetails((prevDetails) => {
      const existingEntry = prevDetails?.find((entry) => entry?.ee_id === eeId);

      if (existingEntry) {
        return prevDetails?.map((entry) =>
          entry?.ee_id === eeId
            ? {
                ...entry,
                legal: [...entry?.legal, newLegalRequirement],
              }
            : entry
        );
      } else {
        return [
          ...prevDetails,
          {
            ee_id: eeId,
            legal: [newLegalRequirement],
          },
        ];
      }
    });
  };

  /* Handle Select Legal Requirements Function */
  const handleSelectLegalRequirements = (event, ee_id, lr_id) => {
    // Ensure ee_id is treated as number
    const eeId = Number(ee_id);

    if (event.target.checked) {
      setCurrentLegalRequirement({ eeId, lr_id });
      setIsAddDescDialogOpen(true);

      // Temporarily Add lr_id to the lr ids list
      setSelectedLegalRequirementsIds((prevSelectedIds) => [
        ...prevSelectedIds,
        lr_id,
      ]);
    } else {
      // Remove lr_id from the lr ids list
      setSelectedLegalRequirementsIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => id !== lr_id)
      );

      // Update eeLRIntersectionsDetails for removing a legal requirement
      setEELRIntersectionsDetails((prevDetails) => {
        return prevDetails
          ?.map((entry) => {
            if (entry?.ee_id === eeId) {
              // Remove the legal requirement with the specified lr_id
              const updatedLegal = entry?.legal?.filter(
                (legal) => legal?.lr_id !== Number(lr_id)
              );

              return {
                ...entry,
                legal: updatedLegal,
              };
            }
            return entry;
          })
          ?.filter((entry) => entry?.legal?.length > 0); // Remove entry if legal array is empty
      });
    }
  };

  // Handle Link EE Component To Legal Requirements
  const handleLinkEEToLRs = async () => {
    if (selectedProjectId && eeLRIntersectionsDetails?.length) {
      let apiDetails = typeApprovalAPI.get_ee_add_intersection;

      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(selectedProjectId),
          variant_id: Number(selectedVariant?.value),
          intersections: [...eeLRIntersectionsDetails],
        },
        // show_toast: true,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(selectedProjectId),
            variant_id: Number(selectedVariant?.value),
            intersections: [...eeLRIntersectionsDetails],
          },
          show_toast: true,
        })
      );

      if (resp.error) {
        setIntersectionDetailsNew([]);
      } else {
        setIsSWMToLegalIntersection(false);

        setIntersectionDetailsNew(resp?.data?.intersections);

        setTabValue(1);
        setShowIntersDelTicket(false);
      }
    }
  };

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);

    handleFilterClose();
  };

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("module_type", 2);
  };

  // Apply filtering
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  /* useEffects */
  useEffect(() => {
    // Get the Legal Standards List
    getLegalStandardsList();
  }, [selectedSearchField, searchFieldValue]);

  useEffect(() => {
    // When the selected project is changed, make the states to default
    setSelectedEELinkedLRList([]);
    setSelectedLegalRequirementsIds([]);
    setEELRIntersectionsDetails([]);
    setCurrentLegalRequirement(null);
    setCurrentDescription("");
  }, [selectedProjectId, selectedSingleVariantDetails?.variant_id]);

  // useEffect(() => {
  //   // If zonal, set the zone main options list & zone lined options list
  //   if (selectedProjectIsZonal) {
  //     /* Zone Main */
  //     let filteredZoneMainOptionsList = selectedProjectZonesList
  //       ?.map((el) => {
  //         return {
  //           // value: el?.sequence,
  //           value: el?.position,
  //           label: el?.zone_name,
  //         };
  //       })
  //       ?.sort((a, b) => a.label.localeCompare(b.label));
  //     setZoneMainOptionsList(filteredZoneMainOptionsList);

  //     /* Select zone from URL if available */
  //     const zoneFromUrlValue = filteredZoneMainOptionsList?.find((zone) => {
  //       return zone?.value === Number(zoneFromurl);
  //     });

  //     if (zoneFromUrlValue) {
  //       setSelectedZoneMain(zoneFromUrlValue?.value);
  //     } /* Select first zone main by default */ else if (
  //       selectedProjectIsZonal &&
  //       filteredZoneMainOptionsList?.[0]?.value
  //     ) {
  //       setSelectedZoneMain(filteredZoneMainOptionsList?.[0]?.value);
  //     }
  //   }
  // }, [selectedProjectZonesList, zoneFromurl]);

  useEffect(() => {
    // If zonal, set the zone main options list & zone lined options list
    if (selectedProjectIsZonal) {
      /* Zone Main */
      let filteredZoneMainOptionsList = selectedProjectZonesList
        ?.map((el) => {
          return {
            value: el?.position,
            label: el?.zone_name,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setZoneMainOptionsList(filteredZoneMainOptionsList);

      /* Select zone from URL if available */
      const zoneFromUrlValue = filteredZoneMainOptionsList?.find(
        (zone) => zone.value === zoneFromurl
      );
      console.log("zoneFromUrlValue", zoneFromUrlValue);
      console.log("selectedZoneMain", selectedZoneMain);
      console.log("filteredZoneMainOptionsList", filteredZoneMainOptionsList);
      if (zoneFromUrlValue) {
        setSelectedZoneMain(zoneFromUrlValue?.value);
      } /* Select first zone main by default */ else if (
        selectedProjectIsZonal &&
        filteredZoneMainOptionsList?.[0]?.value
      ) {
        setSelectedZoneMain(filteredZoneMainOptionsList?.[0]?.value);
      }
    }
  }, [selectedProjectZonesList]);

  useEffect(() => {
    /* Get Legal Standards Options List */
    let filteredLegalStandardsOptionsList = legalStandardsList
      // ?.filter((ls) => ls?.module_type == 2)
      ?.map((el) => {
        return {
          value: el?.ls_id,
          label: el?.standard_number,
        };
      });
    //   ?.sort((a, b) => a.label?.localeCompare(b.label));
    setLegalStandardsOptionsList(filteredLegalStandardsOptionsList);

    /* Select Legal Standards by default */
    if (filteredLegalStandardsOptionsList?.[0]?.value) {
      setSelectedLegalStandardId(filteredLegalStandardsOptionsList?.[0]?.value);
    }
  }, [legalStandardsList]);

  useEffect(() => {
    // Get EE Components Main & Linked List, When the selected project is changed
    if (!selectedProjectIsZonal && selectedProjectId) {
      getEEMainLinkedComponentsList({
        projectId: selectedProjectId,
      });
    }
  }, [selectedProjectId, selectedSingleVariantDetails?.variant_id]);

  useEffect(() => {
    /* Set EE Main state to default, When the selected zone main is changed */
    setEEComponentsMainList([]);
    setSelectedMainEEDetails(null);

    // Get EE Components Main List
    if (selectedProjectIsZonal && selectedProjectId && selectedZoneMain) {
      getEEMainComponentsList({
        projectId: selectedProjectId,
        // zoneSequence: selectedZoneMain,
        zonePosition: selectedZoneMain,
      });
    }
  }, [selectedZoneMain, selectedSingleVariantDetails?.variant_id]);

  useEffect(() => {
    if (eeComponentsMainList?.length > 0) {
      // Check if eeFromurl exists and find the matching component
      const matchingComponent = eeFromurl
        ? eeComponentsMainList.find((item) => item?.ee_id === eeFromurl)
        : null;

      if (matchingComponent) {
        // Set the selected EE details to the matching component
        setSelectedMainEEDetails(matchingComponent);
      } else if (!selectedMainEEDetails?.ee_id) {
        // Select the first ee component main by default if no match is found
        setSelectedMainEEDetails(eeComponentsMainList[0]);
      }
    }
  }, [
    eeComponentsMainList,
    eeFromurl,
    selectedProjectId,
    selectedSingleVariantDetails?.variant_id,
  ]);

  useEffect(() => {
    if (legalStandardsList) {
      // Set the LS Chapters Options List
      let filteredLSChaptersOptionsList = legalStandardsList
        ?.find((ls) => ls?.ls_id == selectedLegalStandardId)
        ?.chapters?.map((el) => {
          return {
            value: el,
            label: el,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setLSChaptersOptionsList(filteredLSChaptersOptionsList);

      /* Select first LS Chapter by default */
      if (filteredLSChaptersOptionsList?.[0]?.value) {
        setLSelectedLSChapter(filteredLSChaptersOptionsList?.[0]?.value);
      }
    }
  }, [selectedLegalStandardId]);

  useEffect(() => {
    /* Get Legal Requirements List, when the selected project and LS Chapter is changed  */
    if (selectedProjectId && selectedLegalStandardId && selectedLSChapter) {
      getLegalRequirementsList({
        legalStandardId: selectedLegalStandardId,
        legalStandardChapter: selectedLSChapter,
      });
    }
  }, [selectedProjectId, selectedLSChapter]);

  useEffect(() => {
    /* Get EE Linked LR List  */
    getEELinkedLRList({
      projectId: Number(selectedProjectId),
      eeId: Number(selectedMainEEDetails?.ee_id),
    });
  }, [selectedProjectId, selectedMainEEDetails]);

  useEffect(() => {
    /* Set SelectedLegalRequirementsIds when the selectedMainEEDetails is changed  */
    let filteredSelectedLegalRequirementsIds =
      eeLRIntersectionsDetails
        ?.find((el) => el.ee_id === Number(selectedMainEEDetails?.ee_id))
        ?.legal.map((legal) => String(legal.lr_id)) || [];
    setSelectedLegalRequirementsIds(filteredSelectedLegalRequirementsIds);
  }, [
    selectedMainEEDetails,
    selectedProjectId,
    selectedSingleVariantDetails?.variant_id,
  ]);

  useEffect(() => {
    customConsole({ eeLRIntersectionsDetails });
  }, [eeLRIntersectionsDetails]);

  const confirmUpdate = async () => {
    // Create the request object
    const reqObj = {
      current_time: new Date().toISOString(),
      project_id: Number(selectedProjectId),
      variant_id: Number(selectedVariant?.value),
      ee_id: Number(selectedMainEEDetails?.ee_id),
      is_dependency_linking: false,
    };
    try {
      // Dispatch the update action type API
      const response = await dispatch(
        HandleApiActions({
          ...labCar.update_action_type,
          params: reqObj,
          show_toast: true,
        })
      );
      getEEMainComponentsList({
        projectId: selectedProjectId,
      });
      if (!response.error) {
        handleDialogClose();
      }
    } catch (error) {
      console.error("Failed to update action type", error);
    }
  };

  const updateChangesAndClose = () => {
    handleDialogClose();
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleActionType1DialogOpen = (ecu) => {
    setSelectedECU(ecu);
    setActionType1DialogOpen(true);
  };

  const handleActionType1DialogClose = () => {
    setActionType1DialogOpen(false);
  };

  const zones = [
    "CENTRAL RIGHT",
    "CENTRAL LEFT",
    "FRONT LEFT",
    "REAR LEFT",
    "REAR RIGHT",
    "FRONT RIGHT",
    "FRONT",
    "REAR",
  ];

  const zoneMapping = {
    1: "CENTRAL RIGHT",
    2: "CENTRAL LEFT",
    3: "FRONT LEFT",
    4: "REAR LEFT",
    5: "REAR RIGHT",
    6: "FRONT RIGHT",
    7: "FRONT",
    8: "REAR",
  };

  // Log the zone_position_name
  console.log("zone_position_value", selectedZoneMain);

  // Use the mapping to get the index
  const selectedZoneIndex = zoneMapping[selectedZoneMain];
  const selectedZoneName =
    selectedZoneIndex !== undefined ? zones[selectedZoneIndex] : null;

  console.log("selectedZoneIndex", selectedZoneIndex); // Check the index
  console.log("selectedZoneName", selectedZoneName); // Check the resulting name

  return (
    <Box>
      {/* Link button */}
      <Box sx={styles.customBtnContainer}>
        <CustomButton
          size={"small"}
          variant={"contained"}
          btnName={"Link"}
          disabled={!(selectedProjectId && eeLRIntersectionsDetails?.length)}
          handleOnClick={handleLinkEEToLRs}
          btnStyle={styles.customButton}
        />
        <CustomFiltersN
          anchorEl={anchorElFilter}
          open={openFilter}
          handleClose={handleFilterClose}
          mainOptionsList={[
            {
              value: "module_type",
              label: "Module Type",
              type: "select",
              value_options_list: [
                { value: "0", label: "All" },
                { value: 1, label: "Software Module" },
                { value: 2, label: "EE Component" },
                { value: 3, label: "Vehicle Component" },
              ],
            },
          ]}
          selectedMainOption={selectedSearchField}
          selectedMainOptionValue={searchFieldValue}
          handleFilterClick={handleFilterClick}
          onReset={handleFilterResetAll}
          onApplyNow={handleFilterApplyNow}
        />
      </Box>
      {/* EE Components linking */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} sx={styles.grid}>
          {/* Select fields */}
          <Box sx={{ mb: 3 }}>
            {selectedProjectIsZonal ? (
              <SelectTextFieldN
                size={"small"}
                label={"Select Zone"}
                variant={"standard"}
                optionsList={zoneMainOptionsList}
                selectedValue={selectedZoneMain}
                handleOnChange={(e) => setSelectedZoneMain(e.target.value)}
                sltFldStyle={styles.selectTxtStyle}
              />
            ) : (
              <Box sx={{ mt: { md: 6.7 } }}></Box>
            )}
          </Box>
          {/* EE Components card */}
          <Card sx={styles.card}>
            <CardHeader
              sx={styles.cardHeader}
              title={
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {"EE COMPONENTS"}
                </Typography>
              }
            />
            <CardContent sx={styles.cardContent}>
              {eeComponentsMainList && eeComponentsMainList?.length ? (
                eeComponentsMainList?.map((item, index) => (
                  <Box
                    key={index}
                    onClick={() => setSelectedMainEEDetails(item)}
                    sx={{
                      ...styles.cardItems,
                      border:
                        selectedMainEEDetails &&
                        selectedMainEEDetails?.ee_id == item?.ee_id
                          ? "2px solid blue"
                          : "1px solid lightgrey",
                      cursor: "pointer",
                      "&:hover": {
                        // backgroundColor: "lightgrey",
                      },
                    }}
                  >
                    <Box sx={styles.cardItemsTxtContainer}>
                      <Typography variant="body2">
                        <strong>Description:</strong> {item?.description}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Assembly No:</strong> {item?.assembly_number}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Version:</strong> {item?.version}
                      </Typography>
                    </Box>
                    <Box sx={styles.edRpIconsContainer}>
                      <Tooltip
                        title={
                          item.action_type === 2
                            ? "Action Pending in ECU to Legal requirements"
                            : ""
                        }
                      >
                        <IconButton
                          size="small"
                          sx={{ color: "red" }}
                          onClick={
                            item.action_type === 2
                              ? handleDialogOpen
                              : undefined
                          }
                        >
                          {item.action_type === 2 && (
                            <ErrorOutlineIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>

                      <Tooltip
                        title={
                          item.action_type === 1
                            ? "Action Pending in ECU to ECU linking"
                            : ""
                        }
                      >
                        <IconButton
                          size="small"
                          sx={{ color: "red" }}
                          onClick={
                            item.action_type === 1
                              ? handleActionType1DialogOpen
                              : undefined
                          }
                        >
                          {item.action_type === 1 && (
                            <ErrorOutlineIcon fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography variant="body1" sx={styles.noDataAvailableTxt}>
                  No data available
                </Typography>
              )}
            </CardContent>
            <CardActions sx={styles.cardActions}></CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={styles.grid}>
          {/* Select fields */}
          <Box sx={{ mb: 3 }}>
            <SelectTextFieldN
              size={"small"}
              label={"Select Legal Standards"}
              variant={"standard"}
              optionsList={legalStandardsOptionsList}
              selectedValue={selectedLegalStandardId}
              handleOnChange={(e) => setSelectedLegalStandardId(e.target.value)}
              sltFldStyle={styles.selectTxtStyle}
            />
            <SelectTextFieldN
              size={"small"}
              label={"Select Tag/Chapter"}
              variant={"standard"}
              optionsList={lsChaptersOptionsList}
              selectedValue={selectedLSChapter}
              disabled={!legalStandardsList}
              handleOnChange={(e) => setLSelectedLSChapter(e.target.value)}
              sltFldStyle={{
                ...styles.selectTxtStyle,
                ml: 2,
              }}
            />
          </Box>
          {/* EE Components card */}
          <Card sx={styles.card}>
            <CardHeader
              sx={styles.cardHeader}
              title={
                <Typography
                  textAlign={"center"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {"LEGAL REQUIREMENTS"}
                </Typography>
              }
            />
            <CardContent sx={styles.cardContent}>
              {legalRequirementsList && legalRequirementsList?.length > 0 ? (
                legalRequirementsList
                  ?.filter((el) => el?.chapter == selectedLSChapter)
                  ?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        ...styles.cardItems,
                        // border: item?.linked_ee_ids?.includes(
                        //   selectedMainEEDetails?.ee_id
                        // )
                        //   ? "2px solid green"
                        //   : "1px solid lightgrey",
                        // cursor: "pointer",
                        "&:hover": {
                          // backgroundColor: "lightgrey",
                        },
                      }}
                    >
                      <Box sx={styles.cardItemsTxtContainer}>
                        <Typography variant="body2">
                          <strong>Req_No:</strong> {item?.req_number}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong> {item?.description}
                        </Typography>
                      </Box>
                      <Box>
                        {selectedMainEEDetails && (
                          <Checkbox
                            checked={
                              selectedMainEEDetails?.legal_req_ids?.includes(
                                item?.lr_id
                              ) ||
                              selectedLegalRequirementsIds?.includes(
                                item?.lr_id
                              )
                            }
                            disabled={selectedMainEEDetails?.legal_req_ids?.includes(
                              item?.lr_id
                            )}
                            onChange={(event) =>
                              handleSelectLegalRequirements(
                                event,
                                selectedMainEEDetails?.ee_id,
                                item?.lr_id
                              )
                            }
                            // inputProps={{ "aria-label": "controlled" }}
                          />
                        )}
                      </Box>
                    </Box>
                  ))
              ) : (
                <Typography variant="body1" sx={styles.noDataAvailableTxt}>
                  No data available
                </Typography>
              )}
            </CardContent>
            <CardActions sx={styles.cardActions}></CardActions>
          </Card>
        </Grid>
      </Grid>
      {selectedMainEEDetails && selectedEELinkedLRList?.length > 0 && (
        <Box sx={{ mt: "10px" }}>
          <LinkedEEToLegalTable
            selectedEELinkedLRList={selectedEELinkedLRList}
          />
        </Box>
      )}
      <CustomAddDialog
        open={isAddDescDialogOpen}
        onClose={() => handleAddDescDialogClose(false)}
        dialogTitle={" Reason For Linking"}
        onDisabled={!(currentDescription?.length > 3)}
        dialogContent={
          <Stack spacing={1.2}>
            {/* <DialogContentText>
            Reason For Linking
            </DialogContentText> */}
            <Box>
              <CustomTextFieldN
                size={"small"}
                // label={"Description*"}
                placeholder={"Description"}
                type={"text"}
                // fldType={"text"}
                value={currentDescription}
                setValue={setCurrentDescription}
                // valueError={assemblyNoError}
                setValueError={() => null}
                variant={"standard"}
                isMultiline={true}
                txtFldStyle={styles.txtStyle}
              />
              <SelectDynamicForm
                isDynamicFormDialogOpen={isDynamicFormDialogOpen}
                setIsDynamicFormDialogOpen={setIsDynamicFormDialogOpen}
                handleAddDescDialogClose={handleAddDescDialogClose}
                projectId={selectedProjectId}
                variantId={selectedSingleVariantDetails?.variant_id}
                isFormRequired={isFormRequired}
                setIsFormRequired={setIsFormRequired}
                formType={FORM_TYPES?.EE}
                dynamicFormFilteredData={dynamicFormFilteredData}
                setDynamicFormFilteredData={setDynamicFormFilteredData}
              />
            </Box>
          </Stack>
        }
        onConfirm={() =>
          isFormRequired
            ? setIsDynamicFormDialogOpen(true)
            : handleAddDescDialogClose(true)
        }
      />
      <Box>
        {/* Dialog for Action Required */}
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
        >
          <DialogTitle align="left">Action Required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Are you sure the intersection for ECU is completed?
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                {/* <Link to={`/dashboard/type-approval`}> */}
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={confirmUpdate}
                >
                  Yes
                </Button>
                {/* </Link> */}
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={updateChangesAndClose}
                >
                  No
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Action Pending Dialog */}
        <Dialog
          open={actionType1DialogOpen}
          onClose={handleActionType1DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action Pending</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                ECU dependency linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/labcar?project_id=${selectedProjectId}&variant_id=${Number(
                    selectedVariant?.value
                  )}&ee_id=${
                    selectedMainEEDetails?.ee_id
                  }&selectedZone=${selectedZoneIndex}&linkSoftwareModulesScreen=${true}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={handleActionType1DialogClose}
                  >
                    Link Now
                  </Button>
                </Link>
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType1DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

export default LinkEEToLegal;

// Styles
const styles = {
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  selectTxtStyle: {
    width: "190px",
    mt: 1,
  },
  card: {
    mx: 2,
    boxShadow: 12,
    // width: "420px",
    width: "100%",
    maxWidth: "420px",
  },
  cardHeader: {
    pb: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  cardContent: {
    height: "340px",
    overflowY: "auto",
  },
  cardItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
  },
  cardItemsTxtContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  noDataAvailableTxt: {
    textAlign: "center",
    mt: 2,
  },
  linkOffIcon: {
    color: "#FF5757",
  },
  linkIcon: {
    color: "green",
  },
  customBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    mb: 1,
    gap: 1.4,
    my: 1,
  },
  customButton: {},
  txtStyle: {
    width: "270px",
  },
};
