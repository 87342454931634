/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 20-07-2024
 * @description : Custom dynamic form template component.
 */
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import appTheme from "../../assets/AppTheme/appTheme";
import CustomTextFieldN from "../text-fields/CustomTextFieldN";
import CustomButton from "../buttons/CustomButton";
import customConsole from "../../config/customConsole";
import { EDIT_TYPES } from "../../config/appConstants";

const DynamicFormTemplate = ({
  // fields,
  // ALIGN_TYPES,
  showOnlyTemplate = false,
  handleEditField,
  handleDeleteField,
  headerOptionsList,
  signatureOptionsList,
  footerOptionsList,
  selectedHeaders,
  selectedSignatures,
  selectedFooters,
  dynamicFormData,
  setDynamicFormData,
}) => {
  /* Functions */

  /* useEffects */
  useEffect(() => {
    customConsole({ dynamicFormData });
  }, [dynamicFormData]);

  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        border: "1px solid grey",
        borderRadius: 1,
      }}
    >
      {/* Template Headers */}
      <Box sx={{ p: 1.4, display: "flex", gap: 2 }}>
        {headerOptionsList?.map((item, index) => (
          //  Enter field name
          <Box
            key={index}
            sx={{
              visibility: selectedHeaders?.includes(item?.position)
                ? "visible"
                : "hidden",
            }}
          >
            {/* <Typography
              variant="subtitle1"
              color={"grey"}
              component="label"
              htmlFor="form-type"
            >
              {`${item?.label} Header`}
            </Typography> */}
            <TextField
              InputProps={{
                readOnly: showOnlyTemplate ? true : false,
                disableUnderline: showOnlyTemplate ? true : false,
              }}
              // readonly
              fullWidth
              id="form-type"
              variant="standard"
              type={"text"}
              size={"small"}
              value={
                dynamicFormData?.headers?.find(
                  (el) => el?.position == item?.position
                )?.title
              }
              placeholder={"Header Title*"}
              onChange={(e) =>
                setDynamicFormData((prev) => ({
                  ...prev,
                  headers: prev?.headers
                    ?.map((header) =>
                      header?.position === item?.position
                        ? { ...header, title: e?.target?.value }
                        : header
                    )
                    ?.concat(
                      !prev?.headers?.some(
                        (header) => header?.position === item?.position
                      )
                        ? [
                            {
                              image: "",
                              position: item?.position,
                              title: e?.target?.value,
                              sub_title: "",
                            },
                          ]
                        : []
                    ),
                }))
              }
              // sx={{ mt: -1 }}
            />
            <TextField
              InputProps={{
                readOnly: showOnlyTemplate ? true : false,
                disableUnderline: showOnlyTemplate ? true : false,
              }}
              fullWidth
              id="form-type"
              variant="standard"
              type={"text"}
              size={"small"}
              value={
                dynamicFormData?.headers?.find(
                  (el) => el?.position == item?.position
                )?.sub_title
              }
              placeholder={"Header Subtitle*"}
              onChange={(e) =>
                setDynamicFormData((prev) => ({
                  ...prev,
                  headers: prev?.headers
                    ?.map((header) =>
                      header?.position === item?.position
                        ? { ...header, sub_title: e?.target?.value }
                        : header
                    )
                    ?.concat(
                      !prev?.headers?.some(
                        (header) => header?.position === item?.position
                      )
                        ? [
                            {
                              image: "",
                              position: item?.position,
                              title: "",
                              sub_title: e?.target?.value,
                            },
                          ]
                        : []
                    ),
                }))
              }
              sx={{ mt: 2, mb: 0.4 }}
            />
          </Box>
        ))}
      </Box>
      <Divider sx={{ border: "1px solid lightGrey" }} />
      {/* Template Fields */}
      <Box sx={{ flexGrow: 1, minHeight: "150px", m: 2.5, mb: 3.4 }}>
        {dynamicFormData?.fields?.map((field, index) => (
          <Box
            key={field?.sequence}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mb: 1.4,
            }}
          >
            <Typography
              variant="subtitle1"
              color={
                field?.edit_type === EDIT_TYPES?.DDH
                  ? "blue"
                  : field?.edit_type === EDIT_TYPES?.SNOW
                  ? "grey"
                  : "black"
              }
            >
              {`${index + 1}. ${field?.field_name}: `}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mt: -1,
              }}
            >
              <TextField
                InputProps={{
                  readOnly: showOnlyTemplate ? true : false,
                  // disableUnderline: showOnlyTemplate ? true : false,
                }}
                fullWidth
                size="large"
                variant="standard"
                type={field?.field_type || "string"}
                value={field?.value}
                onChange={(e) =>
                  setDynamicFormData((prev) => ({
                    ...prev,
                    fields: prev?.fields?.map((field1) =>
                      field1?.sequence === field?.sequence
                        ? { ...field1, value: e?.target?.value }
                        : field1
                    ),
                  }))
                }
                sx={{ ml: 1 }}
              />
              {!showOnlyTemplate && (
                <>
                  <IconButton
                    size="small"
                    onClick={() => handleEditField(field?.sequence)}
                  >
                    <EditIcon
                      sx={{
                        color:
                          field?.edit_type === EDIT_TYPES?.DDH
                            ? "blue"
                            : field?.edit_type === EDIT_TYPES?.SNOW
                            ? "grey"
                            : "black",
                      }}
                    />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => handleDeleteField(field?.sequence)}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      {/* Template Signature */}
      <Box
        sx={{
          p: 1.4,
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          gap: 4,
        }}
      >
        {signatureOptionsList?.map((item, index) => (
          //  Enter field name
          <Box
            key={index}
            sx={{
              visibility: selectedSignatures?.includes(item?.position)
                ? "visible"
                : "hidden",
            }}
          >
            {/* <Typography
              variant="subtitle1"
              color={"grey"}
              component="label"
              htmlFor="form-type"
            >
              {`${item?.label} Footer`}
            </Typography> */}
            <TextField
              InputProps={{
                readOnly: showOnlyTemplate ? true : false,
                disableUnderline: showOnlyTemplate ? true : false,
              }}
              fullWidth
              id="form-type"
              variant="standard"
              type={"text"}
              size={"small"}
              value={
                dynamicFormData?.signatures?.find(
                  (el) => el?.position == item?.position
                )?.title
              }
              placeholder={"Signature Title*"}
              onChange={(e) =>
                setDynamicFormData((prev) => ({
                  ...prev,
                  signatures: prev?.signatures
                    ?.map((signature) =>
                      signature?.position === item?.position
                        ? { ...signature, title: e?.target?.value }
                        : signature
                    )
                    ?.concat(
                      !prev?.signatures?.some(
                        (signature) => signature?.position === item?.position
                      )
                        ? [
                            {
                              image: "",
                              position: item?.position,
                              title: e?.target?.value,
                              sub_title: "",
                            },
                          ]
                        : []
                    ),
                }))
              }
              // sx={{ mt: -1 }}
            />
            <TextField
              InputProps={{
                readOnly: showOnlyTemplate ? true : false,
                disableUnderline: showOnlyTemplate ? true : false,
              }}
              fullWidth
              id="form-type"
              variant="standard"
              type={"text"}
              size={"small"}
              value={
                dynamicFormData?.signatures?.find(
                  (el) => el?.position == item?.position
                )?.sub_title
              }
              placeholder={"Signature Subtitle*"}
              onChange={(e) =>
                setDynamicFormData((prev) => ({
                  ...prev,
                  signatures: prev?.signatures
                    ?.map((signature) =>
                      signature?.position === item?.position
                        ? { ...signature, sub_title: e?.target?.value }
                        : signature
                    )
                    ?.concat(
                      !prev?.signatures?.some(
                        (signature) => signature?.position === item?.position
                      )
                        ? [
                            {
                              image: "",
                              position: item?.position,
                              title: "",
                              sub_title: e?.target?.value,
                            },
                          ]
                        : []
                    ),
                }))
              }
              sx={{ mt: 2, mb: 0.4 }}
            />
          </Box>
        ))}
      </Box>
      <Divider sx={{ border: "1px solid lightGrey" }} />
      {/* Template Footers */}
      <Box
        sx={{
          p: 1.4,
          display: "flex",
          gap: 2,
          justifyContent: "center",
          mt: "auto",
        }}
      >
        {footerOptionsList?.map((item, index) => (
          //  Enter field name
          <Box
            key={index}
            sx={{
              visibility: selectedFooters?.includes(item?.position)
                ? "visible"
                : "hidden",
            }}
          >
            {/* <Typography
              variant="subtitle1"
              color={"grey"}
              component="label"
              htmlFor="form-type"
            >
              {`${item?.label} Footer`}
            </Typography> */}
            <TextField
              InputProps={{
                readOnly: showOnlyTemplate ? true : false,
                disableUnderline: showOnlyTemplate ? true : false,
              }}
              fullWidth
              id="form-type"
              variant="standard"
              type={"text"}
              size={"small"}
              value={
                dynamicFormData?.footers?.find(
                  (el) => el?.position == item?.position
                )?.title
              }
              placeholder={"Footer Title*"}
              onChange={(e) =>
                setDynamicFormData((prev) => ({
                  ...prev,
                  footers: prev?.footers
                    ?.map((footer) =>
                      footer?.position === item?.position
                        ? { ...footer, title: e?.target?.value }
                        : footer
                    )
                    ?.concat(
                      !prev?.footers?.some(
                        (footer) => footer?.position === item?.position
                      )
                        ? [
                            {
                              image: "",
                              position: item?.position,
                              title: e?.target?.value,
                              sub_title: "",
                            },
                          ]
                        : []
                    ),
                }))
              }
              // sx={{ mt: -1 }}
            />
            <TextField
              InputProps={{
                readOnly: showOnlyTemplate ? true : false,
                disableUnderline: showOnlyTemplate ? true : false,
              }}
              fullWidth
              id="form-type"
              variant="standard"
              type={"text"}
              size={"small"}
              value={
                dynamicFormData?.footers?.find(
                  (el) => el?.position == item?.position
                )?.sub_title
              }
              placeholder={"Footer Subtitle*"}
              onChange={(e) =>
                setDynamicFormData((prev) => ({
                  ...prev,
                  footers: prev?.footers
                    ?.map((footer) =>
                      footer?.position === item?.position
                        ? { ...footer, sub_title: e?.target?.value }
                        : footer
                    )
                    ?.concat(
                      !prev?.footers?.some(
                        (footer) => footer?.position === item?.position
                      )
                        ? [
                            {
                              image: "",
                              position: item?.position,
                              title: "",
                              sub_title: e?.target?.value,
                            },
                          ]
                        : []
                    ),
                }))
              }
              sx={{ mt: 2, mb: 0.4 }}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default DynamicFormTemplate;
