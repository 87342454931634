import React, { useState } from "react";

const ImageUploader = ({ label, onImageSelect, showPreview = true }) => {
  const [file, setFile] = useState(null);

  function handleChange(e) {
    const selectedFile = e.target.files[0];
    console.log("selectedFile");
    console.log(selectedFile);

    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      setFile(fileUrl);

      // Call the onImageSelect prop to pass the file to the parent component
      if (onImageSelect) {
        onImageSelect(selectedFile);
      }
    }
  }

  return (
    <div className="image-uploader">
      <h6>{label || "Add Image:"}</h6>
      <input type="file" onChange={handleChange} />
      {showPreview && file && (
        <img
          src={file}
          alt="Uploaded Preview"
          style={{ width: 56, height: 56 }}
        />
      )}
    </div>
  );
};

export default ImageUploader;
