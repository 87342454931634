/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description : Component to add, update and replace ee component.
 */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { TbReplace } from "react-icons/tb";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import PendingIcon from "@mui/icons-material/Pending";
import {
  CustomAddDialog,
  CustomConfirmationDialog,
  CustomLogsDialog,
  CustomPagination,
  CustomReplaceDialog,
  CustomTextFieldN,
  CustomUpdateDialog,
  MultiSelectTextField,
  MultiSelectTextFieldN,
  MultiSelectTextFieldNVs,
  SelectTextFieldN,
} from "../../components";
import VlcSWModules from "./SWModules";
import {
  adminSettingsAPI,
  HandleApiActions,
  labCar,
  settingsApis,
} from "../../redux/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import customConsole from "../../config/customConsole";
import appTheme from "../../assets/AppTheme/appTheme";
import InfoButton from "../../components/infoButton/InfoButton";
import { Link } from "react-router-dom";
import editRequestAPI from "../../redux/actions/editRequestApi";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LockIcon from "@mui/icons-material/Lock";
import NoEncryptionGmailerrorredIcon from "@mui/icons-material/NoEncryptionGmailerrorred";
import CustomFiltersN from "../../components/tables/CustomFiltersN";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description : Show the ee component component
 * @param : heading, eEComponentsList, handleEEComponentAdd, handleEEComponentUpdate, handleEEComponentReplace, selectedProjectDetails, selectedZone,
 * @return : The rendered ee component.
 */
function VlcEEComponents({
  heading,
  selectedSingleVariantDetails,
  eEComponentsList,
  handleEEComponentAdd,
  handleEEComponentUpdate,
  handleEEComponentReplace,
  selectedProjectDetails,
  selectedZone,
  selectedZoneDetails,
  setIsLinkSoftwareModulesScreen,
  getEEComponentsList,
  searchFieldValue,
  setSearchFieldValue,
  selectedSearchField,
  setSelectedSearchField,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  hasMore,
  setHasMore,
}) {
  const dispatch = useDispatch();

  const { swModulesList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  const profileDetails = useSelector((store) => store.profileReducer);

  // Extract role_type from profileDetails
  const roleType = profileDetails?.profile?.role_details?.role_type;

  const supplier = roleType === 2;

  const [activeItemId, setActiveItemId] = useState(null); // State to track which item's actions are visible
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reason, setReason] = useState("");

  /* -------------- Dialogs -------------- */
  const [openEEComponentAddDialog, setOpenEEComponentAddDialog] =
    useState(false);
  const [openEEComponentUpdateDialog, setOpenEEComponentUpdateDialog] =
    useState(false);
  const [openEEComponentReplaceDialog, setOpenEEComponentReplaceDialog] =
    useState(false);
  const [openEEComponentChangeLogsDialog, setOpenEEComponentChangeLogsDialog] =
    useState(false);
  const [openRequestsDialog, setOpenRequestsDialog] = useState(false);

  /* -------------- Selected ee component -------------- */
  const [isEEComponentSelected, setIsEEComponentSelected] = useState(false);
  const [selectedEEComponentDetails, setSelectedEEComponentDetails] =
    useState(null);

  /* -------------- Selected ee component update/replace details  -------------- */
  const [
    selectedEEComponentUpdateDetails,
    setSelectedEEComponentUpdateDetails,
  ] = useState(null);
  const [
    selectedEEComponentReplaceDetails,
    setSelectedEEComponentReplaceDetails,
  ] = useState(null);

  /* -------------- EE - SW Modules -------------- */
  const [eESWModulesList, setEESWModulesList] = useState([]);

  /* -------------- Vehicle Systems-------------- */
  const [vsOptionsList, setVSOptionsList] = useState([]);

  /* -------------- EE Change Logs -------------- */
  const [eeChangeLogsList, setEEChangeLogsList] = useState([]);
  const [eeLogsDialogData, setEELogsDialogData] = useState({});

  /* -------------- Pending Requests -------------- */

  const [anchorEl, setAnchorEl] = useState(null);

  const [getRequestsData, setGetRequestData] = useState([]);

  const [selectedECU, setSelectedECU] = useState(null);
  const [actionType1DialogOpen, setActionType1DialogOpen] = useState(false);
  const [actionType2DialogOpen, setActionType2DialogOpen] = useState(false);

  const [reasonError, setReasonError] = useState("");

  // Dialog states
  const [openLockUnLockEEDialog, setOpenLockUnLockEEDialog] = useState(null);
  const [lockUnLockEEReason, setLockUnLockEEReason] = useState(null);
  const [lockUnLockEEData, setLockUnLockEEData] = useState(null);

  // Filtering
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [filteredEECompSupplierName, setFilteredEECompSupplierName] = useState(
    []
  );
  const [filteredEECompAssemblyNum, setFilteredEECompAssemblyNum] = useState(
    []
  );
  const [filteredEECompVersion, setFilteredEECompVersion] = useState([]);
  const [swmSelectedSearchField, setSWMSelectedSearchField] = useState("name");
  const [swmSearchFieldValue, setSWMSearchFieldValue] = useState("");

  //Effect to handle tickets while filter or page changes
  useEffect(() => {
    getEEComponentsList();
  }, [
    page,
    rowsPerPage,
    // selectedSortColumn,
    // selectedSortOrder,
    selectedSearchField,
    searchFieldValue,
    selectedProjectDetails,
    selectedSingleVariantDetails,
    selectedZoneDetails,
    // selectedIds,
  ]);
  //
  useEffect(() => {
    const supplierName = eEComponentsList?.map((component) => ({
      value: component?.supplier_name,
      label: component?.supplier_name,
    }));

    const assemblyNum = eEComponentsList?.map((component) => ({
      value: component?.assembly_number,
      label: component?.assembly_number,
    }));

    const version = eEComponentsList?.map((component) => ({
      value: component?.version,
      label: component?.version,
    }));

    setFilteredEECompAssemblyNum(assemblyNum);
    setFilteredEECompVersion(version);
    setFilteredEECompSupplierName(supplierName);
  }, [eEComponentsList]);

  useEffect(() => {
    handleFilterResetAll();
    // handleSortResetAll();
  }, [
    selectedProjectDetails,
    selectedSingleVariantDetails,
    selectedZoneDetails,
  ]);

  // Reset the filter values
  const handleFilterResetAll = () => {
    handleFilterChange("supplier_name");
  };

  // Handle filter change
  const handleFilterChange = (searchField, searchValue) => {
    setSelectedSearchField(searchField);
    setSearchFieldValue(searchValue);
    setPage(1);
    handleFilterClose();
  };

  // Function to handle filter
  const handleFilterApplyNow = (searchField, searchValue) => {
    handleFilterChange(searchField, searchValue);
  };

  // Open filter menu
  const handleFilterClick = (event) => {
    setAnchorElFilter(event.currentTarget);
    setOpenFilter(true);
  };

  // Handle filter close
  const handleFilterClose = () => {
    setAnchorElFilter(null);
    setOpenFilter(false);
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handle page size change
  const handlePageSizeChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1);
  };

  /* Handle lock/un-lock ee component with reason dialog */
  const handleLockUnLockEE = (params) => {
    setLockUnLockEEData(params);

    setOpenLockUnLockEEDialog(true);
  };
  const handleConfirmLockUnLockEE = async () => {
    let apiDetails = labCar.lock_unlock_ee_component;

    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id
    ) {
      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(selectedProjectDetails?.project_id),
          variant_id: Number(selectedSingleVariantDetails?.variant_id),
          ee_id: Number(lockUnLockEEData?.eeId),
          is_locked: lockUnLockEEData?.isLocked,
          ...(lockUnLockEEReason && { reason: lockUnLockEEReason }),
        },
        show_toast: true,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id: Number(lockUnLockEEData?.eeId),
            is_locked: lockUnLockEEData?.isLocked,
            ...(lockUnLockEEReason && { reason: lockUnLockEEReason }),
          },
          show_toast: true,
        })
      );
      if (resp.error) {
      } else {
        getEEComponentsList();
        handleCloseLockUnLockEE();
      }
    }
  };
  const handleCloseLockUnLockEE = () => {
    setOpenLockUnLockEEDialog(false);

    // Delay clearing the reason by 300 milliseconds
    setTimeout(() => {
      setLockUnLockEEData(null);
      setLockUnLockEEReason("");
    }, 300);
  };

  // Functions to handle data //
  //
  const getVehicleSystemsDetails = async () => {
    let apiDetails = adminSettingsAPI.get_vehicle_systems;

    customConsole({
      ...apiDetails,
    });

    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
      })
    );

    if (resp.error) {
      setVSOptionsList([]);
    } else {
      setVSOptionsList(resp.data);
    }
  };

  /* -------------- useEffects -------------- */
  useEffect(() => {
    // Get Vehicle Systems Details
    getVehicleSystemsDetails();
  }, []);

  useEffect(() => {
    /* Make to default state when the project is changed */
    setIsEEComponentSelected(false);
    setSelectedEEComponentDetails(null);
    setSelectedEEComponentUpdateDetails(null);
    setSelectedEEComponentReplaceDetails(null);
    setEESWModulesList([]);
  }, [selectedProjectDetails, selectedSingleVariantDetails]);

  useEffect(() => {
    /* Make to default state when the selected zone is changed */
    setIsEEComponentSelected(false);
    setSelectedEEComponentDetails(null);
  }, [selectedZone]);

  useEffect(() => {
    setSelectedEEComponentDetails(eEComponentsList?.[0]);
  }, [eEComponentsList]);

  useEffect(() => {
    /* Make to default state when the ee component is changed */
    setEESWModulesList([]);
  }, [selectedEEComponentDetails]);

  const getSWModulesList = () => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.get_sw_modules,
          params: {
            project_id: selectedProjectDetails?.project_id,
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id: selectedEEComponentDetails?.ee_id,
            ...(searchFieldValue && {
              [selectedSearchField]: searchFieldValue,
            }),
            page_number: page,
            page_size: rowsPerPage,
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    getSWModulesList();
  }, [selectedEEComponentDetails]);

  useEffect(() => {
    if (swModulesList) {
      setEESWModulesList(swModulesList);
    }
  }, [swModulesList]);

  useEffect(() => {
    customConsole(
      "selectedEEComponentUpdateDetails:" + selectedEEComponentUpdateDetails
    );
    customConsole(
      "selectedEEComponentReplaceDetails:" + selectedEEComponentReplaceDetails
    );
  }, [selectedEEComponentUpdateDetails, selectedEEComponentReplaceDetails]);

  /* -------------- Handle EE Component functions -------------- */
  const handleSelectedEEComponent = (eEComponent) => {
    if (eEComponent) {
      setIsEEComponentSelected(true);
      setSelectedEEComponentDetails(eEComponent);
    }
  };

  const handleSelectedEEComponentUpdateDetails = (eEComponent) => {
    setOpenEEComponentUpdateDialog(true);

    if (eEComponent) {
      setSelectedEEComponentUpdateDetails(eEComponent);
    }
  };

  const handleSelectedEEComponentReplaceDetails = (eEComponent) => {
    setOpenEEComponentReplaceDialog(true);

    if (eEComponent) {
      setSelectedEEComponentReplaceDetails(eEComponent);
    }
  };

  /* -------------- Handle SW Module functions -------------- */
  const handleEESWModuleAdd = ({
    version,
    name,
    summary,
    description,
    vf_ids,
  }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      let currentTime = new Date().toISOString();

      return dispatch(
        HandleApiActions({
          ...labCar.add_sw_module,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            version,
            name,
            ...(summary && { summary }),
            description,
            vf_ids,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then((res) => {
        getSWModulesList();

        if (res.error) {
          return false;
        } else {
          return true;
        }
      });
    }

    return false;
  };

  const handleEESWModuleUpdate = ({
    swm_id,
    version,
    will_it_impact,
    reason,
  }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        variant_id: Number(selectedSingleVariantDetails?.variant_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        swm_id,
        version,
        current_time: currentTime,
        reason,
        will_it_impact,
      });

      dispatch(
        HandleApiActions({
          ...labCar.update_sw_module,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            swm_id,
            version,
            current_time: currentTime,
            reason,
            will_it_impact,
          },
          show_toast: true,
        })
      ).then(() => {
        getSWModulesList();
      });
    }
  };

  const handleEESWModuleReplace = ({
    swm_id,
    version,
    name,
    summary,
    description,
    vf_ids,
    will_it_impact,
    reason,
  }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedSingleVariantDetails &&
      selectedSingleVariantDetails?.variant_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        variant_id: Number(selectedSingleVariantDetails?.variant_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        swm_id,
        version,
        name,
        ...(summary && { summary }),
        description,
        vf_ids,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.replace_sw_module,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            swm_id,
            version,
            name,
            ...(summary && { summary }),
            description,
            vf_ids,
            current_time: currentTime,
            reason,
            will_it_impact,
          },
          show_toast: true,
        })
      ).then(() => {
        getSWModulesList();
      });
    }
  };

  /* Get EE component change logs */
  const handleGetEEComponentChangeLogs = async ({
    ee_id,
    pageNo,
    pageSize,
  }) => {
    let apiDetails = labCar.get_ee_components_change_logs;

    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedSingleVariantDetails?.variant_id &&
      selectedSingleVariantDetails
    ) {
      customConsole({
        ...apiDetails,
        params: {
          project_id: Number(selectedProjectDetails?.project_id),
          ee_id,
          page_number: pageNo,
          page_size: pageSize,
        },
        show_toast: false,
      });

      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            variant_id: Number(selectedSingleVariantDetails?.variant_id),
            ee_id,
            page_number: pageNo,
            page_size: pageSize,
          },
          show_toast: false,
        })
      );
      if (resp.error) {
        setEEChangeLogsList([]);
      } else {
        setEEChangeLogsList(resp?.data);
      }
    }
  };

  /* Handle dialogs */

  const handleEELogsDialogOpen = (data) => {
    setOpenEEComponentChangeLogsDialog(true);

    // handleGetEEComponentChangeLogs({ ee_id: data?.ee_id });

    setEELogsDialogData(data);
  };
  const handleEELogsDialogClose = () => {
    setEELogsDialogData(null);
    setEEChangeLogsList([]);

    setOpenEEComponentChangeLogsDialog(false);
  };

  // const handleMoreClick = (e, itemId) => {
  //   e.stopPropagation(); // Prevent click event from bubbling up
  //   setActiveItemId(activeItemId === itemId ? null : itemId); // Toggle visibility
  // };

  const handleCloseActions = () => {
    setActiveItemId(null); // Close all actions
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleRequestsDialogOpen = () => {
    setOpenRequestsDialog(true);
    getRequests();
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setReason(""); // Reset reason
  };

  const handleRequestsDialogClose = () => {
    setOpenRequestsDialog(false);
  };

  const handleSubmit = async () => {
    const reqObj = {
      project_id: Number(selectedProjectDetails?.project_id),
      variant_id: Number(selectedSingleVariantDetails?.variant_id),
      ee_id: Number(selectedEEComponentDetails?.ee_id),
      request_reason: reason,
    };

    try {
      const response = await dispatch(
        HandleApiActions({
          ...editRequestAPI.create_edit_requests,
          params: reqObj,
          show_toast: true,
        })
      );
      if (!response.error) {
        // Reset form fields
        setReason("");
        handleDialogClose();
      }
    } catch (error) {
      console.error("Failed to update the reason", error);
    }
  };

  const handleMoreClick = (e, item) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setActiveItemId(item.ee_id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setActiveItemId(null);
  };

  const handleMenuAction = (action, item) => {
    handleCloseMenu(); // Close the menu after action
    if (action === "update") {
      handleSelectedEEComponentUpdateDetails(item);
    } else if (action === "replace") {
      handleSelectedEEComponentReplaceDetails(item);
    } else if (action === "logs") {
      handleEELogsDialogOpen(item);
    } else if (action === "requests") {
      handleRequestsDialogOpen(item);
    }
  };

  const getRequests = async () => {
    let apiDetails = editRequestAPI.get_edit_requests;
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: Number(selectedProjectDetails?.project_id),
          variant_id: Number(selectedSingleVariantDetails?.variant_id),
          ee_id: Number(selectedEEComponentDetails?.ee_id),
          is_sent_requests: true,
          page_number: 1,
          page_size: 25,
        },
        show_toast: false,
      })
    );
    if (resp.error) {
      setGetRequestData([]);
    } else {
      setGetRequestData(resp.data);
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 100:
        return "Pending";
      case 200:
        return "Approved";
      case 300:
        return "Rejected";
      default:
        return "Unknown Status";
    }
  };

  console.log(
    "selectedProjectDetails",
    selectedProjectDetails?.is_user_project_owner
  );

  const handleActionType1DialogOpen = (ecu) => {
    setSelectedECU(ecu);
    setActionType1DialogOpen(true);
  };

  const handleActionType2DialogOpen = (ecu) => {
    setSelectedECU(ecu);
    setActionType2DialogOpen(true);
  };

  const handleActionType1DialogClose = () => {
    setActionType1DialogOpen(false);
  };

  const handleActionType2DialogClose = () => {
    setActionType2DialogOpen(false);
  };

  const zones = [
    "CENTRAL LEFT",
    "CENTRAL RIGHT",
    "FRONT",
    "REAR",
    "FRONT LEFT",
    "FRONT RIGHT",
    "REAR LEFT",
    "REAR RIGHT",
  ];

  const zoneMapping = {
    "CENTRAL LEFT": 0,
    "CENTRAL RIGHT": 1,
    FRONT: 2,
    REAR: 3,
    "FRONT LEFT": 4,
    "FRONT RIGHT": 5,
    "REAR LEFT": 6,
    "REAR RIGHT": 7,
  };

  // Log the zone_position_name
  console.log("zone_position_name", selectedZone?.zone_position_name);

  // Use the mapping to get the index
  const selectedZoneIndex = zoneMapping[selectedZone?.zone_position_name];
  const selectedZoneName =
    selectedZoneIndex !== undefined ? zones[selectedZoneIndex] : null;

  console.log("selectedZoneIndex", selectedZoneIndex); // Check the index
  console.log("selectedZoneName", selectedZoneName); // Check the resulting name

  return (
    <Box sx={styles.eeCompMainContainer}>
      <Box>
        {/* EE Component card */}
        <Card sx={styles.eeCompCard}>
          <CardHeader
            sx={styles.eeCompCardHeader}
            title={
              <>
                <Typography
                  textAlign={"start"}
                  fontWeight={600}
                  variant="subtitle1"
                >
                  {heading}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    textAlign={"start"}
                    fontSize="13px"
                    alignContent={"center"}
                    color="textSecondary"
                  >
                    {"EE Components"}
                  </Typography>
                  <InfoButton infoKey={"ee_component"} color={"grey"} />
                </Box>
                <Box
                // sx={{
                //   position: "relative",
                //   top: -50,
                // }}
                ></Box>
              </>
            }
            action={
              <Box
                sx={{
                  pt: 2,
                  gap: 1,
                  display: "flex",
                }}
              >
                <CustomFiltersN
                  anchorEl={anchorElFilter}
                  open={openFilter}
                  handleClose={handleFilterClose}
                  mainOptionsList={[
                    {
                      value: "supplier_name",
                      label: "Supplier Name",
                      type: "select",
                      value_options_list:
                        filteredEECompSupplierName?.length > 0
                          ? [...filteredEECompSupplierName]
                          : [],
                    },
                    {
                      value: "assembly_number",
                      label: "Assembly Number",
                      type: "select",
                      value_options_list:
                        filteredEECompAssemblyNum?.length > 0
                          ? [...filteredEECompAssemblyNum]
                          : [],
                    },
                    {
                      value: "version",
                      label: "Version",
                      type: "select",
                      value_options_list:
                        filteredEECompVersion?.length > 0
                          ? [...filteredEECompVersion]
                          : [],
                    },
                  ]}
                  selectedMainOption={selectedSearchField}
                  selectedMainOptionValue={searchFieldValue}
                  handleFilterClick={handleFilterClick}
                  onReset={handleFilterResetAll}
                  onApplyNow={handleFilterApplyNow}
                />
                {!supplier ? (
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    startIcon={<AddIcon />}
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={!selectedSingleVariantDetails?.variant_id}
                    onClick={() => setOpenEEComponentAddDialog(true)}
                  >
                    Add
                  </Button>
                ) : null}
              </Box>
            }
          />
          <CardContent sx={styles.eeCompCardContent}>
            {eEComponentsList && eEComponentsList?.length > 0 ? (
              eEComponentsList?.map((item, index) => (
                <Box
                  key={item.ee_id} // Use a unique identifier for the key
                  onClick={() => handleSelectedEEComponent(item)}
                  sx={{
                    ...styles.eeCompsList,
                    border:
                      selectedEEComponentDetails &&
                      selectedEEComponentDetails?.ee_id == item?.ee_id
                        ? "2px solid blue"
                        : "1px solid lightgrey",
                  }}
                >
                  <Box sx={styles.eeCompsListItemContainer}>
                    <Typography variant="body2">
                      <strong>Description:</strong> {item?.description}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Assembly No:</strong> {item?.assembly_number}
                    </Typography>
                    {/* <Typography variant="body2">
                      <strong>Supplier Name:</strong> {item?.supplier_name}
                    </Typography> */}
                    <Typography variant="body2">
                      <strong>Version:</strong> {item?.version}
                    </Typography>
                  </Box>
                  <Box>
                    <Box
                      key={item?.ee_id}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {/* More */}
                      <Tooltip title="More">
                        <IconButton
                          size="small"
                          sx={{ color: "green" }}
                          onClick={(e) => handleMoreClick(e, item)}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>

                      {/* Lock/UnLock Icons */}
                      {item?.is_locked ? (
                        <Tooltip title="Lock">
                          <IconButton
                            size="small"
                            sx={{ color: "green" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLockUnLockEE({
                                eeId: item?.ee_id,
                                isLocked: false,
                              });
                            }}
                          >
                            <LockIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="UnLock">
                          <IconButton
                            size="small"
                            sx={{ color: "green" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLockUnLockEE({
                                eeId: item?.ee_id,
                                isLocked: true,
                              });
                            }}
                          >
                            <NoEncryptionGmailerrorredIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      )}

                      {/* Conditional render based on edit permission */}
                      {item.is_edit_enabled ||
                      selectedProjectDetails?.is_user_project_owner ? (
                        <>
                          {[1, 2].map((type) => (
                            <Tooltip
                              key={type}
                              title={
                                type === 1
                                  ? "Action required in ECU to ECU linking"
                                  : "Action required in ECU to Legal requirements"
                              }
                            >
                              <IconButton
                                size="small"
                                sx={{ color: "red" }}
                                onClick={() => {
                                  if (type === 1)
                                    handleActionType1DialogOpen(item);
                                  if (type === 2)
                                    handleActionType2DialogOpen(item);
                                }}
                                disabled={item.action_type !== type}
                              >
                                {item.action_type === type && (
                                  <ErrorOutlineIcon fontSize="small" />
                                )}
                              </IconButton>
                            </Tooltip>
                          ))}
                        </>
                      ) : (
                        <>
                          <Tooltip title="Edit action required">
                            <IconButton
                              size="small"
                              sx={{ color: "blue" }}
                              onClick={() => handleDialogOpen(item)}
                            >
                              <EditNoteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>

                          {[1, 2].map((type) => (
                            <Tooltip
                              key={type}
                              title={
                                type === 1
                                  ? "Action required in ECU to ECU linking"
                                  : "Action required in ECU to Legal requirements"
                              }
                            >
                              <IconButton
                                size="small"
                                sx={{ color: "red" }}
                                onClick={() => {
                                  if (type === 1)
                                    handleActionType1DialogOpen(item);
                                  if (type === 2)
                                    handleActionType2DialogOpen(item);
                                }}
                                disabled={item.action_type !== type}
                              >
                                {item.action_type === type && (
                                  <ErrorOutlineIcon fontSize="small" />
                                )}
                              </IconButton>
                            </Tooltip>
                          ))}
                        </>
                      )}

                      {/* Conditional Menu Rendering */}
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && activeItemId === item.ee_id}
                        onClose={handleCloseMenu}
                      >
                        {!item?.is_locked &&
                        (item.is_edit_enabled ||
                          selectedProjectDetails?.is_user_project_owner) ? (
                          <>
                            <MenuItem
                              onClick={() => handleMenuAction("update", item)}
                            >
                              <IconButton size="small" sx={{ color: "green" }}>
                                <EditIcon fontSize="small" />
                              </IconButton>{" "}
                              Update{" "}
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleMenuAction("replace", item)}
                            >
                              <IconButton size="small" sx={{ color: "green" }}>
                                <TbReplace sx={{ fontSize: 20 }} />
                              </IconButton>{" "}
                              Replace{" "}
                            </MenuItem>
                          </>
                        ) : (
                          <>
                            <MenuItem disabled>
                              <IconButton size="small" sx={{ color: "grey" }}>
                                <EditIcon fontSize="small" />
                              </IconButton>{" "}
                              Update{" "}
                            </MenuItem>
                            <MenuItem disabled>
                              <IconButton size="small" sx={{ color: "grey" }}>
                                <TbReplace sx={{ fontSize: 20 }} />
                              </IconButton>{" "}
                              Replace{" "}
                            </MenuItem>
                          </>
                        )}
                        <MenuItem
                          onClick={() => handleMenuAction("logs", item)}
                        >
                          <IconButton size="small" sx={{ color: "green" }}>
                            <HistoryEduIcon fontSize="small" />
                          </IconButton>{" "}
                          Logs{" "}
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleMenuAction("requests", item)}
                        >
                          <IconButton size="small" sx={{ color: "green" }}>
                            <PendingIcon fontSize="small" />
                          </IconButton>{" "}
                          Requests{" "}
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="body1" sx={styles.noDataAvailable}>
                No data available
              </Typography>
            )}
          </CardContent>
          {/* <CardActions sx={styles.eeCompCardActions}>
            <Button
              startIcon={<CloseIcon />}
              color="error"
              size="small"
              variant="contained"
            >
              Cancel
            </Button>
          </CardActions> */}
          <Paper sx={styles.paginationPaper}>
            <CustomPagination
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              hasMore={hasMore}
            />
          </Paper>
        </Card>

        <CustomConfirmationDialog
          open={openLockUnLockEEDialog}
          onClose={handleCloseLockUnLockEE}
          dialogTitle={`Reason to ${
            lockUnLockEEData?.isLocked ? "Lock" : "UnLock"
          } EE`}
          dialogContent={
            <Box sx={{ mt: 1.4 }}>
              <CustomTextFieldN
                size={"small"}
                placeholder={"Reason"}
                type={"text"}
                value={lockUnLockEEReason}
                setValue={setLockUnLockEEReason}
                variant={"standard"}
              />
            </Box>
          }
          onConfirm={handleConfirmLockUnLockEE}
        />

        <VlcEEComponentAddDialog
          openEEComponentAddDialog={openEEComponentAddDialog}
          setOpenEEComponentAddDialog={setOpenEEComponentAddDialog}
          handleEEComponentAdd={handleEEComponentAdd}
          vsOptionsList={vsOptionsList}
        />

        <VlcEEComponentUpdateDialog
          openEEComponentUpdateDialog={openEEComponentUpdateDialog}
          setOpenEEComponentUpdateDialog={setOpenEEComponentUpdateDialog}
          handleEEComponentUpdate={handleEEComponentUpdate}
          selectedEEComponentUpdateDetails={selectedEEComponentUpdateDetails}
          setSelectedEEComponentUpdateDetails={
            setSelectedEEComponentUpdateDetails
          }
          setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
          selectedZone={selectedZone}
          selectedEEComponentDetails={selectedEEComponentDetails}
          getEEComponentsList={getEEComponentsList}
          getSWModulesList={getSWModulesList}
          selectedProjectDetails={selectedProjectDetails}
          selectedSingleVariantDetails={selectedSingleVariantDetails}
        />

        <VlcEEComponentReplaceDialog
          openEEComponentReplaceDialog={openEEComponentReplaceDialog}
          setOpenEEComponentReplaceDialog={setOpenEEComponentReplaceDialog}
          handleEEComponentReplace={handleEEComponentReplace}
          selectedEEComponentReplaceDetails={selectedEEComponentReplaceDetails}
          setSelectedEEComponentReplaceDetails={
            setSelectedEEComponentReplaceDetails
          }
          setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
          selectedZone={selectedZone}
          vsOptionsList={vsOptionsList}
          selectedEEComponentDetails={selectedEEComponentDetails}
          getEEComponentsList={getEEComponentsList}
          getSWModulesList={getSWModulesList}
          selectedProjectDetails={selectedProjectDetails}
          selectedSingleVariantDetails={selectedSingleVariantDetails}
        />
      </Box>

      {/* Software module card */}
      <Box>
        {/* {isEEComponentSelected && ( */}
        <VlcSWModules
          heading={selectedEEComponentDetails?.description}
          sWModulesList={eESWModulesList}
          handleSWModuleAdd={handleEESWModuleAdd}
          handleSWModuleUpdate={handleEESWModuleUpdate}
          handleSWModuleReplace={handleEESWModuleReplace}
          setIsEEComponentSelected={setIsEEComponentSelected}
          setSelectedEEComponentDetails={setSelectedEEComponentDetails}
          selectedProjectDetails={selectedProjectDetails}
          selectedEEComponentDetails={selectedEEComponentDetails}
          setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
          selectedZone={selectedZone}
          getSWModulesList={getSWModulesList}
          selectedSingleVariantDetails={selectedSingleVariantDetails}
          swmSearchFieldValue={swmSearchFieldValue}
          setSWMSearchFieldValue={setSWMSearchFieldValue}
          swmSelectedSearchField={swmSelectedSearchField}
          setSWMSelectedSearchField={setSWMSelectedSearchField}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          hasMore={hasMore}
          setHasMore={setHasMore}
        />
        {/* )} */}
      </Box>

      <CustomLogsDialog
        open={openEEComponentChangeLogsDialog}
        handleClose={handleEELogsDialogClose}
        dialogTitle={"Change Logs"}
        dialogContent={eeChangeLogsList}
        data={eeLogsDialogData}
        getLogsData={handleGetEEComponentChangeLogs}
        setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
      />

      <Box>
        {/* Dialog for Action Required */}
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
        >
          <DialogTitle>Edit Accces Required</DialogTitle>
          <DialogContent>
            <Typography mb={2}>
              Request access to update/replace the EE component
            </Typography>
            <CustomTextFieldN
              size={"small"}
              label={"Request"}
              placeholder={"Reason for request"}
              type={"text"}
              fldType={"text5"}
              value={reason}
              setValue={setReason}
              valueError={reasonError}
              setValueError={setReasonError}
              variant={"standard"}
              txtFldStyle={styles.txtStyleR}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              sx={{ ...appTheme.commonBtnStyle }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              sx={{ ...appTheme.commonBtnStyle }}
              disabled={reason.length < 5}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for to show pending requests */}
        <Dialog
          open={openRequestsDialog}
          onClose={handleRequestsDialogClose}
          BackdropProps={{
            style: {
              backgroundColor: "transparent",
            },
          }}
        >
          <DialogTitle>Edit Requests</DialogTitle>
          <DialogContent>
            {/* Check if there is data to display */}
            {getRequestsData.length > 0 ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>S.No</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Reason</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Status</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Approver</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Created At</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Updated At</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getRequestsData.map((request, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{request.request_reason}</TableCell>
                        <TableCell>{getStatusLabel(request.status)}</TableCell>
                        <TableCell>
                          {request?.approved_user?.fullname}
                        </TableCell>
                        <TableCell>
                          {new Date(request.created_at).toLocaleString()}
                        </TableCell>
                        <TableCell>
                          {new Date(request.updated_at).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p>No requests available.</p>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleRequestsDialogClose}
              sx={{ ...appTheme.commonBtnStyle }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Action Pending Dialog */}
        <Dialog
          open={actionType1DialogOpen}
          onClose={handleActionType1DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                ECU dependency linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/labcar?ee_id=${selectedEEComponentDetails?.ee_id}&selectedZone=${selectedZone?.zone_position_name}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={() => {
                      setIsLinkSoftwareModulesScreen(true);
                    }}
                  >
                    Link Now
                  </Button>
                </Link>
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType1DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Action Pending Dialog */}
        <Dialog
          open={actionType2DialogOpen}
          onClose={handleActionType2DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                ECU intersection linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/type-approval?project_id=${selectedProjectDetails?.project_id}&variant_id=${selectedSingleVariantDetails?.variant_id}&ee_id=${selectedEEComponentDetails?.ee_id}&selectedZone=${selectedZone?.zone_position_name}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={handleActionType2DialogClose}
                  >
                    Link Now
                  </Button>
                </Link>
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType2DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

export default VlcEEComponents;

// Styles
const styles = {
  eeCompMainContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-evenly",
  },
  eeCompCard: {
    mt: 1.3,
    mx: 2,
    width: "370px",
    boxShadow: 12,
  },
  eeCompCardHeader: {
    py: 1,
    backgroundColor: "lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  eeCompCardContent: {
    height: "387px",
    overflowY: "auto",
  },
  eeCompCardActions: {
    display: "flex",
    justifyContent: "flex-end",
    borderTop: "1px solid lightgrey",
  },
  eeCompsList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1.5,
    pl: 1.4,
    pr: 1,
    backgroundColor: "background.paper",
    borderRadius: 1,
    boxShadow: 4,
    cursor: "pointer",
    "&:hover": {
      // backgroundColor: "lightgrey",
    },
  },
  eeCompsListItemContainer: {
    flexGrow: 1,
    textAlign: "start",
    my: 1,
  },
  noDataAvailable: {
    textAlign: "center",
    mt: 2,
  },
  txtStyle: {
    width: "270px",
  },
  txtStyleR: {
    width: "380px",
  },
  edRpIconsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  customTextFieldN: {
    width: "270px",
  },
  selectTxtStyle: {
    width: "270px",
  },
  paginationPaper: {
    display: "flex",
    justifyContent: "flex-end",
    p: "5px",
  },
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : EE component add dialog.
 * @param : openEEComponentAddDialog, setOpenEEComponentAddDialog, handleEEComponentAdd, vsOptionsList
 * @return : The rendered EE component add dialog.
 */
function VlcEEComponentAddDialog({
  openEEComponentAddDialog,
  setOpenEEComponentAddDialog,
  handleEEComponentAdd,
  vsOptionsList,
}) {
  /* Add EE Components Fields */
  const [assemblyNo, setAssemblyNo] = useState("");
  const [assemblyNoError, setAssemblyNoError] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierNameError, setSupplierNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [selectedVSIds, setSelectedVSIds] = useState([]);
  const [selectedASIL, setSelectedASIL] = useState(null);

  /* -------------- UseEffects -------------- */

  /* -------------- Add EE Component -------------- */

  const clearAddEEComponentFields = () => {
    setAssemblyNo("");
    setAssemblyNoError("");
    setSupplierName("");
    setSupplierNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSelectedVSIds([]);
    setSelectedASIL(null);
  };

  const isAddEEComponentValid = () => {
    return (
      assemblyNo?.trim() !== "" &&
      !assemblyNoError &&
      supplierName?.trim() !== "" &&
      !supplierNameError &&
      version?.trim() !== "" &&
      !versionError &&
      description?.trim() !== "" &&
      !descriptionError &&
      selectedVSIds?.length > 0 &&
      selectedASIL?.length > 0 &&
      (summary?.trim().length === 0 || !summaryError)
    );
  };

  const handleCloseEEComponentAdd = () => {
    setOpenEEComponentAddDialog(false);

    clearAddEEComponentFields();
  };

  const handleAddEEComponent = () => {
    handleEEComponentAdd({
      assembly_number: assemblyNo,
      supplier_name: supplierName,
      version: version,
      summary: summary,
      description: description,
      vehicle_systems: selectedVSIds?.map(Number) || [],
      other_fields: {
        asil_class: selectedASIL,
      },
    }).then((res) => {
      if (res) {
        handleCloseEEComponentAdd();
      }
    });
  };

  return (
    <CustomAddDialog
      open={openEEComponentAddDialog}
      onClose={handleCloseEEComponentAdd}
      dialogTitle={"Add EE Component"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Assembly No*"}
            placeholder={"Assembly No*"}
            type={"text"}
            fldType={"textR"}
            value={assemblyNo}
            setValue={setAssemblyNo}
            valueError={assemblyNoError}
            setValueError={setAssemblyNoError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Supplier Name*"}
            placeholder={"Supplier Name*"}
            type={"text"}
            fldType={"text"}
            value={supplierName}
            setValue={setSupplierName}
            valueError={supplierNameError}
            setValueError={setSupplierNameError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={styles.txtStyle}
          />
          <MultiSelectTextFieldNVs
            required
            mutiple
            size={"small"}
            label={"Select Vehicle Systems*"}
            optionsList={vsOptionsList}
            selectedValues={selectedVSIds}
            setSelectedValues={setSelectedVSIds}
            sltFldStyle={styles.customTextFieldN}
          />
          <SelectTextFieldN
            size={"small"}
            label={"Select ASIL Class*"}
            variant={"standard"}
            optionsList={["A", "B", "C", "D"]?.map((el) => ({
              value: el,
              label: el,
            }))}
            selectedValue={selectedASIL}
            handleOnChange={(e) => setSelectedASIL(e.target.value)}
            sltFldStyle={styles.selectTxtStyle}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddEEComponent({});
        // clearAddEEComponentFields();
      }}
      onDisabled={!isAddEEComponentValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : EE component update dialog.
 * @param : openEEComponentUpdateDialog, setOpenEEComponentUpdateDialog, handleEEComponentUpdate, selectedEEComponentUpdateDetails, setSelectedEEComponentUpdateDetails,
 * @return : The rendered EE component update dialog.
 */
function VlcEEComponentUpdateDialog({
  openEEComponentUpdateDialog,
  setOpenEEComponentUpdateDialog,
  handleEEComponentUpdate,
  selectedEEComponentUpdateDetails,
  setSelectedEEComponentUpdateDetails,
  setIsLinkSoftwareModulesScreen,
  selectedZone,
  selectedEEComponentDetails,
  getEEComponentsList,
  getSWModulesList,
  selectedProjectDetails,
  selectedSingleVariantDetails,
}) {
  /* Update EE Components Fields */
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false); // State to track confirmation dialog
  const [discp, setDiscp] = useState("");
  const [discpError, setDiscpError] = useState("");
  const [actionType1DialogOpen, setActionType1DialogOpen] = useState(false);
  const [ticketsDialogOpen, setTicketsDialogOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      openEEComponentUpdateDialog == true &&
      selectedEEComponentUpdateDetails
    ) {
      setVersion(selectedEEComponentUpdateDetails?.version || "");
    }
  }, [openEEComponentUpdateDialog, selectedEEComponentUpdateDetails]);

  /* -------------- Update EE Component -------------- */

  const clearUpdateEEComponentFields = () => {
    setVersion("");
    setVersionError("");
    setDiscp("");
    setDiscpError("");
  };

  const isUpdateEEComponentValid = () => {
    return (
      !versionError &&
      version?.trim() !== selectedEEComponentUpdateDetails?.version &&
      discp?.trim().length > 0
    );
  };

  const handleCloseEEComponentUpdate = () => {
    setOpenEEComponentUpdateDialog(false);

    clearUpdateEEComponentFields();
    setSelectedEEComponentUpdateDetails(null);
    setShowConfirmation(false); // Reset confirmation on close
  };

  const handleUpdateClick = () => {
    setShowConfirmation(true); // Show confirmation dialog when update is clicked
  };

  const handleUpdateEEComponent = ({}) => {
    if (
      selectedEEComponentUpdateDetails &&
      selectedEEComponentUpdateDetails?.ee_id
    ) {
      setShowConfirmation(true); // Show confirmation on update button click
    }
  };

  const confirmUpdateAndRedirect = async () => {
    const reqObj = {
      current_time: new Date().toISOString(),
      project_id: Number(selectedEEComponentUpdateDetails?.project_id),
      variant_id: Number(selectedSingleVariantDetails?.variant_id),
      ee_id: Number(selectedEEComponentUpdateDetails?.ee_id),
      version: version,
      will_it_impact: true,
      reason: discp,
    };
    try {
      const response = await dispatch(
        HandleApiActions({
          ...labCar.update_ee_component,
          params: reqObj,
          show_toast: true,
        })
      );
      getEEComponentsList();
      getSWModulesList();
      if (!response.error) {
        handleCloseEEComponentUpdate();
        setActionType1DialogOpen(true);
      }
    } catch (error) {
      console.error("Failed to update", error);
    }
  };

  const updateChangesAndClose = async () => {
    const reqObj = {
      current_time: new Date().toISOString(),
      project_id: Number(selectedEEComponentUpdateDetails?.project_id),
      variant_id: Number(selectedSingleVariantDetails?.variant_id),
      ee_id: Number(selectedEEComponentUpdateDetails?.ee_id),
      version: version,
      will_it_impact: false,
      reason: discp,
    };

    try {
      // Await the dispatch to ensure you're getting the response
      const response = await dispatch(
        HandleApiActions({
          ...labCar.update_ee_component,
          params: reqObj,
          show_toast: true,
        })
      );
      getEEComponentsList();
      getSWModulesList();
      if (!response.error) {
        handleCloseEEComponentUpdate();
      }
      if (response?.data?.snow_resp) {
        setTicketsDialogOpen(true);
      }
    } catch (error) {
      console.error("Failed to update", error);
    }
  };

  const handleActionType1DialogClose = () => {
    setActionType1DialogOpen(false);
  };

  const handleTicketsDialogClose = () => {
    setTicketsDialogOpen(false);
  };

  return (
    <Box>
      <CustomUpdateDialog
        open={openEEComponentUpdateDialog}
        onClose={handleCloseEEComponentUpdate}
        dialogTitle={"Update EE Component"}
        dialogContent={
          <Stack spacing={2} mb={2} justifyContent={"center"}>
            <CustomTextFieldN
              size={"small"}
              label={"Version*"}
              placeholder={"Version*"}
              type={"text"}
              fldType={"textR"}
              value={version}
              setValue={setVersion}
              valueError={versionError}
              setValueError={setVersionError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Reason*"}
              placeholder={"Reason to update*"}
              type={"text"}
              fldType={"textO"}
              value={discp}
              setValue={setDiscp}
              valueError={discpError}
              setValueError={setDiscpError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            {showConfirmation && (
              <Stack spacing={2}>
                <Typography variant="body1">
                  Will it impact the existing dependencies?
                </Typography>
                <Stack direction="row" spacing={2} justifyContent={"center"}>
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={confirmUpdateAndRedirect}
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={updateChangesAndClose}
                  >
                    No
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        }
        onConfirm={handleUpdateClick} // Trigger the update process
        onDisabled={!isUpdateEEComponentValid()}
      />

      <Box>
        {/* Action Pending Dialog */}
        <Dialog
          open={actionType1DialogOpen}
          onClose={handleActionType1DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                ECU dependency linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/labcar?ee_id=${selectedEEComponentDetails?.ee_id}&selectedZone=${selectedZone?.zone_position_name}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={() => setIsLinkSoftwareModulesScreen(true)}
                  >
                    Link Now
                  </Button>
                </Link>

                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType1DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Handle Ticket Page Dialog */}
        <Dialog
          open={ticketsDialogOpen}
          onClose={handleTicketsDialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Tickets Recreated</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Successfully created the tickets for the intersections!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/type-approval?project_id=${
                    selectedProjectDetails?.project_id
                  }&setTabValue=${2}&variant_id=${
                    selectedSingleVariantDetails?.variant_id
                  }`}
                >
                  <Button sx={{ ...appTheme.commonBtnStyle }}>View</Button>
                </Link>

                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleTicketsDialogClose}
                >
                  Close
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description : EE component replace dialog.
 * @param : openEEComponentReplaceDialog, setOpenEEComponentReplaceDialog, handleEEComponentReplace, selectedEEComponentReplaceDetails, setSelectedEEComponentReplaceDetails, vsOptionsList
 * @return : The rendered EE component replace dialog.
 */
function VlcEEComponentReplaceDialog({
  openEEComponentReplaceDialog,
  setOpenEEComponentReplaceDialog,
  handleEEComponentReplace,
  selectedEEComponentReplaceDetails,
  setSelectedEEComponentReplaceDetails,
  vsOptionsList,
  setIsLinkSoftwareModulesScreen,
  selectedZone,
  selectedEEComponentDetails,
  getEEComponentsList,
  getSWModulesList,
  selectedProjectDetails,
  selectedSingleVariantDetails,
}) {
  /* Replace EE Components Fields */
  const [assemblyNo, setAssemblyNo] = useState("");
  const [assemblyNoError, setAssemblyNoError] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierNameError, setSupplierNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [selectedVSIds, setSelectedVSIds] = useState([]);
  const [selectedASIL, setSelectedASIL] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [discp, setDiscp] = useState("");
  const [discpError, setDiscpError] = useState("");
  const [selectedECU, setSelectedECU] = useState(null);
  const [actionType1DialogOpen, setActionType1DialogOpen] = useState(false);
  const [ticketsDialogOpen, setTicketsDialogOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (
      openEEComponentReplaceDialog == true &&
      selectedEEComponentReplaceDetails
    ) {
      setAssemblyNo(selectedEEComponentReplaceDetails?.assembly_number || "");
      setSupplierName(selectedEEComponentReplaceDetails?.supplier_name || "");
      setVersion(selectedEEComponentReplaceDetails?.version || "");
      setDescription(selectedEEComponentReplaceDetails?.description || "");
      setSummary(selectedEEComponentReplaceDetails?.summary || "");
      setSelectedVSIds(
        (selectedEEComponentReplaceDetails?.vehicle_systems || [])
          ?.map((vsId) => Number(vsId))
          ?.filter((vsId) => !isNaN(vsId))
      );
      setSelectedASIL(
        selectedEEComponentReplaceDetails?.other_fields?.asil_class || null
      );
      setDiscp(selectedEEComponentReplaceDetails?.reason || "");
    }
  }, [openEEComponentReplaceDialog, selectedEEComponentReplaceDetails]);

  /* -------------- Replace EE Component -------------- */

  const clearReplaceEEComponentFields = () => {
    setAssemblyNo("");
    setAssemblyNoError("");
    setSupplierName("");
    setSupplierNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSelectedVSIds([]);
    setSelectedASIL(null);
    setDiscp("");
    setDiscpError("");
  };

  const isReplaceEEComponentValid = () => {
    return (
      !assemblyNoError &&
      !supplierNameError &&
      !versionError &&
      !descriptionError &&
      selectedVSIds?.length > 0 &&
      selectedASIL?.length > 0 &&
      discp?.trim().length > 0 &&
      (assemblyNo?.trim() !==
        selectedEEComponentReplaceDetails?.assembly_number ||
        supplierName?.trim() !==
          selectedEEComponentReplaceDetails?.supplier_name ||
        version?.trim() !== selectedEEComponentReplaceDetails?.version ||
        description?.trim() !==
          selectedEEComponentReplaceDetails?.description ||
        summary?.trim() !== selectedEEComponentReplaceDetails?.summary ||
        selectedVSIds?.length !==
          selectedEEComponentReplaceDetails?.vehicle_systems?.length ||
        selectedASIL !==
          selectedEEComponentReplaceDetails?.other_fields?.asil_class)
    );
  };

  const handleCloseEEComponentReplace = () => {
    setOpenEEComponentReplaceDialog(false);

    clearReplaceEEComponentFields();

    setSelectedEEComponentReplaceDetails(null);
    setShowConfirmation(false); // Reset confirmation on close
  };

  const handleUpdateClick = () => {
    setShowConfirmation(true); // Show confirmation dialog when update is clicked
  };

  const confirmUpdateAndRedirect = async () => {
    if (
      selectedEEComponentReplaceDetails &&
      selectedEEComponentReplaceDetails?.ee_id
    ) {
      const reqObj = {
        current_time: new Date().toISOString(),
        project_id: Number(selectedEEComponentReplaceDetails?.project_id),
        variant_id: Number(selectedSingleVariantDetails?.variant_id),
        ee_id: Number(selectedEEComponentReplaceDetails?.ee_id),
        assembly_number: assemblyNo,
        supplier_name: supplierName,
        version: version,
        ...(summary && { summary }),
        description: description,
        vehicle_systems: selectedVSIds?.map(Number) || [],
        other_fields: {
          asil_class: selectedASIL,
        },
        will_it_impact: true,
        reason: discp,
      };
      try {
        const response = await dispatch(
          HandleApiActions({
            ...labCar.replace_ee_component,
            params: reqObj,
            show_toast: true,
          })
        );
        getEEComponentsList();
        getSWModulesList();
        if (!response.error) {
          handleCloseEEComponentReplace();
          setActionType1DialogOpen(true);
        }
      } catch (error) {
        console.error("Failed to replace", error);
      }
    }
  };

  const updateChangesAndClose = async () => {
    if (
      selectedEEComponentReplaceDetails &&
      selectedEEComponentReplaceDetails?.ee_id
    ) {
      const reqObj = {
        current_time: new Date().toISOString(),
        project_id: Number(selectedEEComponentReplaceDetails?.project_id),
        variant_id: Number(selectedSingleVariantDetails?.variant_id),
        ee_id: Number(selectedEEComponentReplaceDetails?.ee_id),
        assembly_number: assemblyNo,
        supplier_name: supplierName,
        version: version,
        ...(summary && { summary }),
        description: description,
        vehicle_systems: selectedVSIds?.map(Number) || [],
        other_fields: {
          asil_class: selectedASIL,
        },
        will_it_impact: false,
        reason: discp,
      };
      try {
        const response = await dispatch(
          HandleApiActions({
            ...labCar.replace_ee_component,
            params: reqObj,
            show_toast: true,
          })
        );
        getEEComponentsList();
        getSWModulesList();
        if (!response.error) {
          handleCloseEEComponentReplace();
        }
        if (response?.data?.snow_resp) {
          setTicketsDialogOpen(true);
        }
      } catch (error) {
        console.error("Failed to replace", error);
      }
    }
  };

  const handleReplaceEEComponent = ({}) => {
    if (
      selectedEEComponentReplaceDetails &&
      selectedEEComponentReplaceDetails?.ee_id
    ) {
      handleEEComponentReplace({
        ee_id: Number(selectedEEComponentReplaceDetails?.ee_id),
        assembly_number: assemblyNo,
        supplier_name: supplierName,
        version: version,
        summary: summary,
        description: description,
        vehicle_systems: selectedVSIds?.map(Number) || [],
        other_fields: {
          asil_class: selectedASIL,
        },
      });

      handleCloseEEComponentReplace();
    }
  };

  useEffect(() => {
    customConsole({ selectedVSIds, vsOptionsList });
  }, [selectedVSIds, vsOptionsList]);

  const handleActionType1DialogOpen = (ecu) => {
    setSelectedECU(ecu);
    setActionType1DialogOpen(true);
  };

  const handleActionType1DialogClose = () => {
    setActionType1DialogOpen(false);
  };

  const handleTicketsDialogClose = () => {
    setTicketsDialogOpen(false);
  };

  return (
    <Box>
      <CustomReplaceDialog
        open={openEEComponentReplaceDialog}
        onClose={handleCloseEEComponentReplace}
        dialogTitle={"Replace EE Component"}
        dialogContent={
          <Stack spacing={2} mb={2} justifyContent={"center"}>
            <CustomTextFieldN
              size={"small"}
              label={"Assembly No*"}
              placeholder={"Assembly No*"}
              type={"text"}
              fldType={"textR"}
              value={assemblyNo}
              setValue={setAssemblyNo}
              valueError={assemblyNoError}
              setValueError={setAssemblyNoError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Supplier Name*"}
              placeholder={"Supplier Name*"}
              type={"text"}
              fldType={"text"}
              value={supplierName}
              setValue={setSupplierName}
              valueError={supplierNameError}
              setValueError={setSupplierNameError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Version*"}
              placeholder={"Version*"}
              type={"text"}
              fldType={"textR"}
              value={version}
              setValue={setVersion}
              valueError={versionError}
              setValueError={setVersionError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Description*"}
              placeholder={"Description*"}
              type={"text"}
              fldType={"text"}
              value={description}
              setValue={setDescription}
              valueError={descriptionError}
              setValueError={setDescriptionError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Summary"}
              placeholder={"Summary"}
              type={"text"}
              // fldType={"text"}
              value={summary}
              setValue={setSummary}
              // valueError={summaryError}
              // setValueError={setSummaryError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            <MultiSelectTextFieldNVs
              required
              mutiple
              size={"small"}
              label={"Select Vehicle Systems*"}
              optionsList={vsOptionsList}
              selectedValues={selectedVSIds}
              setSelectedValues={setSelectedVSIds}
              sltFldStyle={styles.customTextFieldN}
            />
            <SelectTextFieldN
              size={"small"}
              label={"Select ASIL Class*"}
              variant={"standard"}
              optionsList={["A", "B", "C", "D"]?.map((el) => ({
                value: el,
                label: el,
              }))}
              selectedValue={selectedASIL}
              handleOnChange={(e) => setSelectedASIL(e.target.value)}
              sltFldStyle={styles.selectTxtStyle}
            />
            <CustomTextFieldN
              size={"small"}
              label={"Reason*"}
              placeholder={"Reason to replace*"}
              type={"text"}
              fldType={"textO"}
              value={discp}
              setValue={setDiscp}
              valueError={discpError}
              setValueError={setDiscpError}
              variant={"standard"}
              txtFldStyle={styles.txtStyle}
            />
            {showConfirmation && (
              <Stack spacing={2}>
                <Typography variant="body1">
                  Will it impact the existing dependencies?
                </Typography>
                <Stack direction="row" spacing={2} justifyContent={"center"}>
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={confirmUpdateAndRedirect}
                  >
                    Yes
                  </Button>
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={updateChangesAndClose}
                  >
                    No
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        }
        onConfirm={handleUpdateClick}
        onDisabled={!isReplaceEEComponentValid()}
      />
      {/* Action Pending Dialog */}
      <Box>
        <Dialog
          open={actionType1DialogOpen}
          onClose={handleActionType1DialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Action required</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                ECU dependency linking is pending!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/labcar?ee_id=${selectedEEComponentDetails?.ee_id}&selectedZone=${selectedZone?.zone_position_name}`}
                >
                  <Button
                    sx={{ ...appTheme.commonBtnStyle }}
                    onClick={() => setIsLinkSoftwareModulesScreen(true)}
                  >
                    Link Now
                  </Button>
                </Link>
                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleActionType1DialogClose}
                >
                  Link Later
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>

        {/* Handle Ticket Page Dialog */}
        <Dialog
          open={ticketsDialogOpen}
          onClose={handleTicketsDialogClose}
          BackdropProps={{ style: { backgroundColor: "transparent" } }}
        >
          <DialogTitle align="left">Tickets Recreated</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography variant="body1" align="center">
                Successfully created the tickets for the intersections!
              </Typography>
              <Stack direction="row" spacing={2} justifyContent="center">
                <Link
                  to={`/dashboard/type-approval?project_id=${
                    selectedProjectDetails?.project_id
                  }&setTabValue=${2}&variant_id=${
                    selectedSingleVariantDetails?.variant_id
                  }`}
                >
                  <Button sx={{ ...appTheme.commonBtnStyle }}>View</Button>
                </Link>

                <Button
                  sx={{ ...appTheme.commonBtnStyle }}
                  onClick={handleTicketsDialogClose}
                >
                  Close
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}
