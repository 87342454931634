import Autocomplete from "@mui/material/Autocomplete";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  ChipsTextField,
  CustomButton,
  CustomTextFieldN,
  CustomTextFieldSP,
  MultiSelectTextField2,
  SelectTextField,
} from "../../../components";
import AddIcon from "@mui/icons-material/Add";
import InfoButton from "../../../components/infoButton/InfoButton";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  adminSettingsAPI,
  HandleApiActions,
  projectConfigAPI,
} from "../../../redux/actions";
import moment from "moment";

function CreateVariant({
  openCreateVariant,
  setOpenCreateVariant,
  selectedProjectData,
}) {
  const dispatch = useDispatch();
  //Redux
  const vehicleStatusDetails = useSelector(
    (state) => state?.vehicleStatusReducer
  );
  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );
  const powerTrainClassDetails = useSelector(
    (state) => state.powerTrainClassReducer
  );

  //useEffect for Dispatch
  useEffect(() => {
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_status }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_powertrain_class }));
  }, []);

  //1.React useState
  const [name, setName] = useState("");
  const [mftDetailsState, setMftDetailsState] = useState(null);
  const [selectedItemsVf, setSelectedItemsVf] = useState([]);
  const [selectedItemsPc, setSelectedItemsPc] = useState(null);
  const [components, setComponents] = useState([]);
  const [DMState, setDMState] = useState(null);
  const [vehicleStatus, setVehicleStatus] = useState("");
  //data
  const [DmData, setDMData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [vfData, setVfData] = useState([]);
  const [pcData, setPcData] = useState([]);
  const [mftData, setMftData] = useState([]);
  //error useState
  const [nameError, setNameError] = useState(false);

  //2.useEffect
  useEffect(() => {
    console.log("selectedProjectData", selectedProjectData);
    if (selectedProjectData?.delivery_market?.length > 0) {
      setDMData(selectedProjectData?.delivery_market);
    }

    if (selectedProjectData?.mft_details?.length > 0) {
      setMftData(selectedProjectData?.mft_details);
    }
  }, [selectedProjectData]);

  useEffect(() => {
    if (selectedProjectData?.pwrtrn_class?.length > 0) {
      const filteredPCData =
        powerTrainClassDetails?.powerTrainClassDetails?.data?.filter((data) =>
          selectedProjectData?.pwrtrn_class?.includes(data?.id)
        );
      console.log("filteredPCData", filteredPCData);
      setPcData(filteredPCData);
    }
  }, [selectedProjectData, powerTrainClassDetails]);

  useEffect(() => {
    if (selectedProjectData?.veh_funcs?.length > 0) {
      const filteredVFData =
        vehicleFunctionDetails?.vehicleFunctionDetails?.data?.filter((data) =>
          selectedProjectData?.veh_funcs?.includes(data?.id)
        );
      console.log("filteredVFData", filteredVFData);
      setVfData(filteredVFData);
    }
  }, [selectedProjectData, vehicleFunctionDetails]);

  useEffect(() => {
    console.log("vehicleStatusDetails<---", selectedProjectData);

    const vehicleStatusData = vehicleStatusDetails?.vehicleStatusDetails?.data
      ?.map((el, idx) => ({
        value: el?.name,
        label: el?.name,
        sequence: el?.sequence,
      }))
      ?.sort((a, b) => a.sequence - b.sequence);
    console.log("vehicleStatusData", vehicleStatusData);

    setVehicleData(vehicleStatusData);
  }, [vehicleStatusDetails]);

  const handleCreateVariant = async () => {
    const reqObj = {
      project_id: selectedProjectData?.project_id,
      name: name,
      delivery_market: DMState,
      pwrtrn_class: selectedItemsPc,
      vehicle_status: vehicleStatus,
      ...(selectedItemsVf &&
        selectedItemsVf.length > 0 && { veh_funcs: selectedItemsVf }),

      current_time: moment().format(),
      mft_details: mftDetailsState,
    };
    // dispatch(
    //   HandleApiActions({
    //     ...projectConfigAPI.add_variant,
    //     params: reqObj,
    //     shallowEqual,
    //     show_toast: true,
    //   })
    // ).then(() => {
    //   dispatch(
    //     HandleApiActions({
    //       ...projectConfigAPI.get_variant_details,
    //       params: {
    //         project_id: selectedProjectData?.project_id,
    //         is_complete_details_required: true,
    //       },
    //       shallowEqual,
    //     })
    //   );
    // });

    const addVariantResponse = await dispatch(
      HandleApiActions({
        ...projectConfigAPI.add_variant,
        params: reqObj,
        shallowEqual,
        show_toast: true,
      })
    );

    console.log("Add Variant Response:", addVariantResponse);

    if (!addVariantResponse?.error) {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_variant_details,
          params: {
            project_id: selectedProjectData?.project_id,
            is_complete_details_required: true,
          },
          shallowEqual,
        })
      );
      setOpenCreateVariant(false);
    }
  };

  console.log("mftDetailsState", mftDetailsState);

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" open={openCreateVariant}>
        <DialogTitle sx={styled.dialogTitle} id="customized-dialog-title">
          <Typography fontWeight="bold" variant="h6">
            Create Variant
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenCreateVariant(false)}
          sx={styled.iconButton}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                variant="body1"
                sx={{ fontStyle: "italic", mt: "10px" }}
              >
                Required fields are marked with asterisk *
              </Typography>
            </Box>
            <Divider
              sx={{
                marginTop: "25px",
                marginRight: "10px",
                border: 1,
              }}
              flexItem
            />
          </Box>
          {/* 1. */}
          <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
            <Avatar>1</Avatar>
            <Box sx={{ ml: "20px" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Variant Name *
              </Typography>
              {/* <CustomTextFieldSP
                size="normal"
                placeholder="Name of the Variant"
                required
                value={name}
                setValue={setName}
                txtFldStyle={{ width: "330px" }}
                validationRules={{ minLength: 3 }}
                setError={setNameError}
                error={nameError !== ""}
              /> */}
              <CustomTextFieldN
                size={"small"}
                placeholder={"Name of the variant"}
                type={"textR"}
                //isMultiline={true}
                //minRows={3}
                //maxRows={5}
                value={name}
                setValue={setName}
                valueError={nameError}
                setValueError={setNameError}
                variant={"standard"}
                txtFldStyle={{ width: "330px" }}
              />
            </Box>
            <InfoButton infoKey={"name_of_the_project"} color={"grey"} />

            <Box sx={{ ml: "20px" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Delivery Market*
              </Typography>
              <Autocomplete
                value={DMState}
                options={DmData}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setDMState(newValue);
                  }
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    sx={{ width: "330px", mr: "10px" }}
                    {...params}
                    variant="standard"
                    placeholder="Select a Delivery Market"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box>
                      <Typography>{option}</Typography>
                    </Box>
                  </li>
                )}
              />
            </Box>

            <Box sx={{ ml: "15px", mb: "15px" }}>
              <Typography sx={{ fontWeight: "bold" }}>
                Vehicle Status*
              </Typography>
              <SelectTextField
                label="Select a Vehicle Status"
                size={"small"}
                optionsList={vehicleData}
                selectedValue={vehicleStatus}
                setSelectedValue={setVehicleStatus}
                variant={"standard"}
                sltFldStyle={{
                  width: "330px",
                }}
              />
            </Box>
          </Box>

          {/* 2. */}

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar>2</Avatar>
            <Box sx={{ ml: "20px", flex: 1 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {" "}
                Vehicle Function
              </Typography>
              <MultiSelectTextField2
                mutiple
                size={"small"}
                label={"Select Vehicle Function"}
                optionsList={vfData}
                selectedValues={selectedItemsVf}
                setSelectedValues={setSelectedItemsVf}
                sltFldStyle={{
                  textAlign: "start",

                  backgroundColor: "white",
                }}
              />
            </Box>
            <InfoButton infoKey={"vehicle_function"} color={"grey"} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
            <Box sx={{ ml: "30px" }}></Box>

            <Box sx={{ ml: "30px", flex: 1 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                PowerTrain Class*
              </Typography>
              <Autocomplete
                value={
                  pcData?.find((item) => item?.id === selectedItemsPc) || null
                }
                options={pcData || []}
                getOptionLabel={(option) => option?.name || ""}
                onChange={(event, option) => setSelectedItemsPc(option?.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select PowerTrain Class*"
                    variant="standard"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box>
                      <Typography variant="body2">
                        {option?.name} - {option?.abbreviation}
                      </Typography>
                    </Box>
                  </li>
                )}
              />
            </Box>
            <InfoButton infoKey={"power_train_class"} color={"grey"} />
          </Box>

          {/* 3. */}
          <Box sx={{ display: "flex", alignItems: "center", mt: "25px" }}>
            <Avatar>3</Avatar>
            <Box sx={{ ml: "20px", flex: 1 }}>
              <Typography sx={{ fontWeight: "bold" }}>
                {" "}
                Manufacturer Details *
              </Typography>
              <Autocomplete
                value={mftDetailsState}
                options={mftData}
                getOptionLabel={(option) => option?.name}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setMftDetailsState({
                      name: newValue?.name,
                      address: newValue?.address,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Manufacturer Details"
                    variant="standard"
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Box>
                      <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                        {option?.name}
                      </Typography>
                      <Box>
                        <Typography>{option?.address}</Typography>
                      </Box>
                    </Box>
                  </li>
                )}
              />
            </Box>
            <InfoButton infoKey={"manufacturer_name"} color={"grey"} />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            position: "sticky",
            bottom: 0,
            padding: "16px",
            boxShadow: "3",
          }}
        >
          <CustomButton
            disabled={
              name?.length > 2 &&
              vehicleStatus?.length > 1 &&
              DMState &&
              selectedItemsPc?.length > 0 &&
              mftDetailsState
                ? false
                : true
            }
            startIcon={<AddIcon />}
            size={"medium"}
            variant={"contained"}
            btnName={"New Variant"}
            handleOnClick={handleCreateVariant}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateVariant;

const styled = {
  dialogTitle: { m: 0, p: 2, boxShadow: "3" },
  iconButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "red",
  },
};
