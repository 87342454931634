/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-07-2024
 * @description : Custom component to select dynamic form/template.
 */
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Input,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { customAppColors } from "../../assets/app-theme/appColors";
import CustomButton from "../buttons/CustomButton";
import SelectTextFieldN from "../text-fields/SelectTextFieldN";
import DynamicFormDialog from "./DynamicFormDialog";
import TemplateDialog from "./TemplateDialog";
import typeApprovalAPI from "../../redux/actions/typeApprovalAPI";
import { dispatch } from "d3";
import { HandleApiActions } from "../../redux/actions";
import { useDispatch } from "react-redux";
import customConsole from "../../config/customConsole";

const SelectDynamicForm = ({
  isDynamicFormDialogOpen,
  setIsDynamicFormDialogOpen,
  handleAddDescDialogClose,
  projectId,
  variantId,
  isFormRequired,
  setIsFormRequired,
  formType,
  dynamicFormFilteredData,
  setDynamicFormFilteredData,
}) => {
  /* Dispatch */
  const dispatch = useDispatch();

  /* Dynamic form state */
  const [dynamicFormData, setDynamicFormData] = useState(null);

  /* Constant states*/
  const FORM_TYPE = { DEFAULT: 100, MY_FORMS: 200, UPLOAD: 300 };
  const [selectedFTBtn, setSelectedFTBtn] = useState(FORM_TYPE?.DEFAULT);

  /* States */
  const [file, setFile] = useState(null);

  // Template dialog states
  const [defaultTemplates, setDefaultTemplates] = useState([]);
  const [selectedDefaultTemplate, setSelectedDefaultTemplate] = useState(null);
  const [myTemplates, setMyTemplates] = useState([]);
  const [selectedMyTemplate, setSelectedMyTemplate] = useState(null);

  /* Functions */
  const getDefaultTemplates = async ({ formType }) => {
    const apiDetails = typeApprovalAPI.get_default_templates;
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        show_toast: false,
        params: {
          type: formType,
          page_number: 1,
          page_size: 100,
        },
      })
    );
    if (resp.error) {
      setDefaultTemplates([]);
      setSelectedDefaultTemplate(null);
    } else {
      if (resp?.data?.length) {
        let filteredDefaultTemplates = resp?.data?.map((el) => ({
          ...el,
          id: Number(el?.id),
          value: Number(el?.id),
          label: el?.name,
        }));
        setDefaultTemplates(filteredDefaultTemplates);
        // Select first option by default
        setSelectedDefaultTemplate(filteredDefaultTemplates?.[0]);
      }
    }
  };

  const getMyTemplates = async ({ formType }) => {
    const apiDetails = typeApprovalAPI.get_user_dynamic_forms;
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        show_toast: false,
        params: {
          project_id: projectId,
          variant_id: variantId,
          type: formType,
          page_number: 1,
          page_size: 100,
        },
      })
    );
    if (resp.error) {
      setMyTemplates([]);
      setSelectedMyTemplate(null);
    } else {
      if (resp?.data?.length) {
        let filteredMyTemplates = resp?.data?.map((el) => ({
          ...el,
          id: Number(el?.intr_id),
          value: Number(el?.intr_id),
          label: el?.name,
        }));
        setMyTemplates(filteredMyTemplates);
        // Select first option by default
        setSelectedMyTemplate(filteredMyTemplates?.[0]);
      }
    }
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
    }
  };

  const handleUpload = () => {
    if (file) {
      // Handle the file upload logic here
      alert(`File "${file.name}" uploaded successfully!`);
    } else {
      alert("Please select a file to upload.");
    }
  };

  /* Handle Dynamic Form Dialog Function */
  const handleDynamicFormDialog = (confirm) => {
    if (confirm) {
    }

    setIsDynamicFormDialogOpen(false);
  };

  /* useEffects */
  useEffect(() => {
    if (formType) {
      // Get default templates
      getDefaultTemplates({ formType });
      // Get my templates
      getMyTemplates({ formType });
    }
  }, [formType]);
  useEffect(() => {
    if (selectedFTBtn) {
      // Make the selected file to null, when the selected file type tab is changed
      setFile(null);
    }
  }, [selectedFTBtn]);
  useEffect(() => {
    // Store the dynamic form data in the selected main template, based on the selected form type button
    setDynamicFormData(
      selectedFTBtn === FORM_TYPE.DEFAULT
        ? selectedDefaultTemplate
        : selectedFTBtn === FORM_TYPE.MY_FORMS
        ? selectedMyTemplate
        : null
    );
  }, [selectedFTBtn, selectedDefaultTemplate, selectedMyTemplate]);
  useEffect(() => {
    customConsole({ defaultTemplates });
    customConsole({ selectedDefaultTemplate });
    customConsole({ myTemplates });
    customConsole({ selectedMyTemplate });
    customConsole({ dynamicFormData });
  }, [
    defaultTemplates,
    selectedDefaultTemplate,
    myTemplates,
    selectedMyTemplate,
    dynamicFormData,
  ]);

  return (
    <Box
      sx={{
        mt: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 1,
        width: "270px",
      }}
    >
      {/* Is form required checkbox */}
      <FormControlLabel
        control={
          <Checkbox
            checked={isFormRequired}
            onChange={(e) => setIsFormRequired(e.target.checked)}
            color="primary"
          />
        }
        label="Is form required?"
        labelPlacement="start"
        sx={{
          ".MuiFormControlLabel-label": {
            marginLeft: -2, // Remove extra space on the left of the label
          },
        }}
      />
      {/* Form type tabs */}
      {isFormRequired ? (
        <Paper elevation={0} sx={{}}>
          <ButtonGroup>
            {[
              { value: FORM_TYPE?.DEFAULT, label: "Default" },
              { value: FORM_TYPE?.MY_FORMS, label: "My Forms" },
              // { value: FORM_TYPE?.UPLOAD, label: "Upload" },
            ].map((el) => (
              <Button
                size="small"
                sx={{
                  color: "black",
                  borderRadius: 0,
                  borderColor: "#333",
                  backgroundColor:
                    // selectedFTBtn === el?.value ? "#26890D" : "transparent",
                    selectedFTBtn === el?.value
                      ? customAppColors?.green
                      : "transparent",
                  "&:hover": {
                    borderColor: "#333",
                    backgroundColor:
                      // selectedFTBtn == el?.value ? "#37b518" : "#f0f0f0",
                      customAppColors?.lightGreen,
                  },
                }}
                onClick={() => setSelectedFTBtn(el?.value)}
              >
                {el?.label}
              </Button>
            ))}
          </ButtonGroup>

          {/* Selected form type details */}
          {(() => {
            switch (selectedFTBtn) {
              case FORM_TYPE.DEFAULT:
                return (
                  <Paper elevation={0} sx={{ width: "270px", my: 2 }}>
                    <SelectTextFieldN
                      size={"small"}
                      label={"Select default form"}
                      variant={"standard"}
                      optionsList={defaultTemplates}
                      selectedValue={selectedDefaultTemplate?.value || ""}
                      handleOnChange={(e) => {
                        const selectedOpt = defaultTemplates?.find(
                          (option) => option?.value === e?.target?.value
                        );
                        setSelectedDefaultTemplate(selectedOpt);
                      }}
                      sltFldStyle={{ width: "270px" }}
                    />
                  </Paper>
                );

              case FORM_TYPE.MY_FORMS:
                return (
                  <Paper elevation={0} sx={{ width: "270px", my: 2 }}>
                    <SelectTextFieldN
                      size={"small"}
                      label={"Select my form"}
                      variant={"standard"}
                      optionsList={myTemplates}
                      selectedValue={selectedMyTemplate?.value || ""}
                      handleOnChange={(e) => {
                        const selectedOpt = myTemplates?.find(
                          (option) => option?.value === e?.target?.value
                        );
                        setSelectedMyTemplate(selectedOpt);
                      }}
                      sltFldStyle={{ width: "270px" }}
                    />
                  </Paper>
                );

              case FORM_TYPE.UPLOAD:
                return (
                  <Paper elevation={0} sx={{ width: "270px", my: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      Upload form
                    </Typography>
                    {/* File upload input */}
                    <Box sx={{ marginTop: 0.4 }}>
                      <Input
                        type="file"
                        onChange={handleFileChange}
                        inputProps={{ accept: ".pdf,.doc,.docx,.txt" }} // Accept file types
                        sx={{ display: "block", marginBottom: 1 }}
                      />
                      {/* Upload button */}
                      <CustomButton
                        size="small"
                        variant="contained"
                        btnName="Upload"
                        handleOnClick={handleUpload}
                        disabled={!file} // Disable the button if no file is selected
                      />
                    </Box>

                    {/* Display selected file name */}
                    {file && (
                      <Typography variant="body2" sx={{ marginTop: 1 }}>
                        <span style={{ fontWeight: "bold" }}>
                          Uploaded File:{" "}
                        </span>
                        {file?.name}
                      </Typography>
                    )}
                  </Paper>
                );
              default:
                return null;
            }
          })()}

          {/* Open dynamic form dialog */}
          {/* <CustomButton
            size={"small"}
            variant={"contained"}
            btnName={"Add form"}
            disabled={!dynamicFormData}
            handleOnClick={() => setIsDynamicFormDialogOpen(true)}
            btnStyle={styles.customButton}
          /> */}
        </Paper>
      ) : null}

      {/* Dynamic form dialog */}
      <DynamicFormDialog
        open={isDynamicFormDialogOpen}
        onClose={() => handleDynamicFormDialog(false)}
        dynamicFormData={dynamicFormData}
        setDynamicFormData={setDynamicFormData}
        dynamicFormFilteredData={dynamicFormFilteredData}
        setDynamicFormFilteredData={setDynamicFormFilteredData}
        dialogTitle={"Dynamic Form/Template"}
        dialogContent={null}
        onConfirm={() => handleAddDescDialogClose(true)}
        onDisabled={() => {}}
      />
    </Box>
  );
};

export default SelectDynamicForm;

// Styles
const styles = {};
