/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-tahir@au79consulting.com
 * @date 11-06-2024
 * @description This is the Virtual Type Approval Page for the Application
 */

//Importing the required components/functions from the libraries
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Links from "./Links/Links";
import Intersections from "./Intersections/Intersections";
import Tickets from "./Tickets/Tickets";
import {
  CustomButton,
  ProjectVariantDetails,
  SearchSelectTextField,
  SearchSelectTextFieldN,
} from "../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import labCar from "../../redux/actions/labCar";
import typeApprovalAPI from "../../redux/actions/typeApprovalAPI";
import InfoButton from "../../components/infoButton/InfoButton";
import IntersDelTicket from "../../pages/vta/Intersections/IntersDelTicket";
import { useLocation, useNavigate } from "react-router-dom";
import {
  legalRequirements,
  projectConfigAPI,
  userManagementApis,
} from "../../redux/actions";
import selectProjectAction from "../../redux/actions/selectProject";
import selectVariantAction from "../../redux/actions/selectVariant";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterByOptionsTickets from "../../components/tickets/FilterByOptionsTickets";
import SortByOptionsTickets from "../../components/tickets/SortByOptionsTickets";

function Vta() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const appMetaDetails = useSelector(
    (state) => state.settingsReducer?.metaData
  );

  const EEDetails = useSelector((state) => state?.labCarReducer);
  const UserDetails = useSelector((state) => state.userManagementReducer);
  const LSDetails = useSelector((state) => state.legalRequirementsReducer);
  const profileDetails = useSelector((store) => store.profileReducer);

  // Extract role_type from profileDetails
  const roleType = profileDetails?.profile?.role_details?.role_type;

  const supplier = roleType === 2;

  const initialTabValue = supplier ? 2 : 0;
  const [tabValue, setTabValue] = useState(initialTabValue);

  /* -------------- States -------------- */

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  /* Project states */
  const [projectOptionsList, setProjectOptionsList] = useState([]);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);
  /* Variant states */
  const [variantOptionsList, setVariantOptionsList] = useState([]);
  const [selectedVariantDetails, setSelectedVariantDetails] = useState(null);

  /* EE states */
  const [projectEECount, setProjectEECount] = useState(0);
  /* Tab states */
  const [linkTab, setLinkTab] = useState(false);
  const [isFirstVisitTab1, setIsFirstVisitTab1] = useState(true);
  /* Intersection states */
  const [showIntersDelTicket, setShowIntersDelTicket] = useState(false);
  const [intersectionDetailsNew, setIntersectionDetailsNew] = useState([]);
  const [isSWMToLegalIntersection, setIsSWMToLegalIntersection] =
    useState(true);
  const [isVehicleToLegalIntersection, setIsVehicleToLegalIntersection] =
    useState(true);
  /* Links */
  const linkModuleType = { SWM: 1, EE: 2, VEHICLE: 3 };
  const [selectedLinkModuleType, setSelectedLinkModuleType] = useState(
    linkModuleType?.EE
  );

  //useState for Sort
  const [selectColumn, setSelectColumn] = useState("updated_at");
  const [selectOrder, setSelectOrder] = useState("DESC");

  //useState for Filter
  const [searchIntrId, setSearchIntrId] = useState("");
  const [searchTicketId, setSearchTicketId] = useState("");
  const ticketsType = { SWM: 1, EE: 2, VEHICLE: 3 };
  const [selectedTicketsType, setSelectedTicketsType] = useState(
    ticketsType?.EE
  );

  const [selectTicketStatus, setSelectTicketStatus] = useState([]);

  const [selectedZones, setSelectedZones] = useState([]);
  const [selectOEM, setSelectOEM] = useState([]);
  const [selectTECH, setSelectTECH] = useState([]);
  const [selectedEE, setSelectedEE] = useState([]);
  const [selectSWMEE, setSelectSWMEE] = useState("");
  const [selectedSWM, setSelectedSWM] = useState([]);
  const [selectedVC, setSelectedVC] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState([]);
  const [selectedLR, setSelectedLR] = useState([]);
  const [ticketDetails, setTicketDetails] = useState([]);

  const reqObj = {
    project_id: selectedProjectDetails?.project_id,
    variant_id: selectedVariantDetails?.variant_id,
    ...(selectOEM?.length > 0
      ? { oem_users: Array.isArray(selectOEM) ? selectOEM.join(",") : null }
      : null),
    ...(selectTECH?.length > 0
      ? {
          ts_users: Array.isArray(selectTECH) ? selectTECH.join(",") : null,
        }
      : null),

    ...(selectTicketStatus?.length > 0
      ? {
          status: Array.isArray(selectTicketStatus)
            ? selectTicketStatus.join(",")
            : null,
        }
      : null),
    ...(selectedZones?.length > 0
      ? {
          zones: Array.isArray(selectedZones) ? selectedZones.join(",") : null,
        }
      : null),
    ...(selectedEE?.length > 0
      ? {
          ee_id: Array.isArray(selectedEE) ? selectedEE.join(",") : null,
        }
      : null),

    ...(selectedVC?.length > 0
      ? {
          vc_id: Array.isArray(selectedVC) ? selectedVC.join(",") : null,
        }
      : null),
    ...(selectedSWM?.length > 0
      ? {
          swm_id: Array.isArray(selectedSWM) ? selectedSWM.join(",") : null,
        }
      : null),
    ...(selectedStandard?.length > 0
      ? {
          ls_id: Array.isArray(selectedStandard)
            ? selectedStandard.join(",")
            : null,
        }
      : null),
    ...(selectedChapter?.length > 0
      ? {
          chapters: Array.isArray(selectedChapter)
            ? selectedChapter.join(",")
            : null,
        }
      : null),
    ...(selectedLR?.length > 0
      ? {
          chapters: Array.isArray(selectedLR) ? selectedLR.join(",") : null,
        }
      : null),

    page_number: page,
    page_size: rowsPerPage,
    ...(selectColumn ? { sort_column: selectColumn } : null),
    ...(selectOrder ? { sort_order: selectOrder } : null),

    tickets_type: selectedTicketsType,
  };

  //useEffect for Filter
  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis?.get_users_list,
        params: {
          page_size: 2000,
        },
      })
    );
  }, []);
  useEffect(() => {
    if (selectedProjectDetails && selectedVariantDetails) {
      dispatch(
        HandleApiActions({
          ...labCar?.get_ee_components,
          params: {
            project_id: selectedProjectDetails?.project_id,
            variant_id: selectedVariantDetails?.variant_id,
          },
          show_toast: false,
        })
      );
    }
  }, [selectedProjectDetails, selectedVariantDetails]);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...labCar?.get_sw_modules,
        params: {
          project_id: selectedProjectDetails?.project_id,
          variant_id: selectedVariantDetails?.variant_id,
          ee_id: selectSWMEE,
        },
        show_toast: false,
      })
    );
  }, [selectSWMEE]);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_standard_details,
        params: {
          page_number: 1,
          page_size: 200,
        },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_requirements_details,
        params: {
          page_number: 1,
          page_size: 200,
        },
      })
    );
  }, []);

  /* -------------- useSelectors -------------- */
  const { projectDetails, selectedProject, selectedVariant } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { variantInfo: variantDetails } = useSelector(
    (state) => state.variantReducer,
    shallowEqual
  );

  /* -------------- Functions -------------- */
  /* Function to handle Tab Change */
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  /* Handler to show IntersDelTicket */
  const handleSaveDraft = () => {
    setShowIntersDelTicket(true);
  };

  //Function for Checkbox
  const handleCheckboxChange = (e, key, title) => {
    const verifyChecked = e.target.checked;
    const updateState = (setterFunction, key) => {
      setterFunction((prev) => {
        console.log("Updating state:", key, verifyChecked, prev, title);
        return verifyChecked
          ? [...prev, key]
          : prev.filter((item) => item !== key);
      });
    };

    if (title === "Ticket Status") {
      updateState(setSelectTicketStatus, key);
    } else if (title === "Zones") {
      updateState(setSelectedZones, key);
    } else if (title === "EE Components") {
      updateState(setSelectedEE, key);
    } else if (title === "Vehicle Components") {
      updateState(setSelectedVC, key);
    } else if (title === "Standards") {
      updateState(setSelectedStandard, key);
    } else if (title === "Chapters") {
      updateState(setSelectedChapter, key);
    } else if (title === "E/E Components ") {
      if (selectSWMEE === key) {
        setSelectSWMEE("");
      } else {
        setSelectSWMEE(key);
      }
    } else if (title === "SWM Components") {
      updateState(setSelectedSWM, key);
    } else if (title === "Legal Requirements") {
      updateState(setSelectedLR, key);
    } else if (title === "OEM Service Users") {
      updateState(setSelectOEM, key);
    } else if (title === "Technical Service Users") {
      updateState(setSelectTECH, key);
    }
  };

  //handleClear function for Filters
  const handleClearAllFilter = () => {
    setSearchIntrId("");
    setSearchTicketId("");
    setSelectOEM([]);
    setSelectTECH([]);
    setSelectedStandard([]);
    setSelectedChapter([]);
    setSelectedLR([]);
    setSelectedZones([]);
    setSelectedVC([]);
    setSelectedSWM([]);
    setSelectedEE([]);
    setSelectTicketStatus([]);
  };

  /* -------------- UseEffects -------------- */
  useEffect(() => {
    /* Get project details */
    dispatch(HandleApiActions({ ...labCar.get_project_details }));
  }, []);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_number: 1,
          page_size: 100,
          sort_column: "created_at",
          sort_order: "ASC",
        },
        show_toast: false,
      })
    );
  }, []);

  useEffect(() => {
    if (tabValue === 2 && selectedProjectDetails && selectedVariantDetails) {
      dispatch(
        HandleApiActions({
          ...labCar?.get_ee_components,
          params: {
            project_id: selectedProjectDetails?.project_id,
            variant_id: selectedVariantDetails?.variant_id,
          },
          show_toast: false,
        })
      );
    }
  }, [selectedProjectDetails, selectedVariantDetails]);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_size: 1000,
          role_type: 2,
        },
        show_toast: false,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...labCar?.get_sw_modules,
        params: {
          project_id: selectedProjectDetails?.project_id,
          variant_id: selectedVariantDetails?.variant_id,
          ee_id: selectSWMEE,
        },
        show_toast: false,
      })
    );
  }, [selectSWMEE]);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_standard_details,
        params: {
          page_number: 1,
          page_size: 200,
        },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      HandleApiActions({
        ...legalRequirements.get_legal_requirements_details,
        params: {
          page_number: 1,
          page_size: 200,
        },
      })
    );
  }, []);

  /* Get project options list & select first project by default, if not selected */

  useEffect(() => {
    // Map through the project details to create options for the dropdown
    let filteredProjectOptionsList = projectDetails
      ?.map((el) => {
        return {
          value: el?.project_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setProjectOptionsList(filteredProjectOptionsList);

    // Try to find the project that matches the projectId
    const queryParams = new URLSearchParams(location.search);

    const projectId = queryParams.get("project_id");

    const selectedProjectQuery = filteredProjectOptionsList.find(
      (project) => project.value === projectId
    );

    if (selectedProjectQuery) {
      // If a matching project is found, set it as the selected project
      dispatch(selectProjectAction(selectedProjectQuery));
    }
    /* Store first project in the redux store */
    if (!(selectedProject?.value && selectedProject?.label)) {
      dispatch(selectProjectAction(filteredProjectOptionsList?.[0]));
    }
  }, [projectDetails]);

  // useEffect(() => {
  //   /* Get project options list */
  //   let filteredProjectOptionsList = projectDetails
  //     ?.map((el) => {
  //       return {
  //         value: el?.project_id,
  //         label: el?.name,
  //       };
  //     })
  //     ?.sort((a, b) => a.label.localeCompare(b.label));
  //   setProjectOptionsList(filteredProjectOptionsList);

  //   /* Store first project in the redux store */
  //   if (!(selectedProject?.value && selectedProject?.label)) {
  //     dispatch(selectProjectAction(filteredProjectOptionsList?.[0]));
  //   }
  // }, [projectDetails]);

  /* Get selected project details */
  useEffect(() => {
    if (selectedProject) {
      let filteredSelectedProjectDetails = projectDetails?.find(
        (el) => el?.project_id === selectedProject?.value
      );
      setSelectedProjectDetails(filteredSelectedProjectDetails);
    }

    /* Selected variant will be null, when the project is changed */
    dispatch(selectVariantAction(null));
  }, [selectedProject]);

  useEffect(() => {
    /* Get variant options list */
    let filteredVariantOptionsList = variantDetails
      ?.map((el) => {
        return {
          value: el?.variant_id,
          label: el?.name,
        };
      })
      ?.sort((a, b) => a.label.localeCompare(b.label));

    setVariantOptionsList(filteredVariantOptionsList);

    /* Store first project in the redux store */
    if (!(selectedVariant?.value && selectedVariant?.label)) {
      dispatch(selectVariantAction(filteredVariantOptionsList?.[0]));
    }
  }, [variantDetails]);

  /* Get variant details, when the project is changed */
  useEffect(() => {
    if (selectedProject) {
      dispatch(
        HandleApiActions({
          ...projectConfigAPI.get_variant_details,
          params: {
            project_id: selectedProject?.value,
            is_complete_details_required: true,
          },
        })
      );
    }
  }, [selectedProject]);
  /* Get variant options list & select first variant by default, if not selected */
  // useEffect(() => {
  //   /* Get variant options list */
  //   let filteredVariantOptionsList = variantDetails
  //     ?.map((el) => {
  //       return {
  //         value: el?.variant_id,
  //         label: el?.name,
  //       };
  //     })
  //     ?.sort((a, b) => a.label.localeCompare(b.label));
  //   setVariantOptionsList(filteredVariantOptionsList);

  //       // Try to find the project that matches the projectId
  //       const queryParams = new URLSearchParams(location.search);

  //       const variantId = queryParams.get("variant_id");

  //       const selectedVariantQuery = filteredVariantOptionsList.find(
  //         (variant) => variant.value === variantId
  //       );

  //       if (selectedVariantQuery) {
  //         // If a matching project is found, set it as the selected project
  //         dispatch(selectVariantAction(selectedVariantQuery));
  //       }
  //       /* Store first project in the redux store */
  //       if (!(selectedVariant?.value && selectedVariant?.label)) {
  //         dispatch(selectVariantAction(filteredVariantOptionsList?.[0]));
  //       }

  // }, [variantDetails, selectedProject]);

  // console.log("selectVariantAction", selectedVariant);

  /* Get selected variant details */
  useEffect(() => {
    let filteredSelectedVariantDetails = variantDetails?.find(
      (el) => el?.variant_id === selectedVariant?.value
    );
    setSelectedVariantDetails(filteredSelectedVariantDetails);
  }, [variantDetails, selectedVariant]);
  /*  */
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("project_id");
    const status = queryParams.get("status");
    const tabValue = queryParams.get("setTabValue");
    if (
      location.pathname.includes("/dashboard/type-approval") &&
      projectId &&
      status
    ) {
      setTabValue(1);
    }
    if (
      location.pathname.includes("/dashboard/type-approval") &&
      projectId &&
      tabValue
    ) {
      setTabValue(2);
    }
  }, [location.pathname]);
  /*  */
  useEffect(() => {
    const clearQueryParams = () => {
      const currentPath = location.pathname;
      navigate(currentPath);
    };

    if (tabValue === 1) {
      if (isFirstVisitTab1) {
        setIsFirstVisitTab1(false);
      } else if (tabValue !== 1) {
        clearQueryParams();
        setIsFirstVisitTab1(true);
      } else {
        clearQueryParams();
      }
    }

    // Dispatch action whenever tab changes
    dispatch({
      type: typeApprovalAPI.get_add_intersection.action_type,
      payload: { error: true, msg: "", data: { intersections: [] } },
    });
  }, [tabValue]);

  const fetchTickets = async (requestObj) => {
    const resp = await dispatch(
      HandleApiActions({
        ...typeApprovalAPI?.get_tickets,
        params: requestObj,
        show_toast: false,
      })
    );
    console.log("respresp", resp);
    if (resp.error) {
      setTicketDetails([]);
    } else {
      setTicketDetails(resp.data);
    }
  };

  return (
    <Box>
      <Box sx={styles.box1}>
        <Box sx={styles.topContainer}>
          <Box sx={styles.searchContainer}>
            <SearchSelectTextFieldN
              size={"small"}
              label={"Search Project"}
              placeholder={"Search Project"}
              noOptionsText={"No projects found"}
              optionsList={projectOptionsList}
              selectedValue={selectedProject}
              handleValueChange={(event, value) =>
                // Store the selected project in the redux store
                dispatch(selectProjectAction(value))
              }
              txtFldStyle={styles.searchSelectTextField}
            />
          </Box>
          <Box sx={styles.searchContainer}>
            <SearchSelectTextFieldN
              size={"small"}
              label={"Search Variant"}
              placeholder={"Search Variant"}
              noOptionsText={"No variants found"}
              optionsList={variantOptionsList}
              selectedValue={selectedVariant}
              handleValueChange={(event, value) =>
                // Store the selected variant in the redux store
                dispatch(selectVariantAction(value))
              }
              txtFldStyle={styles.searchSelectTextField}
            />
          </Box>
        </Box>
        <Box sx={{ my: 3 }}>
          <ProjectVariantDetails
            projectDetails={selectedProjectDetails}
            variantDetails={selectedVariantDetails}
          />
        </Box>

        {tabValue === 2 ? (
          <SortByOptionsTickets
            selectColumn={selectColumn}
            selectOrder={selectOrder}
            setSelectColumn={setSelectColumn}
            setSelectOrder={setSelectOrder}
          />
        ) : null}
        <Box sx={{ height: "100px" }}>
          <Grid container spacing={2}>
            {tabValue === 2 ? (
              <Grid item xs={12} md={3}>
                {" "}
                <Box
                  sx={{
                    minHeight: "500px",
                    height: "500px",
                    overflowY: "auto",
                    padding: "5px",

                    scrollbarWidth: "thin",
                    scrollbarColor: "#888 #f1f1f1",
                  }}
                >
                  <FilterByOptionsTickets
                    setSearchTicketId={setSearchTicketId}
                    setSearchIntrId={setSearchIntrId}
                    searchTicketId={searchTicketId}
                    searchIntrId={searchIntrId}
                    selectOEM={selectOEM}
                    selectTECH={selectTECH}
                    UserDetails={UserDetails}
                    ticketsType={ticketsType}
                    appMetaDetails={appMetaDetails}
                    selectedVariantDetails={selectedVariantDetails}
                    EEDetails={EEDetails}
                    LSDetails={LSDetails}
                    selectedTicketsType={selectedTicketsType}
                    setSelectedTicketsType={setSelectedTicketsType}
                    selectTicketStatus={selectTicketStatus}
                    handleCheckboxChange={handleCheckboxChange}
                    selectedZones={selectedZones}
                    selectedEE={selectedEE}
                    selectedVC={selectedVC}
                    selectedStandard={selectedStandard}
                    selectedChapter={selectedChapter}
                    selectedLR={selectedLR}
                    selectSWMEE={selectSWMEE}
                    selectedSWM={selectedSWM}
                    handleClearAllFilter={handleClearAllFilter}
                    fetchTickets={fetchTickets}
                    reqObj={reqObj}
                    tabValue={tabValue}
                  />
                </Box>
              </Grid>
            ) : null}

            {/* 2.VTA */}
            <Grid
              item
              xs={12}
              md={tabValue === 2 ? 9 : null}
              sx={{ padding: "10px" }}
            >
              {" "}
              <Paper elevation={12}>
                {!supplier ? (
                  <Box sx={styles.box2}>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                      <Tab
                        sx={{
                          "&.Mui-selected": {
                            fontWeight: 600,
                            color: "black",
                          },
                        }}
                        iconPosition="start"
                        label="Link"
                        {...a11yProps(0)}
                      />

                      <Tab
                        sx={{
                          "&.Mui-selected": {
                            fontWeight: 600,
                            color: "black",
                          },
                        }}
                        iconPosition="start"
                        label="Intersections"
                        {...a11yProps(1)}
                      />
                      <Tab
                        sx={{
                          "&.Mui-selected": {
                            fontWeight: 600,
                            color: "black",
                          },
                        }}
                        label="Tickets"
                        {...a11yProps(2)}
                      />
                    </Tabs>
                  </Box>
                ) : null}

                <Box>
                  <CustomTabPanel value={tabValue} index={0}>
                    <Links
                      setIntersectionDetailsNew={setIntersectionDetailsNew}
                      setIsSWMToLegalIntersection={setIsSWMToLegalIntersection}
                      setIsVehicleToLegalIntersection={
                        setIsVehicleToLegalIntersection
                      }
                      setSelectedProject={() => {}}
                      selectedProjectIsZonal={selectedProjectDetails?.is_zonal}
                      selectedProjectZonesList={
                        selectedProjectDetails?.project_zones
                      }
                      projectOptionsList={projectOptionsList}
                      selectedProject={selectedProject}
                      selectedProjectId={selectedProjectDetails?.project_id}
                      selectedProjectDetails={selectedProjectDetails}
                      selectedSingleVariantDetails={{
                        ...selectedVariantDetails,
                      }}
                      setTabValue={setTabValue}
                      setLinkTab={setLinkTab}
                      tabValue={tabValue}
                      setShowIntersDelTicket={setShowIntersDelTicket}
                    />
                  </CustomTabPanel>

                  <CustomTabPanel value={tabValue} index={1}>
                    {intersectionDetailsNew &&
                    intersectionDetailsNew.length > 0 ? (
                      showIntersDelTicket ? (
                        <IntersDelTicket
                          projectOptionsList={projectOptionsList}
                          selectedProject={selectedProject}
                          setSelectedProject={() => {}}
                          selectedProjectId={selectedProjectDetails?.project_id}
                          selectedProjectDetails={selectedProjectDetails}
                          selectedSingleVariantDetails={selectedVariantDetails}
                          setTabValue={setTabValue}
                        />
                      ) : (
                        <Intersections
                          intersectionDetailsList={intersectionDetailsNew}
                          setIntersectionDetailsList={setIntersectionDetailsNew}
                          isSWMToLegalIntersection={isSWMToLegalIntersection}
                          isVehicleToLegalIntersection={
                            isVehicleToLegalIntersection
                          }
                          handleTabChange={handleTabChange}
                          tabValue={tabValue}
                          setTabValue={setTabValue}
                          handleSaveDraft={handleSaveDraft}
                        />
                      )
                    ) : (
                      <IntersDelTicket
                        projectOptionsList={projectOptionsList}
                        selectedProject={selectedProject}
                        setSelectedProject={() => {}}
                        selectedProjectId={selectedProjectDetails?.project_id}
                        selectedProjectDetails={selectedProjectDetails}
                        selectedSingleVariantDetails={selectedVariantDetails}
                      />
                    )}
                  </CustomTabPanel>

                  <CustomTabPanel value={tabValue} index={2}>
                    <Tickets
                      tabValue={tabValue}
                      ticketDetails={ticketDetails}
                      setTicketDetails={setTicketDetails}
                      searchIntrId={searchIntrId}
                      searchTicketId={searchTicketId}
                      selectedSWM={selectedSWM}
                      selectedChapter={selectedChapter}
                      selectedStandard={selectedStandard}
                      selectedLR={selectedLR}
                      selectOEM={selectOEM}
                      selectedZones={selectedZones}
                      selectTECH={selectTECH}
                      selectedVC={selectedVC}
                      selectColumn={selectColumn}
                      selectedEE={selectedEE}
                      selectOrder={selectOrder}
                      selectTicketStatus={selectTicketStatus}
                      fetchTickets={fetchTickets}
                      reqObj={reqObj}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      projectOptionsList={projectOptionsList}
                      selectedTicketsType={selectedTicketsType}
                      setSelectedTicketsType={setSelectedTicketsType}
                      ticketsType={ticketsType}
                      selectedProject={selectedProject}
                      setSelectedProject={() => {}}
                      selectedProjectId={selectedProjectDetails?.project_id}
                      selectedProjectDetails={selectedProjectDetails}
                      selectedSingleVariantDetails={selectedVariantDetails}
                    />
                  </CustomTabPanel>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Vta;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//Styles for the Page
const styles = {
  box1: { width: "100%", position: "relative" },
  box2: {
    position: "sticky",
    top: 0,
    borderBottom: 1,
    borderColor: "divider",
    backgroundColor: "white",
    zIndex: 1000,
    borderTop: "1px solid lightgrey",
  },
  searchContainer: {
    textAlign: "start",
    mt: { xs: 2, md: 0 },
  },
  searchSelectTextField: {
    width: "215px",
    backgroundColor: "white",
  },
  topContainer: {
    mt: 1,
    mx: 2,
    flexDirection: { xs: "column", md: "row" },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: { xs: 2, md: 4 },
  },
  prjDetailsContainer: {
    px: 2,
    // py: 1,
    mx: 1,
    height: "100%",
  },
  prjDetailsTxt: {
    textAlign: "start",
    alignContent: "center",
  },
  txtBold: {
    fontWeight: "bold",
  },
};
